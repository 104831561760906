import "./index.less"
import React, { Component } from 'react'
import { Select, DatePicker, Input, Button, Form } from "antd";
import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./message"
const { Option } = Select;

class EFCSearchBar extends Component {

    constructor(props) {
        super(props);
        this.matchTypes = [
            {
                intlKey: "app.components.ef_search_bar.search_tpye_fuzzy_match",
                name: "曖昧検索",
                value: 3
            },
            {
                intlKey: "app.components.ef_search_bar.search_tpye_exactly_match",
                name: "完全一致",
                value: 4
            }, {
                intlKey: "app.components.ef_search_bar.search_tpye_previous_match",
                name: "前一致",
                value: 1
            },
            {
                intlKey: "app.components.ef_search_bar.search_tpye_post_match",
                name: "後一致",
                value: 2
            },
        ]
        this.matchItems = this.matchTypes.map((type) => {
            return type.name;
        })
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.setupDefalutValues()
    }

    setupDefalutValues = () => {
        if (this.props.defaultValues && this.formRef.current) {
            if (this.props.defaultValues.wordFields) {
                this.formRef.current.setFieldsValue({
                    keywordItems: this.props.defaultValues.wordFields
                })
            }else{
                this.formRef.current.resetFields(["keywordItems"])
            }
            if (this.props.defaultValues.keyword) {
                this.formRef.current.setFieldsValue({
                    searchWord: this.props.defaultValues.keyword
                })
            }else{
                this.formRef.current.resetFields(["searchWord"])
            }

            if (this.props.defaultValues.searchType) {
                this.formRef.current.setFieldsValue({
                    searchType: this.props.defaultValues.searchType
                })
            }else{
                this.formRef.current.resetFields(["searchType"])
            }
            if (this.props.defaultValues.dateFields) {
                this.formRef.current.setFieldsValue({
                    dateItems: this.props.defaultValues.dateFields
                })
            }else{
                this.formRef.current.resetFields(["dateItems"])
            }

            if (this.props.defaultValues.startDate) {
                this.formRef.current.setFieldsValue({
                    startDate: moment(this.props.defaultValues.startDate)
                })
            }else{
                this.formRef.current.resetFields(["startDate"])
            }
            if (this.props.defaultValues.endDate) {
                this.formRef.current.setFieldsValue({
                    endDate: moment(this.props.defaultValues.endDate)
                })
            }else{
                this.formRef.current.resetFields(["endDate"])
            }
        }

    }


    handleSearchButtonClick = () => {
        this.props.onSearch();
    }

    handleResetButtonClick = () => {
        this.props.onReset();
        this.formRef.current.resetFields()
    }

    createSelectItems = (items) => {
        return items.map((item) => {
            return <Option value={item.value} key={item.value}>
                {item.intlKey ? <FormattedMessage id={item.intlKey} /> : item.name}
            </Option>
        })
    }

    onFinish = (values) => {
        const { keywordItems, searchWord, searchType, dateItems, startDate, endDate, } = values;
        let body = {}
        if (dateItems) {
            body.dateFields = dateItems
            if (startDate && endDate) {
                if (endDate < startDate) {
                    return
                }
            }

            if (startDate) {
                body.startDate = moment(startDate).format("YYYY-MM-DD");
            }

            if (endDate) {
                body.endDate = moment(endDate).format("YYYY-MM-DD");
            }
        }

        if (keywordItems && searchWord && searchWord.trim().length > 0) {
            body.wordFields = keywordItems;
            body.keyword = searchWord;
            body.searchType = searchType;
            if (searchType) {
                body.searchType = searchType;
            }
        }
        this.props.onSearch(body);
    };

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        this.setState({ error: errors[0] })
    }

    createDateSelect = (items) => {
        if (items) {
            return (
                <div className="date-container">
                    <div className="item-group">
                        <p><FormattedMessage id="app.components.ef_search_bar.date_item" /></p>
                        <Form.Item name="dateItems" noStyle={true}>
                            <Select mode="multiple" showArrow={true} allowClear className="date-items">
                                {this.createSelectItems(items)}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="item-group">
                        <p><FormattedMessage id="app.components.ef_search_bar.date_from" /></p>
                        <Form.Item name="startDate" noStyle={true}>
                            <DatePicker onChange={this.onStartDateChange} />
                        </Form.Item>
                    </div>
                    <div className="item-group">
                        <p><FormattedMessage id="app.components.ef_search_bar.date_to" /></p>
                        <Form.Item name="endDate" noStyle={true}>
                            <DatePicker onChange={this.onEndDateChange} />
                        </Form.Item>
                    </div>

                </div>
            )
        }
    }

    render() {
        this.setupDefalutValues()
        return (
            <Form
                noStyle={true}
                key="search-bar"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                ref={this.formRef}
            >

                <div className="ef-c-searchbar-container">
                    {this.createDateSelect(this.props.dateItems)}
                    <div className="keyword-container">
                        <div className="item-group">
                            <p>
                                <FormattedMessage id="app.components.ef_search_bar.search_item" />
                            </p>
                            <Form.Item name="keywordItems" noStyle={true}>
                                <Select mode="multiple" showArrow={true} allowClear className="keyword-items">
                                    {this.createSelectItems(this.props.keywordItems)}
                                </Select>
                            </Form.Item>
                            <Form.Item name="searchWord" noStyle={true}>
                                <Input
                                    className="search-word"
                                    placeholder={this.props.intl.formatMessage(messages["search_hint"])}
                                />
                            </Form.Item>
                            <Form.Item name="searchType" noStyle={true}>
                                <Select allowClear className="search-type">
                                    {this.createSelectItems(this.matchTypes)}
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="action-container">
                            <Button type="primary" htmlType="submit" className="search-button">
                                <FormattedMessage id="app.components.ef_search_bar.search_button" />
                            </Button>
                            <Button onClick={this.handleResetButtonClick} className="reset-button">
                                <FormattedMessage id="app.components.ef_search_bar.reset_button" />
                            </Button>
                        </div>

                    </div>
                </div>
            </Form>

        );
    }
}


export default injectIntl(EFCSearchBar)
