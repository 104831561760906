import React, { Component } from "react";
import { Layout } from "antd";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import EFCBreadcrumb from "@/components/ef-c-breadcrumb";
import EFUpdateUserInfoView from "@/views/setting/update-userinfo.js";
import EFResetPasswordView from "@/views/setting/update-password.js";
import EFClientList from "../../../views/admin/client-list";
import EFClientNew from "../../../views/admin/client-new";
import EFClientDetail from "../../../views/admin/client-detail"
import EFAnalystList from "../../../views/admin/manager-list"
import EFAnalystNew from "../../../views/admin/manager-new"
import EFAnalystDetail from "../../../views/admin/manager-detail"
import EFAdminResetPassword from "../../../views/admin/reset-password"

import EFUserList from "../../../views/admin/user-list"
import EFUserNew from "../../../views/admin/user-new"
import EFUserDetail from "../../../views/admin/user-detail"

import EFTrademarkList from "@/views/trademark/trademark-list";
import EFTrademarkDetail from "@/views/trademark/trademark-detail";
import EFTrademarkNew from "@/views/trademark/trademark-new";
import EFTrademarkRegistration from "@/views/trademark/trademark-registration";

import EFAnalystECSite from "@/views/analyst/ec-site/ec-site-list"
import EFCreateECSiteView from "@/views/analyst/ec-site/ec-site-create"
import EFDetailECSiteView from "@/views/analyst/ec-site/ec-site-detail"

import EFAnalystInfringementCategory from "../../analyst/ec-master/infringement-category"

import EFECDataList from "@/views/ec/ec-list"
import EFECDataDetail from "@/views/ec/ec-detail"
import EFDashboard from '@/views/dashboard'
import EFHome from '@/views/home'
import EFNoticeCenterView from '@/views/notice-center'

import EFWSDataList from '@/views/ws/ws-list'
import EFWSDataDetail from "@/views/ws/ws-detail"

import EFECTaskList from "@/views/ec-task/list-view"
import EFCreateECTaskView from "@/views/ec-task/create-view"
import EFECTaskDetailView from "@/views/ec-task/detail-view"

import EFIPFNews from "@/views/news"
import EFTestPurchase from "@/views/test-purchase"
import EFDataSetting from "@/views/client/data-setting"

import Page404 from "../../error-page/404";

import { connect } from "react-redux";

import "./index.less"

const { Content } = Layout;

class EFContent extends Component {

    redirctToDefaultPage() {
        return
    }

    commonRoute() {
        return (
            [
                <Route exact
                    component={EFUpdateUserInfoView}
                    key="userinfo"
                    path="/profile"
                />,
                <Route exact
                    component={EFResetPasswordView}
                    key="reset-password"
                    path="/profile/reset-password"
                />
            ]
        )
    }

    adminRoute() {
        if (this.props.role & 4) {
            return (
                [

                    <Route component={EFAdminResetPassword}
                        key="admin-client-new"
                        path="/manage/reset-password/:id" />,
                    <Route component={EFClientNew}
                        key="admin-client-new"
                        path="/manage/client/new" />,
                    <Route component={EFClientDetail}
                        key="admin-client-detail"
                        path="/manage/client/:id" />,
                    <Route component={EFClientList}
                        key="admin-client-list"
                        path="/manage/client" />,
                    <Route component={EFAnalystNew}
                        key="admin-analyst-new"
                        path="/manage/analyst/new" />,
                    <Route component={EFAnalystDetail}
                        key="admin-analyst-detail"
                        path="/manage/analyst/:id" />,
                    <Route component={EFAnalystList}
                        key="admin-analyst-list"
                        path="/manage/analyst" />,
                    <Route component={EFUserNew}
                        key="admin-user-new"
                        path="/manage/user/new" />,
                    <Route component={EFUserDetail}
                        key="admin-user-detail"
                        path="/manage/user/:id" />,
                    <Route component={EFUserList}
                        key="admin-user-list"
                        path="/manage/user" />,
                    <Route component={EFHome}
                        key="admin-home"
                        path="/home" />,
                    <Route component={EFNoticeCenterView}
                        key="notice-center"
                        path="/notice" />,
                    <Route component={EFIPFNews}
                        key="ipf-news"
                        path="/news" />,
                ]
            )
        }
    }

    analystRoute() {
        if (this.props.role & 2) {
            return (
                [
                    <Route component={EFCreateECSiteView}
                        key="analyst-ec-site-new"
                        path="/analyst/ec-site/new" />,
                    <Route component={EFDetailECSiteView}
                        key="analyst-ec-site-detail"
                        path="/analyst/ec-site/detail/:id" />,
                    <Route component={EFAnalystECSite}
                        key="analyst-ec-site"
                        path="/analyst/ec-site" />,
                    <Route component={EFAnalystInfringementCategory}
                        key="analyst-infringement-category"
                        path="/analyst/infringement-category" />,
                    <Route component={EFTrademarkNew}
                        key="trademark-new"
                        path="/trademark/new" />,
                    <Route component={EFTrademarkDetail}
                        key="trademark-detail"
                        path="/trademark/:id" />,
                    <Route component={EFTrademarkList}
                        key="trademark-list"
                        path="/trademark" />,
                    <Route component={EFECDataDetail}
                        key="analyst-ec-data-detail"
                        path="/ec-data/:cid/:rid" />,
                    <Route component={EFECDataList}
                        key="analyst-ec-data"
                        path="/ec-list" />,
                    <Route component={EFWSDataList}
                        key="analyst-ws-data"
                        path="/ws-list" />,
                    <Route component={EFWSDataDetail}
                        key="analyst-ws-data-detail"
                        path="/ws-data/:cid/:rid" />,
                    <Route component={EFDashboard}
                        key="online-dashboard"
                        path="/online-dashboard" />,
                    <Route component={EFClientDetail}
                        key="admin-client-detail"
                        path="/manage/client/:id" />,
                    <Route component={EFClientList}
                        key="admin-client-list"
                        path="/manage/client" />,
                    <Route component={EFHome}
                        key="analyst-home"
                        path="/home" />,
                    <Route component={EFNoticeCenterView}
                        key="notice-center"
                        path="/notice" />,
                    <Route component={EFECTaskList}
                        key="ec-crawler-task"
                        path="/task/ec-crawler-task" />,
                    <Route component={EFCreateECTaskView}
                        key="new-ec-crawler-task"
                        path="/task/new-ec-scraping-task" />,
                    <Route component={EFECTaskDetailView}
                        key="ec-scraping-task-detail"
                        path="/task/ec-scraping-task-detail/:id" />,
                    <Route component={EFIPFNews}
                        key="ipf-news"
                        path="/news" />,
                    <Route component={EFTestPurchase}
                        key="test-purchase"
                        path="/test-purchase" />,
                ]
            )
        }
    }

    clientRoute() {
        if (this.props.role & 1) {
            return (
                [
                    <Route component={EFTrademarkRegistration}
                        key="trademark-registration"
                        path="/trademark/registration" />,
                    <Route component={EFTrademarkNew}
                        key="trademark-new"
                        path="/trademark/new" />,
                    <Route component={EFTrademarkDetail}
                        key="trademark-detail"
                        path="/trademark/:id" />,
                    <Route component={EFTrademarkList}
                        key="trademark-list"
                        path="/trademark" />,
                    <Route component={EFECDataDetail}
                        key="analyst-ec-data-detail"
                        path="/ec-data/:cid/:rid" />,
                    <Route component={EFECDataList}
                        key="ec-data"
                        path="/ec-list" />,
                    <Route component={EFWSDataList}
                        key="analyst-ws-data"
                        path="/ws-list" />,
                    <Route component={EFWSDataDetail}
                        key="analyst-ws-data-detail"
                        path="/ws-data/:cid/:rid" />,
                    <Route component={EFDashboard}
                        key="online-dashboard"
                        path="/online-dashboard" />,
                    <Route component={EFHome}
                        key="client-home"
                        path="/home" />,
                    <Route component={EFNoticeCenterView}
                        key="notice-center"
                        path="/notice" />,
                    <Route component={EFIPFNews}
                        key="ipf-news"
                        path="/news" />,
                    <Route component={EFDataSetting}
                        key="data-setting"
                        path="/data-setting" />,

                ]
            )
        }
    }

    render() {
        let bread = (<EFCBreadcrumb></EFCBreadcrumb>)
        if (this.props.location.pathname === "/home") {
            bread = null
        }
        return (
            <Content
                className="ef-content-container"
            // style={{ margin: '24px 16px 0', overflow: 'initial' }}
            >
                {bread}
                <Switch>
                    <Redirect exact from="/" to="profile" />
                    {this.adminRoute()}
                    {this.commonRoute()}
                    {this.analystRoute()}
                    {this.clientRoute()}
                    <Route component={Page404}
                        key="404"
                    // path="/404"
                    />,

                </Switch>
            </Content>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    };
};

export default connect(mapStateToProps)(withRouter(EFContent));


