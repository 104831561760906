// setting intl messages

import { defineMessages } from 'react-intl'
const messages = defineMessages({
    'basicinfo.name.error': {
        id: "app.ui.profile.section.basicinfo.name.error",
        defaultMessage: "Please input your name.",
    },
    'basicinfo.tel.error': {
        id: "app.ui.profile.section.basicinfo.tel.error",
        defaultMessage: "Please check your tel number.",
    },
    'message.success.update_success': {
        id: "app.ui.profile.message.success.update_success",
        defaultMessage: "Update successfully.",
    },
    'message.error.update_failed': {
        id: "app.ui.profile.message.error.update_failed",
        defaultMessage: "Update unsuccessfully.",
    },

    'message.error.password_character': {
        id: "app.ui.profile.message.error.password_character",
        defaultMessage: "The password contains three character categories: digits, lowercase characters and special characters and must be at least 8 characters long.",
    },
    'message.error.confirm_password_same': {
        id: "app.ui.profile.message.error.confirm_password_same",
        defaultMessage: "The confirm password must be the same as the password.",
    },

    'message.success.password_reset_success': {
        id: "app.ui.profile.message.success.password_reset_success",
        defaultMessage: "Your password has been reset successfully.",
    },
    'message.error.password_reset_failed': {
        id: "app.ui.profile.message.error.password_reset_failed",
        defaultMessage: "Failed to reset password.",
    },
    'message.error.no_current_password': {
        id: "app.ui.profile.password_reset.message.error.no_current_password",
        defaultMessage: "Please input the current password.",
    },

    'message.error.no_new_password': {
        id: "app.ui.profile.password_reset.message.error.no_new_password",
        defaultMessage: "Please input the new password.",
    },
    'message.error.no_confirm_new_password': {
        id: "app.ui.profile.password_reset.message.error.no_confirm_new_password",
        defaultMessage: "Please input the comfirm new password.",
    },
    'message.error.no_code': {
        id: "app.ui.profile.password_reset.message.error.no_code",
        defaultMessage: "Please input the verification code.",
    },
})

export default messages