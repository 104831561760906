import React, { Component } from "react";
import { Badge, Layout, Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import menuList from "@/config/menu-config.js"
import { connect } from "react-redux";
import "./index.less"
import { reqUnviewedNoticeCount } from "../../../api/notice-center"

import { setMenuCollapsed } from "@/store/actions/menu.js"
import store from "@/store";


const { Sider } = Layout;


const keySpritor = "***";

class EFSider extends Component {

    state = {
        menuTreeNode: null,
        openKey: [],
        path: "",
        unviewed: 0,
        collapsed: false,
        logoClass: "logo-img",
        logoImage: "/ipf-logo.png"
    };

    setCollapsed(collapsed, type) {
        const logoClass = collapsed ? "logo-img-small" : "logo-img"
        const logoImage = collapsed ? "/ipf-logo-circle.png" : "/ipf-logo.png"

        store.dispatch(setMenuCollapsed(collapsed));

        this.setState({
            collapsed: collapsed,
            logoClass,
            logoImage
        })
    }

    doGetUnviewedNoticeCount() {
        reqUnviewedNoticeCount().then((resp) => {
            if (resp.data.code === 0) {
                // console.log(resp)
                this.setState({
                    unviewed: resp.data.data.unviewed
                })
            }
        })
    }

    componentDidMount() {
        this.doGetUnviewedNoticeCount()
        this.timer = setInterval(this.doGetUnviewedNoticeCount.bind(this), 1000 * 30)
    }

    componentWillUnmount = () => {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    handleClick = e => {
        let path = e.key.split(keySpritor)[1]
        this.setState({ path })
    };

    handleSelect = e => {
    };
    handleOpenChange = e => {

    };

    onSubmenuClick = (values) => {
        let submenu = menuList.find(submenu => {
            return submenu.id === values.key
        })
        if (submenu && submenu.path) {
            this.props.history.push(submenu.path)
        }
    }

    filterMenuItem = (item, role) => {
        const { roles } = item
        if (roles & role) {
            return true;
        } else if (item.children) {
            return !!item.children.find((child) => child.roles & role)
        }
        return false;
    }

    getMenuNodes = (menuList, role) => {

        let submenu = menuList.find(submenu => {
            return submenu.path === this.props.location.pathname && (role & submenu.roles)
        })

        return menuList.reduce((pre, item) => {

            if (this.filterMenuItem(item, role)) {
                if (!item.children) {
                    if (item.id === "notice") {
                        pre.push(
                            {
                                // "label": item.title,
                                "label": <> <Link to={item.path}>
                                    <span>
                                        {item.title}
                                    </span>
                                </Link>
                                    <Badge count={this.state.unviewed} className="ef-notice-badge">
                                    </Badge>
                                </>,
                                "icon": item.icon,
                                "key": item.id
                            }
                        )
                    } else {
                        pre.push(
                            {
                                "label": <Link to={item.path}>
                                    <span>{item.title}</span>
                                </Link>,
                                // "label": item.title,
                                "icon": item.icon,
                                "key": item.id
                            }
                            // < Menu.Item key={item.id}
                            //     icon={item.icon}
                            // >
                            //     <Link to={item.path}>
                            //         <span>{item.title}</span>
                            //     </Link>
                            // </Menu.Item >
                        )
                    }

                } else {

                    let menuClass = "";
                    let title = item.title
                    pre.push(
                        {
                            "label": title,
                            "icon": item.icon,
                            "key": item.id,
                            onTitleClick: this.onSubmenuClick,
                            popupClassName: menuClass,
                            children: this.getMenuNodes(item.children, role)
                        }
                    );
                }
            } else {

            }
            return pre;
        }, [])
    }

    getOpenSubMenuAndSelectItem(pathname) {
        if (pathname) {
            for (const subMenu of menuList) {
                if (this.props.role & subMenu.roles) {
                    if (subMenu.re && pathname.match(subMenu.re)) {
                        return { openMenu: subMenu.id, selectItem: subMenu.id }
                    } else {
                        if (subMenu.children) {
                            for (const child of subMenu.children) {
                                if (child.path && pathname === child.path) {
                                    return { openMenu: subMenu.id, selectItem: child.id }
                                }
                            }
                        }
                    }
                }
            }


            for (const subMenu of menuList) {
                if (this.props.role & subMenu.roles) {
                    if (subMenu.re && pathname.match(subMenu.re)) {
                        return { openMenu: subMenu.id, selectItem: subMenu.id }
                    } else {
                        if (subMenu.children) {
                            for (const child of subMenu.children) {
                                if (child.re && pathname.match(child.re)) {
                                    return { openMenu: subMenu.id, selectItem: child.id }
                                }
                            }
                        }
                    }
                }
            }
        }
        return { openMenu: "", selectItem: "" }
    }

    getActiveMenuAndSelectItem() {
        if (this.props.activeKey) {
            for (const subMenu of menuList) {
                if (this.props.role & subMenu.roles) {
                    if (subMenu.id === this.props.activeKey) {
                        return { openMenu: subMenu.id, selectItem: subMenu.id }
                    } else {
                        if (subMenu.children) {
                            for (const child of subMenu.children) {
                                if (child.id === this.props.activeKey) {
                                    return { openMenu: subMenu.id, selectItem: child.id }
                                }
                            }
                        }
                    }
                }
            }
        }
        return { openMenu: "", selectItem: "" }
    }

    render() {
        let { openMenu, selectItem } = this.getOpenSubMenuAndSelectItem(this.props.location.pathname)
        return (

            <Sider
                className="ef-side-container"
                width="256"
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
                collapsed={this.state.collapsed}
                collapsible={true}
                onCollapse={(collapsed, type) => this.setCollapsed(collapsed, type)}
            >
                <div className="ef-menu-container">
                <div
                    className="logo-wrapper"
                    // style={{ 'background-image': this.state.logoImage }}
                >
                    <img src={this.state.logoImage} alt="logo" className={this.state.logoClass} ></img>
                </div>
                <Menu
                    onClick={this.handleClick}
                    onSelect={this.handleSelect}
                    onOpenChange={this.handleOpenChange}
                    defaultOpenKeys={[openMenu]}
                    selectedKeys={[selectItem]}
                    theme="dark"
                    mode="inline"
                    items={this.getMenuNodes(menuList, this.props.role)}
                >
                </Menu>
                </div>
            </Sider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.mainMenu,
        ...state.user
    }
}


export default connect(mapStateToProps)(withRouter(EFSider));