import React, { Component } from "react";
import { connect } from "react-redux";
import EFSimpleContainer from "@/components/ef-simple-container"
import { TrademarkSites } from "@/config/trademark-site.js"
import { CountryConfig } from "@/config/country.js"
import { ProductTrademarkType } from "@/config/product-type.js"

import { Button, Row, Col, Select, Form, Input, DatePicker, message, Checkbox } from "antd";
import { reqCreateTrademark } from "@/api/trademark.js"
import { reqFetchClients } from '@/api/analyst-client'
import store from "@/store";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"

import TradeMarkPictureUploader from "../common/image-uploader"

import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../messages"
import common_messages from "../../../locales/messages";
import { getCountryConfigDict } from "../../../config/country";

import '../index.less'

class TrademarkNew extends Component {

    constructor(props) {
        super(props);
        this.selectTradeMarkSite = "";
        this.state = { clients: [] }
        this.isPictureUploading = false;
        if (this.props.role & 2) {
            reqFetchClients().then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ clients: data.data.client });
                }
            }).catch((error) => {
            })
        }
    }

    componentDidMount() {
        store.dispatch(setMenuActiveKey("trademark"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.layout.menu.legal_services.tm_registration" />,
                path: "/trademark",
            },
            {
                name: <FormattedMessage id="app.common.action.add" />,
            },
        ]));
    }

    onGoToTradeMarkSite = () => {
        if (this.selectTradeMarkSite && this.selectTradeMarkSite.length > 0) {
            window.open(this.selectTradeMarkSite)
        } else {
            message.error(this.props.intl.formatMessage(messages["message.error.no_search_db"]))
        }
    }

    onSelectTradeMarkSiteChanged = (value, option) => {
        this.selectTradeMarkSite = option ? option.value : ""
    }

    onFinish = (values) => {
        if (this.isPictureUploading) {
            message.warning(this.props.intl.formatMessage(messages["message.info.image_uploading"]))
            return;
        }
        let clientId
        if (this.props.role & 2) {
            clientId = values.trademark_client
        } else {
            clientId = this.props.companyInfo ? this.props.companyInfo.client_id : undefined
        }

        let parameter = {}
        if (this.imageName) {
            parameter.image = this.imageName;
        }

        parameter = {
            clientId: clientId,
            "no": values.trademark_no,
            "region": values.trademark_region,
            "type": values.trademark_type,
            "text": values.trademark_text,
            // "registerDate": values.trademark_register_date,
            // "invalidDate": values.trademark_invalid_date,
            "belongsto": values.trademark_belongsto,
            "descriptionJP": values.trademark_description_ja,
            "descriptionEN": values.trademark_description_en,
            "descriptionCN": values.trademark_description_cn,
            "status": values.trademark_status,
            "remarks": values.trademark_remarks,
            "remindFlag": values.trademark_remind_flag && values.trademark_remind_flag[0] === "1" ? true : false,
            ...parameter
        }
        if (values.trademark_register_date) {
            parameter.registerDate = values.trademark_register_date.format("YYYY-MM-DD");
        }
        if (values.trademark_invalid_date) {
            parameter.invalidDate = values.trademark_invalid_date.format("YYYY-MM-DD");
        }

        reqCreateTrademark(parameter).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                message.success(this.props.intl.formatMessage(messages["message.info.trademark_added"]));
                this.props.history.push("/trademark")
            }
        }).catch((error) => {

        })
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        message.error(errors[0])
    }

    getClientsForAnalyst = () => {
        if (this.props.role & 2) {
            return (
                <Row>
                    <Col span={12}>
                        <div className="item-wrapper">
                            <div className="item-label-container">
                                <p>
                                    <FormattedMessage id="app.ui.trademark.table.client" />
                                </p>
                            </div>
                            <div className="item-content-container">
                                <Form.Item noStyle={true} name="trademark_client" rules={[
                                    {
                                        required: true,
                                        message: this.props.intl.formatMessage(messages["message.error.no_client"]),
                                    },
                                ]}>
                                    <Select style={{ width: 240 }}>
                                        {this.state.clients.map((client) => {
                                            return <Select.Option value={client.client_id} key={client.client_id}>{client.client_name}</Select.Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </div>
                        </div>
                    </Col>
                </Row>
            )
        }
    }

    onPictureRemove = (data) => {
        this.imageName = null
    }

    onPictureFinishUpload = (data) => {
        if (data && data.name) {
            this.imageName = data.name
        }
        this.isPictureUploading = false;
    }

    beforePictureUpload = () => {
        this.isPictureUploading = true;
    }

    render() {
        document.title = this.props.intl.formatMessage(messages["registration_add_title"]);
        return (
            <div className="trademark-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={24}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.trademark.add.container_search.title" />}>
                                <Select onChange={this.onSelectTradeMarkSiteChanged} style={{ width: 240 }}>
                                    {TrademarkSites.map((trademarkSite) => {
                                        let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                                        return <Select.Option value={trademarkSite.url} key={trademarkSite.url}>{trademarkSite.name[localeKey]}</Select.Option>
                                    })}
                                </Select>
                                <Button className="search-site" type="primary" onClick={this.onGoToTradeMarkSite}>
                                    <FormattedMessage id="app.ui.trademark.add.container_search.button" />
                                </Button>
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <EFSimpleContainer title={<FormattedMessage id="app.ui.trademark.add.container_body.title" />}>
                                {this.getClientsForAnalyst()}
                                <Row>
                                    <Col span={12}>
                                        <div className="item-wrapper">
                                            <div className="item-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.label.trademark_image" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <TradeMarkPictureUploader
                                                    onRemove={this.onPictureRemove}
                                                    onFinishUpload={this.onPictureFinishUpload}
                                                    beforeUpload={this.beforePictureUpload}
                                                    clientId={this.props.companyInfo && this.props.companyInfo.client_id ? this.props.companyInfo.client_id : null}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <div className="item-wrapper">
                                            <div className="item-required-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_region" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item
                                                    noStyle={true}
                                                    name="trademark_region"
                                                    // label="登録国・地区（必須）"
                                                    // labelCol={{
                                                    //     span: 6,
                                                    // }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: this.props.intl.formatMessage(messages["message.error.no_region"]),
                                                        },
                                                    ]}>
                                                    <Select allowClear className="select-box"
                                                        showSearch
                                                        filterOption={(inputValue, option) => {
                                                            return option.key.indexOf(inputValue) > -1 ? true : false;
                                                        }}>

                                                        {CountryConfig.map((country) => {
                                                            let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                                                            return <Select.Option
                                                                key={`${country.code}${country.name.ja}${country.name.en}`}
                                                                value={country.code}>
                                                                {country.name[localeKey]}
                                                            </Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="item-wrapper">
                                            <div className="item-required-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_no" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_no" rules={[
                                                    {
                                                        type: "string",
                                                        max: 200,
                                                        required: true,
                                                        message: this.props.intl.formatMessage(messages["message.error.no_trademark_no"]),
                                                    },
                                                ]}>
                                                    <Input className="input-text" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-required-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_product_genre" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_type" rules={[
                                                    {
                                                        type: "string",
                                                        max: 200,
                                                        required: true,
                                                        message: this.props.intl.formatMessage(messages["message.error.no_product_genre"]),
                                                    },
                                                ]}>
                                                    <Select className="select-box"
                                                        showSearch
                                                        filterOption={(inputValue, option) => {
                                                            return option.key.indexOf(inputValue) > -1 ? true : false;
                                                        }}>
                                                        {ProductTrademarkType.map(typeInfo => {
                                                            let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                                                            return <Select.Option
                                                                key={`${typeInfo.value}${typeInfo.name.ja}${typeInfo.name.en}`}
                                                                value={typeInfo.value}>
                                                                <FormattedMessage id="app.ui.trademark.add.product_genre_prefix" values={{ number: typeInfo.value }} />
                                                                {`${typeInfo.name[localeKey]}`}
                                                            </Select.Option>
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-required-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_word" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_text" rules={[
                                                    {
                                                        type: "string",
                                                        max: 200,
                                                        required: true,
                                                        message: this.props.intl.formatMessage(messages["message.error.no_word_mark"]),
                                                    },
                                                ]}>
                                                    <Input className="input-text" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="item-wrapper">
                                            <div className="item-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_registration_date" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_register_date">
                                                    <DatePicker />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_expire_date" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_invalid_date">
                                                    <DatePicker />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.remind_mail_flg" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_remind_flag">
                                                    <Checkbox.Group options={[
                                                        {
                                                            label: this.props.intl.formatMessage(messages["add.remind_message"]),
                                                            value: "1"
                                                        }
                                                    ]} />
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div className="item-wrapper">
                                            <div className="item-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_right_holder" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_belongsto">
                                                    <Input className="input-text" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_registration_status" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_status">
                                                    <Select className="select-box">
                                                        <Select.Option value={1}>
                                                            <FormattedMessage id="app.trademark_registration_status.register" />
                                                        </Select.Option>
                                                        <Select.Option value={2}>
                                                            <FormattedMessage id="app.trademark_registration_status.applying" />
                                                        </Select.Option>
                                                        <Select.Option value={3}>
                                                            <FormattedMessage id="app.trademark_registration_status.rejection" />
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <div className="item-wrapper">
                                            <div className="item-long-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.description_ja" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_description_ja">
                                                    <Input.TextArea className="description-text" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-long-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.description_en" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_description_en">
                                                    <Input.TextArea className="description-text" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-long-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.description_cn" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_description_cn">
                                                    <Input.TextArea className="description-text" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                        <div className="item-wrapper">
                                            <div className="item-long-label-container">
                                                <p>
                                                    <FormattedMessage id="app.ui.trademark.table.trademark_remarks" />
                                                </p>
                                            </div>
                                            <div className="item-content-container">
                                                <Form.Item noStyle={true} name="trademark_remarks">
                                                    <Input.TextArea className="description-text" />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </EFSimpleContainer>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={8}>
                            <Button type="primary" htmlType="submit" className="update-button">
                                <FormattedMessage id="app.common.action.add" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
    };
};

export default connect(mapStateToProps)(injectIntl(TrademarkNew));
