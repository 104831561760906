import "./index.less"
import React, { Component } from "react";
import { Row, Col, Input, Card, Form, Select } from "antd";

import { reqCreateInfringementCategory, reqCreateInfringementSubcategory, reqFetchInfringementCategories, reqRemoveInfringementCategory, reqRemoveInfringementSubcategory, reqUpdateInfringementCategory, reqUpdateInfringementSubcategory } from '../../../api/analyst-infringement-category'
import store from "@/store";
import EFCMasterTable from "@/components/ef-c-master-table";
import { InfringementCommonCategories } from "@/config/infringement-common-category.js"

import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js";
import { setMenuActiveKey } from "@/store/actions/menu.js"

import { ProFormText, ProFormSelect } from '@ant-design/pro-form';

class AnalystInfringementCategory extends Component {


    onRowClick = (record) => {
        let action = (event) => {

            this.setState({
                subCategories: record.subcategories,
                selectedRowKeys: [record.id]
            })
        }
        return action
    }

    constructor(props) {
        super(props)
        this.state = {
            mainCategories: [],
            subCategories: [],
            selectedRowKeys: [],
            editRowDictionary: {}
        }
        this.formRef = React.createRef();
        this.modalFormRef = React.createRef();

        this.mainCategoryColumns = [
            // {
            //     title: '番号',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'カテゴリ基準',
                dataIndex: 'name',
                key: 'name',

                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "カテゴリ基準を入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },

            {
                title: 'カテゴリ基準(英文)',
                dataIndex: 'name_en',
                key: 'name_en',

                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_en_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "カテゴリ基準(英文)を入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            // {
            //     title: 'コード',
            //     dataIndex: 'code',
            //     key: 'code',
            //     editCell: (text, record) => {
            //         return (
            //             <Form.Item
            //                 noStyle={true}
            //                 name={"code_" + record.id}
            //                 rules={[
            //                     {
            //                         type: "string",
            //                         required: true,
            //                         message: "コードを入力ください",
            //                     }
            //                 ]}>
            //                 <Input></Input>
            //             </ Form.Item >
            //         )
            //     }
            // },

        ];

        this.subCategoryColumns = [
            // {
            //     title: '番号',
            //     dataIndex: 'id',
            //     key: 'id',
            // },
            {
                title: 'カテゴリ',
                dataIndex: 'name',
                key: 'name',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "カテゴリを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: 'カテゴリ(英文)',
                dataIndex: 'name_en',
                key: 'name_en',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_en_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "カテゴリ(英文)を入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: 'コード',
                dataIndex: 'code',
                key: 'code',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"code_" + record.id}
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "コードを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '集計',
                dataIndex: 'commonCategory',
                key: 'commonCategory',
                width: 200,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"commonCategory_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "集計を入力ください",
                                }
                            ]}>
                            <Select allowClear
                                style={{ width: 200 }}
                                showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                {
                                    InfringementCommonCategories.map((category) => {
                                        return <Select.Option key={`${category.name.ja}${category.name.en}`} value={category.id}>{category.name.ja}</Select.Option>
                                    })
                                }
                            </Select>
                        </ Form.Item >
                    )
                },
                renderText: (record) => {
                    if (record.commonCategory) {
                        let category = InfringementCommonCategories.find(category => category.id === record.commonCategory)
                        return category ? category.name.ja : ""
                    }
                    return ""

                }
            },

        ];

        reqFetchInfringementCategories().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ mainCategories: data.data.categories });
            }

        }).catch((error) => {

        })

    }

    componentDidMount() {
        store.dispatch(setMenuActiveKey("analyst-infringement-category"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "カテゴライズ基準",
                path: "/analyst/infringement-category",
            },
        ]));
    }


    rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            this.setState({ selectedRowKeys })
        },
    };

    getTableProps = () => {
        let selectedRowKeys = this.state.selectedRowKeys
        let _this = this
        let props = {
            pagination: false,
            onRow: (record) => {
                return {
                    onClick: _this.onRowClick(record), // 点击行
                };
            },
            rowSelection: {
                type: "radio",
                selectedRowKeys: selectedRowKeys,
                ...this.rowSelection,
            },

            rowKey: (record) => {
                return record.id
            }
        }
        return props
    }

    // Main Category
    onMainTableRemoveClick = (record) => {
        reqRemoveInfringementCategory({
            categoryId: record.id
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ mainCategories: data.data.categories });
            }
        }).catch((e) => {

        })
    }

    onMainTableSaveClick = (record, values) => {
        reqUpdateInfringementCategory({
            categoryId: record.id,
            name: values["name_" + record.id],
            nameEn: values["name_en_" + record.id],

            // code: values["code_" + record.id]
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ mainCategories: data.data.categories });
            }
        }).catch((e) => {

        })
    }
    onMainTableModalFinished = (values) => {
        reqCreateInfringementCategory({
            name: values.name,
            nameEn:values.name_en,
            code: values.code
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ mainCategories: data.data.categories });
            }
        }).catch((e) => {

        })
    }

    getMainTableEditFieldsValue = (record) => {
        let row = {}
        row["name_" + record.id] = record.name;
        row["name_en_" + record.id] = record.name_en;
        // row["code_" + record.id] = record.code;
        return row
    }

    getMainTableModalItems = () => {
        return (
            <>
                <ProFormText width="md" name="name" label="カテゴリ基準" rules={[
                    {
                        required: true,
                        message: 'カテゴリ基準を入力ください。',
                    },
                ]}
                />
                <ProFormText width="md" name="name_en" label="カテゴリ基準(英文)" rules={[
                    {
                        required: true,
                        message: 'カテゴリ基準(英文)を入力ください。',
                    },
                ]}
                />
                {/* <ProFormText width="md" name="code" label="コード" rules={[
                    {
                        required: true,
                        message: 'コードを入力ください。',
                    },
                ]}
                /> */}
            </>
        )
    }

    // Sub Category
    onSubTableRemoveClick = (record) => {
        if (this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0) {
            reqRemoveInfringementSubcategory({
                categoryId: this.state.selectedRowKeys[0],
                subcategoryId: record.id,
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    let temp = [...this.state.mainCategories]
                    let updateCategory = temp.find(obj => {
                        return obj.id === data.data.categoryId
                    })
                    updateCategory.subcategories = data.data.categories;
                    this.setState({ subCategories: data.data.categories, mainCategories: temp });
                }
            }).catch((e) => {
            })
        }
    }

    getSubTableEditFieldsValue = (record) => {
        let row = {}
        row["name_" + record.id] = record.name;
        row["name_en_" + record.id] = record.name_en;
        row["code_" + record.id] = record.code;
        row["commonCategory_" + record.id] = record.commonCategory;
        return row
    }

    onSubTableSaveClick = (record, values) => {
        if (this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0) {
            reqUpdateInfringementSubcategory({
                categoryId: this.state.selectedRowKeys[0],
                subcategoryId: record.id,
                subcategoryName: values["name_" + record.id],
                subcategoryNameEn: values["name_en_" + record.id],
                subcategoryCode: values["code_" + record.id],
                commonCategory: parseInt(values["commonCategory_" + record.id]),
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    let temp = [...this.state.mainCategories]
                    let updateCategory = temp.find(obj => {
                        return obj.id === data.data.categoryId
                    })
                    updateCategory.subcategories = data.data.categories;
                    this.setState({ subCategories: data.data.categories, mainCategories: temp });

                }
            }).catch((e) => {
            })
        }
    }

    onSubTableModalFinished = (values) => {
        if (this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0) {
            reqCreateInfringementSubcategory({
                subcategoryName: values.name,
                subcategoryNameEn: values.name_en,
                subcategoryCode: values.code,
                commonCategory: parseInt(values.commonCategory),
                categoryId: this.state.selectedRowKeys[0],

            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    let temp = [...this.state.mainCategories]
                    let updateCategory = temp.find(obj => {
                        return obj.id === data.data.categoryId
                    })
                    updateCategory.subcategories = data.data.categories;
                    this.setState({ subCategories: data.data.categories, mainCategories: temp });
                }
            }).catch((e) => {

            })

        }
    }

    getSubTableModalItems = () => {
        return (
            <>
                <ProFormText width="md" name="name" label="カテゴリ" rules={[
                    {
                        required: true,
                        message: 'カテゴリを入力ください。',
                    },
                ]}
                />
                <ProFormText width="md" name="name_en" label="カテゴリ(英文)" rules={[
                    {
                        required: true,
                        message: 'カテゴリ(英文)を入力ください。',
                    },
                ]}
                />
                <ProFormText width="md" name="code" label="コード" rules={[
                    {
                        required: true,
                        message: 'コードを入力ください。',
                    },
                ]}
                />
                <ProFormSelect
                    width="md"
                    showSearch
                    fieldProps={{
                        optionFilterProp: "children",
                        filterOption: (inputValue, option) => {
                            return option.key.indexOf(inputValue) > -1 ? true : false;
                        }
                    }}

                    name="commonCategory"
                    options={
                        InfringementCommonCategories.map((category) => {
                            return {
                                value: category.id,
                                label: category.name.ja,
                                key: `${category.name.ja}${category.name.en}`,
                            }
                        })
                    }
                    label="集計"
                    rules={[
                        {
                            required: true,
                            message: '集計を入力ください。',
                        },
                    ]}
                />
            </>
        )
    }

    getSubTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }


    render() {
        document.title = "カテゴライズ基準";
        return (
            <div className="master-container">
                <Row>
                    <Col span={24}>
                        <Card bordered={false}>
                            <EFCMasterTable
                                columns={this.mainCategoryColumns}
                                dataSource={this.state.mainCategories}
                                tableProps={this.getTableProps()}
                                modalTitle="カテゴリ基準"
                                onRemoveClick={this.onMainTableRemoveClick}
                                getEditFieldsValue={this.getMainTableEditFieldsValue}
                                onSaveClick={this.onMainTableSaveClick}
                                onModalFinished={this.onMainTableModalFinished}
                                modalItems={this.getMainTableModalItems()}
                            />
                        </Card>
                    </Col>
                </Row>
                <Row>
                <Col span={24}>
                        <Card bordered={false}>
                            <EFCMasterTable
                                columns={this.subCategoryColumns}
                                dataSource={this.state.subCategories}
                                modalTitle="カテゴリ・集計"
                                onRemoveClick={this.onSubTableRemoveClick}
                                getEditFieldsValue={this.getSubTableEditFieldsValue}
                                onSaveClick={this.onSubTableSaveClick}
                                onModalFinished={this.onSubTableModalFinished}
                                modalItems={this.getSubTableModalItems()}
                                tableProps={this.getSubTableProps()}
                            />
                        </Card>
                    </Col>

                </Row>
            </div>
        )
    }
}

export default AnalystInfringementCategory