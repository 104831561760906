import "./index.less"
import React, { useEffect,  useState } from "react";
import { Card, Form, Select, Input, Table, Space, Button, Dropdown, Modal } from "antd";
import {  reqFetchECSites, reqRemoveECSite,  } from '@/api/analyst-site';
import { reqFetchInfringementCategories } from '@/api/analyst-infringement-category'
import { CountryConfig } from "@/config/country.js"
import { DownOutlined } from '@ant-design/icons';

import store from "@/store";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js";
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { compareString, compareNumber } from "@/utils/compare.js"


function AnalystECSiteList(props) {
    const [datasource, setDatasource] = useState([])
    const [categories, setCategories] = useState([])

    useEffect(() => {
        reqFetchECSites().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setDatasource(data.data.sites)
            }
        }).catch((error) => {
        })

        reqFetchInfringementCategories().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setCategories(data.data.categories)
            }
        }).catch((error) => {
        })
    }, [])

    useEffect(() => {
        store.dispatch(setMenuActiveKey("analyst-ec-site"));
        store.dispatch(setContentContainerHeaderAction([{
            name: "新規",
            path: "/analyst/ec-site/new"
        }]));

        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "EC登録",
                path: "/analyst/ec-site",
            },
        ]));
        document.title = "EC登録"
    }, [])

    const getRegionFilters = () => {
        const regionFilter = [];
        const regionDict = {}
        datasource.every((site) => {
            if (!regionDict[site.region]) {
                regionDict[site.region] = site;
                regionFilter.push({ text: site.region, value: site.region })
            }
            return true;
        })
        return regionFilter;
    }

    const getCategoryFilters = () => {
        const categoryFilter = [];
        if (datasource) {
            const categoryDict = {}
            datasource.every((site) => {
                if (site.infrigementCategoryId && categories && categories.length > 0) {
                    let result = categories.filter((category) => {
                        return category.id === site.infrigementCategoryId
                    })
                    if (!categoryDict[site.infrigementCategoryId] && result && result[0]) {
                        categoryDict[site.infrigementCategoryId] = site;
                        categoryFilter.push({ text: result[0].name, value: site.infrigementCategoryId })
                    }
                }
                return true;
            })
        }
        return categoryFilter;
    }

    const genItemsWithRecord = (record) => {
        const items = [
            {
                key: '1',
                label: <a>削除</a>,
                onClick: () => {
                    Modal.confirm({
                        title: "削除しますか？",
                        okText: "はい",
                        cancelText: "いいえ",
                        onOk: (close) => {
                            onRemoveClick(record)
                            close()
                        },
                        onCancel: (close) => {
                            close()
                        }
                    })
                }
            },
        ];
        return items
    }

    const createColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
                fixed: true,
                width: 80,
                sorter: (a, b) => compareNumber(a.id, b.id),
                showSorterTooltip: false,
            },
            {
                title: 'サイト名',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => compareString(a.name, b.name),
                showSorterTooltip: false,
                filterSearch: true,
                filters: datasource.map((item) => {
                    return { text: item.name, value: item.name }
                }),
                onFilter: (value, record) => record.name.includes(value),
            },
            {
                title: 'URL',
                dataIndex: 'url',
                key: 'url',
                sorter: (a, b) => compareString(a.url, b.url),
                showSorterTooltip: false,
            },
            {
                title: '国・地区',
                dataIndex: 'region',
                key: 'region',
                render: (text, record) => {
                    if (record.region) {
                        let country = CountryConfig.find(country => country.code === record.region)
                        if (country) {
                            return country.name.ja
                        } else {
                            return ""
                        }
                    }
                    return ""
                },
                sorter: (a, b) => compareString(a.region, b.region),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.region) {
                        return value === record.region
                    } else {
                        return value === 0
                    }
                },
                filters: getRegionFilters(),
                filterMultiple: true,
            },
            {
                title: '採用カテゴリ',
                dataIndex: 'infrigementCategoryId',
                key: 'infringementCategoryId',
                render: (text, record) => {
                    if (record.infrigementCategoryId) {
                        for (let category of categories) {
                            if (category.id === record.infrigementCategoryId) {
                                return category.name;
                            }
                        }
                    }
                    return ""
                },
                sorter: (a, b) => compareNumber(a.infrigementCategoryId, b.infrigementCategoryId),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.infrigementCategoryId) {
                        return value === record.infrigementCategoryId
                    } else {
                        return value === 0
                    }
                },
                filters: getCategoryFilters(),
                filterMultiple: true,
            },
            {
                title: 'スクレイピング可能',
                dataIndex: 'hasCrawler',
                key: 'hasCrawler',
                render: (text, record) => {
                    if (record.hasCrawler === true) {
                        return "可能"
                    } else {
                        return "不可能"
                    }
                },
                sorter: (a, b) => compareNumber(a.hasCrawler, b.hasCrawler),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.hasCrawler) {
                        return value && record.hasCrawler
                    } else {
                        return !value
                    }
                },
                filters: [
                    {
                        text: "可能",
                        value: 1,
                    },
                    {
                        text: "不可能",
                        value: 0,
                    },
                ],
                filterMultiple: true,
            },
            {
                title: "動作",
                dataIndex: "action",
                key: "action",
                fixed: "right",
                showSorterTooltip: false,
                render: (text, record) => (
                    <Space size="middle">
                        <Button type="link" onClick={() => {
                            onDetailClick(record)
                        }
                        }>
                            詳細
                        </Button>
                        <Dropdown arrow={true} menu={{ items: genItemsWithRecord(record) }}>
                            <a>
                                動作 <DownOutlined />
                            </a>
                        </Dropdown>
                    </Space>
                ),
            },

        ];
    }

    const onDetailClick = (record) => {
        props.history.push({
            pathname: "/analyst/ec-site/detail/" + record.id,
        })
    }

    const onRemoveClick = (record) => {
        reqRemoveECSite({ siteId: record.id }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setDatasource(data.data.sites)
            }
        }).catch((e) => {

        })
    }

    return (
        <div className="master-container">
            <div>
                <Card bordered={false}>
                    <Table
                        key="ec-site-list-table"
                        bordered
                        tableLayout="fixed"
                        rowKey={(record) => {
                            return record.id
                        }}
                        columns={createColumns()}
                        dataSource={datasource}
                        size="small"
                        pagination={{
                            showQuickJumper: true,
                            pageSizeOptions: [10, 20, 50, 100, 200, 500],
                            defaultPageSize: 20,
                            showSizeChanger: true,
                        }}
                        scroll={{
                            x: 'max-content',
                        }}
                    />
                </Card>
            </div>
        </div>
    )
}

export default AnalystECSiteList
