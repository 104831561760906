import "./index.less"

import store from "@/store";
import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select, DatePicker, message, Tabs, Checkbox } from "antd";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"

import { reqUpdateClient, reqRemoveClient, reqGetClient } from '../../../api/admin-client'
import { reqSearchUsers } from '../../../api/admin-user'

import EFMasterBrand from "../../analyst/ec-master/ec-master-brand"
import EFMasterProductCategory from "../../analyst/ec-master/ec-master-product-category"
import EFMasterCustomColumn from "../../analyst/ec-master/ec-master-custom-column"
import EFMasterSpecailURL from "../../analyst/ec-master/ec-master-special-url"
import EFMasterSpecailWord from "../../analyst/ec-master/ec-master-special-word"
import EFMasterAttorneyLetter from "../../analyst/ec-master/master-attorney-letter"
import EFMasterTrademarkCountry from "../../analyst/ec-master/master-trademark-country"
import EFMasterOtherSetting from "../../analyst/ec-master/ec-master-other"
import EFMasterECReport from "../../analyst/ec-master/ec-master-report"

import EFMasterWSReport from "../../analyst/ec-master/ws-master-report"

import { isValidTelNo } from "../../../utils/ef-re";

import moment from 'moment';
const { Option } = Select;

class ClientDetail extends Component {

    constructor(props) {
        super(props);
        this.clientId = parseInt(this.props.location.pathname.split("/")[3]);
        this.state = {
            clientInfo: {},
            analysts: [],
            isloading: false,
            isManagerForClient: false,
            isAdmin: this.props.role & 4 ? true : false,
        }
        this.basicFormRef = React.createRef();
        this.serviceFormRef = React.createRef();

        reqGetClient({
            "clientId": this.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                let clientInfo = data.data.client;
                this.updateUIByClientInfo(clientInfo);
            }
        }).catch((e) => {
        })

        reqSearchUsers({
            "role": 2
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                let analysts = data.data.users;
                this.setState({ analysts })
            }
        }).catch((e) => {
        })

    }

    componentDidMount() {
    }

    updateUIByClientInfo = (clientInfo) => {
        this.setState({ clientInfo });
        this.basicFormRef.current.setFieldsValue({
            name: clientInfo.client_name,
            tel: clientInfo.client_tel,
            address: clientInfo.client_address,
            remarks: clientInfo.client_remarks,
        });
        this.setupManagerOptionsDefaultValue(clientInfo.analystInfo)

        if (clientInfo.service_ec_start_date) {
            this.serviceFormRef.current.setFieldsValue({
                ec_start_date: moment(clientInfo.service_ec_start_date, "YYYY-MM-DD"),
            });
        }
        if (clientInfo.service_ec_end_date) {
            this.serviceFormRef.current.setFieldsValue({
                ec_end_date: moment(clientInfo.service_ec_end_date, "YYYY-MM-DD"),
            });
        }
        if (clientInfo.service_ec_fee) {
            this.serviceFormRef.current.setFieldsValue({
                ec_fee: clientInfo.service_ec_fee,
            });
        }

        if (clientInfo.service_ec_currency) {
            this.serviceFormRef.current.setFieldsValue({
                ec_currency: clientInfo.service_ec_currency,
            });
        }

        this.serviceFormRef.current.setFieldsValue({
            ec_mail: clientInfo.service_ec_remind_mail ? "1" : "0",
        });

        if (clientInfo.service_sns_start_date) {
            this.serviceFormRef.current.setFieldsValue({
                sns_start_date: moment(clientInfo.service_sns_start_date, "YYYY-MM-DD"),
            });
        }
        if (clientInfo.service_sns_end_date) {
            this.serviceFormRef.current.setFieldsValue({
                sns_end_date: moment(clientInfo.service_sns_end_date, "YYYY-MM-DD"),
            });
        }
        if (clientInfo.service_sns_fee) {
            this.serviceFormRef.current.setFieldsValue({
                sns_fee: clientInfo.service_sns_fee,
            });
        }

        if (clientInfo.service_sns_currency) {
            this.serviceFormRef.current.setFieldsValue({
                sns_currency: clientInfo.service_sns_currency,
            });
        }

        this.serviceFormRef.current.setFieldsValue({
            sns_mail: clientInfo.service_sns_remind_mail ? "1" : "0",
        });

        if (clientInfo.service_ws_start_date) {
            this.serviceFormRef.current.setFieldsValue({
                ws_start_date: moment(clientInfo.service_ws_start_date, "YYYY-MM-DD"),
            });
        }
        if (clientInfo.service_ws_end_date) {
            this.serviceFormRef.current.setFieldsValue({
                ws_end_date: moment(clientInfo.service_ws_end_date, "YYYY-MM-DD"),
            });
        }
        if (clientInfo.service_ws_fee) {
            this.serviceFormRef.current.setFieldsValue({
                ws_fee: clientInfo.service_ws_fee,
            });
        }

        if (clientInfo.service_ws_currency) {
            this.serviceFormRef.current.setFieldsValue({
                ws_currency: clientInfo.service_ws_currency,
            });
        }

        this.serviceFormRef.current.setFieldsValue({
            ws_mail: clientInfo.service_ws_remind_mail ? "1" : "0",
        });

        if (clientInfo.service_other_start_date) {
            this.serviceFormRef.current.setFieldsValue({
                other_start_date: moment(clientInfo.service_other_start_date, "YYYY-MM-DD"),
            });
        }
        if (clientInfo.service_other_end_date) {
            this.serviceFormRef.current.setFieldsValue({
                other_end_date: moment(clientInfo.service_other_end_date, "YYYY-MM-DD"),
            });
        }
        if (clientInfo.service_other_fee) {
            this.serviceFormRef.current.setFieldsValue({
                other_fee: clientInfo.service_other_fee,
            });
        }

        if (clientInfo.service_other_currency) {
            this.serviceFormRef.current.setFieldsValue({
                other_currency: clientInfo.service_other_currency,
            });
        }

        this.serviceFormRef.current.setFieldsValue({
            other_mail: clientInfo.service_other_remind_mail ? "1" : "0",
        });
    }

    setupManagerOptionsDefaultValue = (analystInfo) => {
        if (analystInfo) {
            let strManagerIds = analystInfo.map((analyst) => {
                return analyst.user_id.toString()
            })

            this.basicFormRef.current.setFieldsValue({
                managers: strManagerIds,
            })
            let user = analystInfo.find((analyst) => {
                return analyst.user_id === this.props.id
            })
            this.setState({ isManagerForClient: user ? true : false })
        }
    }

    removeClient = () => {
        reqRemoveClient({ clientId: this.clientId }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.props.history.push("/manage/client")
            }
        }).catch((e) => {

        })
    }

    checkService = (startDate, endDate, fee) => {
        if (startDate && endDate && fee) {
            return true;
        } else {
            return false;
        }
    }


    onServiceFormFinish = (values) => {
        const { ec_start_date, ec_end_date, ec_fee, ec_currency, ec_mail,
            sns_start_date, sns_end_date, sns_fee, sns_currency, sns_mail,
            ws_start_date, ws_end_date, ws_fee, ws_currency, ws_mail,
            other_start_date, other_end_date, other_fee, other_currency, other_mail } = values;
        let body = {
            clientId: this.clientId,
        }
        if (this.checkService(ec_start_date, ec_end_date, ec_fee)) {
            body["ec"] = {
                startDate: ec_start_date.format("YYYY-MM-DD"),
                endDate: ec_end_date.format("YYYY-MM-DD"),
                fee: parseFloat(ec_fee),
                currency: parseInt(ec_currency),
                remindMail: ec_mail && ec_mail[0] === "1" ? true : false,
            }
        }

        if (this.checkService(sns_start_date, sns_end_date, sns_fee)) {
            body["sns"] = {
                startDate: sns_start_date.format("YYYY-MM-DD"),
                endDate: sns_end_date.format("YYYY-MM-DD"),
                fee: parseFloat(sns_fee),
                currency: parseInt(sns_currency),
                remindMail: sns_mail && sns_mail[0] === "1" ? true : false,
            }
        }

        if (this.checkService(ws_start_date, ws_end_date, ws_fee)) {
            body["ws"] = {
                startDate: ws_start_date.format("YYYY-MM-DD"),
                endDate: ws_end_date.format("YYYY-MM-DD"),
                fee: parseFloat(ws_fee),
                currency: parseInt(ws_currency),
                remindMail: ws_mail && ws_mail[0] === "1" ? true : false,
            }
        }

        if (this.checkService(other_start_date, other_end_date, other_fee)) {
            body["other"] = {
                startDate: other_start_date.format("YYYY-MM-DD"),
                endDate: other_end_date.format("YYYY-MM-DD"),
                fee: parseFloat(other_fee),
                currency: parseInt(other_currency),
                remindMail: other_mail && other_mail[0] === "1" ? true : false,
            }
        }

        this.setState({ isloading: true })
        reqUpdateClient(body).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                let clientInfo = data.data.client;
                this.updateUIByClientInfo(clientInfo);
                message.success("クライアント情報を更新しました。")
            } else {
                message.error("クライアント情報の更新は失敗しました。")
            }
            this.setState({ isloading: false })
        }).catch((error) => {
            this.setState({ isloading: false })
            message.error("クライアント情報の更新は失敗しました。")
        })
    }

    // form
    onBasicFormFinish = (values) => {
        const { name, tel, address, managers, remarks } = values;


        if (tel && !isValidTelNo(tel)) {
            message.error("正しい電話番号を入力してください。")
            return
        }
        let body = {
            clientId: this.clientId,
            name,
            tel,
            address,
            remarks,
        }

        if (managers) {

            let intMangerIds = managers.map((managerId) => {
                return parseInt(managerId);
            })
            body.managerIds = intMangerIds;
        }
        this.setState({ isloading: true })
        reqUpdateClient(body).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                let clientInfo = data.data.client;
                this.updateUIByClientInfo(clientInfo);
                message.success("クライアント情報を更新しました。")
            } else {
                message.error("クライアント情報の更新は失敗しました。")
            }
            this.setState({ isloading: false })
        }).catch((error) => {
            this.setState({ isloading: false })
            message.error("クライアント情報の更新は失敗しました。")
        })
    };

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        this.setState({ error: errors[0] })
        message.error(errors[0])
    }

    // life circle
    componentDidMount = () => {
        store.dispatch(setMenuActiveKey("manage-client-list"));
        if (this.state.isAdmin) {
            store.dispatch(setContentContainerHeaderAction([{
                name: "削除",
                onClick: this.removeClient
            }]));
        } else {
            store.dispatch(setContentContainerHeaderAction([]));
        }

        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "ブランド登録一覧",
                path: "/manage/client",
            },
            {
                name: "詳細",
            }
        ]));
    }

    createCurrencySelect = () => {
        return (
            <Select disabled={!this.state.isAdmin} className="analyst-select">
                <Option key="USD" value={1}>USD</Option>
                <Option key="JPY" value={2}>JPY</Option>
                <Option key="CNY" value={3}>CNY</Option>
            </Select>
        )
    }

    render() {
        document.title = "クライアント詳細"
        return (
            <div className="client-detail-container">
                <Tabs>
                    <Tabs.TabPane tab="基本情報" key="11">
                        <Form
                            onFinish={this.onBasicFormFinish}
                            onFinishFailed={this.onFinishFailed}
                            ref={this.basicFormRef}
                        >
                            <div className="item-wrapper">
                                <div className="item-label-container">
                                    <p>ID</p>
                                </div>
                                <div className="content-container">
                                    <p>{this.clientId}</p>
                                </div>
                            </div>
                            <div className="item-wrapper">
                                <div className="item-label-container">
                                    <p>会社名（必須）</p>
                                </div>
                                <div className="content-container">
                                    <Form.Item noStyle={true} name="name" rules={[
                                        {
                                            type: "string",
                                            max: 30,
                                            required: true,
                                            message: "会社名を入力ください。",
                                        },
                                    ]}>
                                        <Input disabled={!this.state.isAdmin} className="input-text" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="item-wrapper">
                                <div className="item-label-container">
                                    <p>連絡先</p>
                                </div>
                                <div className="content-container">
                                    <Form.Item noStyle={true} name="tel" rules={[
                                        {
                                            type: "string",
                                            pattern: "",
                                            message: "正しい連絡先を入力ください。",
                                        },
                                    ]}>
                                        <Input disabled={!this.state.isAdmin} className="input-text" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="item-wrapper">
                                <div className="item-label-container">
                                    <p>住所</p>
                                </div>
                                <div className="content-container">
                                    <Form.Item noStyle={true} name="address" rules={[
                                        {
                                            type: "string",
                                            max: 100,
                                            message: "正しい住所を入力ください。",
                                        },
                                    ]}>
                                        <Input disabled={!this.state.isAdmin} className="input-text" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="item-wrapper">
                                <div className="item-label-container">
                                    <p>Online Protection 担当者</p>
                                </div>
                                <div className="content-container">
                                    <Form.Item noStyle={true} name="managers" >
                                        <Select disabled={!this.state.isAdmin} mode="multiple" showArrow={true} className="analyst-select">
                                            {
                                                this.state.analysts.map((analyst) => {
                                                    return <Option key={analyst.user_id.toString()} value={analyst.user_id.toString()}>{analyst.user_name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="item-wrapper">
                                <div className="item-label-container">
                                    <p>備考</p>
                                </div>
                                <div className="content-container">
                                    <Form.Item noStyle={true} name="remarks" rules={[
                                        {
                                            type: "string",
                                            max: 500,
                                            message: "正しいアナリストを入力ください。",
                                        },
                                    ]}>
                                        <Input.TextArea disabled={!this.state.isAdmin} className="remarks-text" />
                                    </Form.Item>
                                </div>
                                <br />
                            </div>
                            <Row>
                                <Col span={12}>
                                    <div className="acition-container">
                                        <Form.Item>
                                            <Button disabled={!this.state.isAdmin} htmlType="submit" type="primary" className="update-button" loading={this.state.isloading}>更新</Button>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Form>

                    </Tabs.TabPane>
                    <Tabs.TabPane tab="契約情報" key="12" forceRender>
                        <Form
                            onFinish={this.onServiceFormFinish}
                            onFinishFailed={this.onFinishFailed}
                            ref={this.serviceFormRef}
                        >
                            <div className="item-wrapper">
                                <Row className="date-row">
                                    <Col span={3}>
                                        EC Protection
                                    </Col>
                                    <Col span={2}>契約期間</Col>
                                    <Col span={12}>
                                        <div>
                                            <Form.Item noStyle={true} name="ec_start_date">
                                                <DatePicker disabled={!this.state.isAdmin} />
                                            </Form.Item>
                                            〜
                                            <Form.Item noStyle={true} name="ec_end_date">
                                                <DatePicker disabled={!this.state.isAdmin} />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3}>
                                    </Col>
                                    <Col span={2}>金額</Col>
                                    <Col span={3}>
                                        <Form.Item noStyle={true} name="ec_fee">
                                            <Input disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item noStyle={true} name="ec_currency">
                                            {this.createCurrencySelect()}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3}>
                                    </Col>
                                    <Col span={2}>リマインドメール</Col>
                                    <Col span={7}>
                                        <Form.Item noStyle={true} name="ec_mail">
                                            <Checkbox.Group options={[{ label: "失効日の30、60、90日前に通知", value: "1" }]} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </div>
                            <div className="item-wrapper">
                                <Row className="date-row">
                                    <Col span={3}>
                                        SNS Protection
                                    </Col>
                                    <Col span={2}>契約期間</Col>
                                    <Col span={12}>
                                        <Form.Item noStyle={true} name="sns_start_date">
                                            <DatePicker disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                        〜
                                        <Form.Item noStyle={true} name="sns_end_date">
                                            <DatePicker disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3}>
                                    </Col>
                                    <Col span={2}>金額</Col>
                                    <Col span={3}>
                                        <Form.Item noStyle={true} name="sns_fee">
                                            <Input disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item noStyle={true} name="sns_currency">
                                            {this.createCurrencySelect()}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3}>
                                    </Col>
                                    <Col span={2}>リマインドメール</Col>
                                    <Col span={7}>
                                        <Form.Item noStyle={true} name="sns_mail">
                                            <Checkbox.Group options={[{ label: "失効日の30、60、90日前に通知", value: "1" }]} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <div className="item-wrapper">
                                <Row className="date-row">
                                    <Col span={3}>
                                        WS Protection
                                    </Col>
                                    <Col span={2}>契約期間</Col>
                                    <Col span={12}>
                                        <Form.Item noStyle={true} name="ws_start_date">
                                            <DatePicker disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                        〜
                                        <Form.Item noStyle={true} name="ws_end_date">
                                            <DatePicker disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3}>
                                    </Col>
                                    <Col span={2}>金額</Col>
                                    <Col span={3}>
                                        <Form.Item noStyle={true} name="ws_fee">
                                            <Input disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item noStyle={true} name="ws_currency">
                                            {this.createCurrencySelect()}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3}>
                                    </Col>
                                    <Col span={2}>リマインドメール</Col>
                                    <Col span={7}>
                                        <Form.Item noStyle={true} name="ws_mail">
                                            <Checkbox.Group options={[{ label: "失効日の30、60、90日前に通知", value: "1" }]} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </div>
                            <div className="item-wrapper">
                                <Row className="date-row">
                                    <Col span={3}>
                                        Other Protection
                                    </Col>
                                    <Col span={2}>契約期間</Col>
                                    <Col span={12}>
                                        <Form.Item noStyle={true} name="other_start_date">
                                            <DatePicker disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                        〜
                                        <Form.Item noStyle={true} name="other_end_date">
                                            <DatePicker disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3}>
                                    </Col>
                                    <Col span={2}>金額</Col>
                                    <Col span={3}>
                                        <Form.Item noStyle={true} name="other_fee">
                                            <Input disabled={!this.state.isAdmin} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item noStyle={true} name="other_currency">
                                            {this.createCurrencySelect()}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={3}>
                                    </Col>
                                    <Col span={2}>リマインドメール</Col>
                                    <Col span={7}>
                                        <Form.Item noStyle={true} name="other_mail">
                                            <Checkbox.Group options={[{ label: "失効日の30、60、90日前に通知", value: "1" }]} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                            </div>
                            <Row>
                                <Col span={12}>
                                    <div className="acition-container">
                                        <Form.Item>
                                            <Button disabled={!this.state.isAdmin} htmlType="submit" type="primary" className="update-button" loading={this.state.isloading}>更新</Button>
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="データ設定" key="13" disabled={!this.state.isManagerForClient}>
                        <Tabs defaultActiveKey={this.defaultActiveKey} onChange={this.onTabsChange}>
                            <Tabs.TabPane tab="ECレポート" key="1">
                                <EFMasterECReport clientId={this.clientId} key="EFMasterECReport" />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="ECマスター情報" key="2">
                                <Tabs tabPosition="left">
                                    <Tabs.TabPane tab="ブランド" key="1">
                                        <EFMasterBrand clientId={this.clientId} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="製品区分" key="2">
                                        <EFMasterProductCategory clientId={this.clientId} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="追加項目" key="3">
                                        <EFMasterCustomColumn clientId={this.clientId} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="ブラックリスト" key="4">
                                        <EFMasterSpecailURL clientId={this.clientId} urlType={2} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="ホワイトリスト" key="5">
                                        <EFMasterSpecailURL clientId={this.clientId} urlType={1} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="互換表記" key="6">
                                        <EFMasterSpecailWord clientId={this.clientId} wordType={1} />
                                    </Tabs.TabPane>
                                    <Tabs.TabPane tab="純正表記" key="7">
                                        <EFMasterSpecailWord clientId={this.clientId} wordType={2} />
                                    </Tabs.TabPane>
                                </Tabs>

                            </Tabs.TabPane>
                            <Tabs.TabPane tab="WSレポート" key="3">
                                <EFMasterWSReport clientId={this.clientId} key="EFMasterWSReport" />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="委任状" key="4">
                                <EFMasterAttorneyLetter clientId={this.clientId} key="EFMasterAttorneyLetter" />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="商標登録国・地区" key="5">
                                <EFMasterTrademarkCountry clientId={this.clientId} key="7EFMasterTrademarkCountry" />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="その他" key="6">
                                <EFMasterOtherSetting clientId={this.clientId} key="EFMasterOtherSetting" />
                            </Tabs.TabPane>
                        </Tabs>


                    </Tabs.TabPane>
                </Tabs>

            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(ClientDetail)
