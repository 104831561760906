import "./index.less"
import React, { Component } from "react";
import { Form, Input } from "antd";

import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormText } from '@ant-design/pro-form';
import { reqCreateSpecialWord, reqFetchSpecialWords, reqRemoveSpecialWord, reqUpdateSpecialWord } from '../../../api/analyst-special-word'

class AnalystClientProductCategory extends Component {


    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
        if (this.props.clientId) {
            reqFetchSpecialWords({
                clientId: this.props.clientId,
                type: this.props.wordType
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ datesource: data.data.words });
                }

            }).catch((error) => {

            })

        }
    }

    createColumns = () => {
        return [
            {
                title: '番号',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '表記',
                dataIndex: 'word',
                key: 'word',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"word_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "表記を入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },
        ];

    }

    createModalItems = (record) => {
        return (
            <>
                <ProFormText width="sm" name="word" label="表記" rules={[
                    {
                        required: true,
                        message: '表記を入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    getEditFieldsValue = (record) => {
        let row = {}
        row["word_" + record.id] = record.word;
        return row
    }

    onRemoveClick = (record) => {
        reqRemoveSpecialWord({
            wordId: record.id,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.words });
            }
        }).catch((e) => {

        })
    }

    onUpdateClick = (record, values) => {
        reqUpdateSpecialWord({
            wordId: record.id,
            word: values["word_" + record.id],
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.words });
            }
        }).catch((e) => {

        })
    }

    onCreateClick = (values) => {
        reqCreateSpecialWord({
            word: values["word"],
            type: this.props.wordType,
            clientId: this.props.clientId,
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.words });
            }
        }).catch((e) => {

        })
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }

    render() {
        return (
            <div className="master-container">
                <EFCMasterTable
                    modalTitle={this.props.wordType === 1 ? "互換表記" : "純正表記"}
                    rowkey="id"
                    columns={this.createColumns()} 
                    dataSource={this.state.datesource}
                    getEditFieldsValue={this.getEditFieldsValue}
                    modalItems={this.createModalItems()}
                    onRemoveClick={this.onRemoveClick}
                    onSaveClick={this.onUpdateClick}
                    onModalFinished={this.onCreateClick}
                    tableProps={this.getTableProps()}
                />
            </div>
        )
    }
}

export default AnalystClientProductCategory