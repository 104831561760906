import "./index.less"
import React, { Component } from "react";
import { Form } from "antd";

import { reqFetchProductCategories, reqCreateProductCategory, reqRemoveProductCategory, reqUpdateProductCategory } from '../../../api/analyst-product-category'
import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormText } from '@ant-design/pro-form';

import { setProductCategoryAction } from "@/store/actions/ec-master.js"
import store from "@/store";

class AnalystClientProductCategory extends Component {

    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
        if (this.props.clientId) {
            reqFetchProductCategories({
                clientId: this.props.clientId
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ datesource: data.data.categories });
                    store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
                }
            }).catch((error) => {

            })

        }
        this.columns = this.createColumns()
    }

    createColumns = () => {
        return [
            {
                title: '番号',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '製品区分',
                dataIndex: 'name',
                key: 'name',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "製品区分を入力ください",
                                }
                            ]}>
                            <input></input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: '製品区分(英文)',
                dataIndex: 'name_en',
                key: 'name_en',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"name_en_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "製品区分(英文)を入力ください",
                                }
                            ]}>
                            <input></input>
                        </ Form.Item >
                    )
                }

            },

            {
                title: 'コード',
                dataIndex: 'code',
                key: 'code',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"code_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "コードを入力ください",
                                }
                            ]}>
                            <input></input>
                        </ Form.Item >
                    )
                }
            },
        ];

    }

    createModalItems = (record) => {
        return (
            <>
                <ProFormText width="sm" name="name" label="製品区分" rules={[
                    {
                        required: true,
                        message: '製品区分を入力ください。',
                    },
                ]}
                />
                <ProFormText width="sm" name="name_en" label="製品区分(英文)" rules={[
                    {
                        required: true,
                        message: '製品区分(英文)を入力ください。',
                    },
                ]}
                />
                <ProFormText width="sm" name="code" label="コード" rules={[
                    {
                        required: true,
                        message: 'コードを入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    getEditFieldsValue = (record) => {
        let row = {}
        row["name_" + record.id] = record.name;
        row["name_en_" + record.id] = record.name_en;
        row["code_" + record.id] = record.code;
        return row
    }

    onRemoveClick = (record) => {
        reqRemoveProductCategory({
            categoryId: record.id,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.categories });
                store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
            }
        }).catch((e) => {

        })
    }

    onUpdateClick = (record, values) => {
        reqUpdateProductCategory({
            categoryId: record.id,
            name: values["name_" + record.id],
            nameEn: values["name_en_" + record.id],
            code: values["code_" + record.id],
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.categories });
                store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
            }
        }).catch((e) => {

        })
    }

    onCreateClick = (values) => {
        reqCreateProductCategory({
            name: values["name"],
            nameEn: values["name_en"],
            code: values["code"],
            clientId: this.props.clientId,
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.categories });
                store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
            }
        }).catch((e) => {

        })
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }
    
    render() {
        return (
            <div className="master-container">
                <EFCMasterTable
                    modalTitle="製品区分を追加"
                    rowkey="id"
                    columns={this.createColumns()}
                    dataSource={this.state.datesource}
                    getEditFieldsValue={this.getEditFieldsValue}
                    modalItems={this.createModalItems()}
                    onRemoveClick={this.onRemoveClick}
                    onSaveClick={this.onUpdateClick}
                    onModalFinished={this.onCreateClick}
                    tableProps={this.getTableProps()}
                />
            </div>
        )
    }
}

export default AnalystClientProductCategory