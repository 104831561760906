import * as types from "../action-types";
import enUS from 'antd/es/locale/en_US';
import jaJP from 'antd/es/locale/ja_JP';
import enMessages from '@/locales/en'
import jaMessages from '@/locales/ja'

import { getCookieLocale, setCookieLocale } from "@/utils/cookie";


const getDefaultLocale = () => {
    let browserLang = navigator.language
    let cookieLang = getCookieLocale()
    if (browserLang.toLowerCase() === "ja" || cookieLang === "ja") {
        return {
            antdLocale: jaJP,
            locale: "ja",
            messages: jaMessages
        }
    } else {
        return {
            antdLocale: enUS,
            locale: "en",
            messages: enMessages
        }
    }
}

const updateCookieLocale = (locale) => {
    if (locale) {
        setCookieLocale(locale)
    }
}

const initLocale = {
    intl: getDefaultLocale()
};

const getIntlInfoByLocale = (locale) => {
    switch (locale) {
        case "ja":
            return {
                antdLocale: jaJP,
                locale: "ja",
                messages: jaMessages
            }
        case "en":
            return {
                antdLocale: enUS,
                locale: "en",
                messages: enMessages
            }
        default: {
            return {
                antdLocale: enUS,
                locale: "en",
                messages: enMessages
            }
        }
    }
}

export default function settings(state = initLocale, action) {
    switch (action.type) {
        case types.LOCALE_KEY: {
            let newState = Object.assign({}, state)
            let localeInfo = getIntlInfoByLocale(action.locale)
            newState.intl = localeInfo;
            updateCookieLocale(localeInfo.locale)
            return newState;
        }
        case types.USER_SET_USER_INFO: {
            if (action.settings) {
                if (action.settings.locale) {
                    let newState = Object.assign({}, state)
                    let localeInfo = getIntlInfoByLocale(action.settings.locale)
                    newState.intl = localeInfo;
                    updateCookieLocale(localeInfo.locale)
                    return newState;
                } else if (action.settings.ec_columns) {
                    let newState = Object.assign({}, state)
                    newState.ec_display_columns = action.settings.ec_columns;
                    return newState;
                } else {
                    return state
                }
            } else {
                return state
            }
        }
        case types.DEFALUT_DISPLAY_EC_COLUMNS: {
            let newState = Object.assign({}, state)
            newState.ec_display_columns = action.columns;
            return newState;
        }

        default:
            return state;
    }
}