import { PicLeftOutlined, MessageOutlined, LineChartOutlined, GlobalOutlined, TrademarkOutlined, BarChartOutlined, SearchOutlined, SettingOutlined, HomeOutlined, FileSearchOutlined, ExperimentOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

const menuList = [
    {
        id: "home",
        title: <FormattedMessage id="app.layout.menu.home" />,
        path: "/home",
        roles: 7,
        re: /^\/home/,
        icon: <HomeOutlined />,
    },
    {
        id: "online-services",
        title: <FormattedMessage id="app.layout.menu.online_protection" />,
        path: "/online-dashboard",
        re: /^\/online-dashboard/,
        roles: 2,
        icon: <GlobalOutlined />,
        children: [
            {
                id: "analyst-ec-list",
                title: <FormattedMessage id="app.layout.menu.online_protection.ec_record_list" />,
                path: "/ec-list",
                roles: 2,
                re: /^\/ec-list/

            },
            {
                id: "analyst-sns-list",
                title: <FormattedMessage id="app.layout.menu.online_protection.sns_record_list" />,
                path: "/sns-list",
                roles: 2,
                re: /^\/sns-list/
            },

            {
                id: "analyst-ws-list",
                title: <FormattedMessage id="app.layout.menu.online_protection.ws_record_list" />,
                path: "/ws-list",
                roles: 2,
                re: /^\/ws-list/
            },
        ]
    },
    {
        id: "analyst-offline-services",
        title: "Offilne Protection",
        path: "/analyst-offline-services",
        roles: 6,
        re: /^\/analyst-offline-services/,
        icon: <SearchOutlined />,
    },
    {
        id: "client-online-services",
        title: "Online Protection",
        path: "/online-dashboard",
        re: /^\/online-dashboard/,
        roles: 1,
        icon: <BarChartOutlined />,
        children: [
            {
                id: "client-ec-list",
                title: <FormattedMessage id="app.layout.menu.online_protection.ec_record_list" />,
                path: "/ec-list",
                roles: 1,
                re: /^\/ec-list/,
            },
            {
                id: "client-sns-list",
                title: <FormattedMessage id="app.layout.menu.online_protection.sns_record_list" />,
                path: "/sns-list",
                roles: 1,
                re: /^\/sns-list/
            },

            {
                id: "client-ws-list",
                title: <FormattedMessage id="app.layout.menu.online_protection.ws_record_list" />,
                path: "/ws-list",
                roles: 1,
                re: /^\/ws-list/
            },
        ]
    },
    {
        id: "client-offline-services",
        title: "Offline Protection",
        path: "/client-offline-services",
        re: /^\/client-offline-services/,
        roles: 1,
        icon: <SearchOutlined />,
    },

    {
        id: "legal-services",
        title: <FormattedMessage id="app.layout.menu.legal_services" />,
        roles: 7,
        icon: <TrademarkOutlined />,
        children: [
            {
                id: "search-trademark",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_search" />,
                path: "/search-trademark",
                roles: 7, // 1+2
                re: /^\/search-trademark/,
            },
            {
                id: "trademark-registration",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_register" />,
                path: "/trademark/registration",
                roles: 1, // 1+2
                re: /^\/trademark-registration/,
            },
            {
                id: "request-trademark-progress",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_application_status" />,
                path: "/request-trademark-progress",
                roles: 7, // 1+2
                re: /^\/request-trademark-progress/,
            },
            {
                id: "update-trademark",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_update_status" />,
                path: "/update-trademark",
                roles: 7, // 1+2
                re: /^\/update-trademark/,
            },
            {
                id: "trademark-reject-report",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_rejection_reason" />,
                path: "/trademark-reject-report",
                roles: 7, // 1+2
                re: /^\/trademark-reject-report/,
            },
            {
                id: "trademark-change",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_transfer" />,
                path: "/trademark-change",
                roles: 7, // 1+2
                re: /^\/trademark-change/,
            },
            {
                id: "trademark-protect",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_monitoring" />,
                path: "/trademark-protect",
                roles: 7, // 1+2
                re: /^\/trademark-protect/,
            },
            {
                id: "trademark-failed-reason",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_correction_invaildation_trail" />,
                path: "/trademark-failed-reason",
                roles: 7, // 1+2
                re: /^\/trademark-failed-reason/,
            },
            {
                id: "case-management",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_case_management" />,
                path: "/case-management",
                roles: 7, // 1+2
                re: /^\/case-management/,
            },
            {
                id: "trademark",
                title: <FormattedMessage id="app.layout.menu.legal_services.tm_registration" />,
                path: "/trademark",
                roles: 7, // 1+2
                re: /^\/trademark/,
            },
        ]
    },
    {
        id: "biz-support",
        title: <FormattedMessage id="app.layout.menu.biz_supprot" />,
        path: "/biz-support",
        re: /^\/biz-support/,
        roles: 7,
        icon: <LineChartOutlined />,
    },

    {
        id: "other-support",
        title: <FormattedMessage id="app.layout.menu.other_support" />,
        roles: 6,
        icon: <ExperimentOutlined />,
        children: [
            {
                id: "test-purchase",
                title: <FormattedMessage id="app.layout.menu.test_purfchase" />,
                path: "/test-purchase",
                re: /^\/test-purchase/,
                roles: 6, // 1+2
            },
        ]
    },
    {
        id: "news",
        title: <FormattedMessage id="app.layout.menu.news" />,
        path: "/news",
        re: /^\/news/,
        roles: 7,
        icon: <PicLeftOutlined />,
    },
    {
        id: "notice",
        title: <FormattedMessage id="app.layout.menu.notice" />,
        roles: 7,
        path: "/notice",
        re: /^\/notice/,
        icon: <MessageOutlined />,
    },
    {
        id: "task",
        title: <FormattedMessage id="app.layout.menu.task" />,
        roles: 4,
        icon: <FileSearchOutlined />,
        children: [
            {
                id: "task-ec",
                title: <FormattedMessage id="app.layout.menu.task.ec_crawler_task" />,
                path: "/task/ec-crawler-task",
                roles: 4, // 1+2
                re: /^\/ec-crawler-task/,
            },
        ]
    },
    {
        id: "setting",
        title: <FormattedMessage id="app.layout.menu.setting" />,
        roles: 7,
        icon: <SettingOutlined />,
        children: [
            {
                id: "profile",
                title: <FormattedMessage id="app.layout.menu.setting.profile" />,
                path: "/profile",
                roles: 7, // 1+2
                re: /^\/profile/,
            },
            {
                id: "data-setting",
                title: <FormattedMessage id="app.layout.menu.setting.data_setting" />,
                path: "/data-setting",
                roles: 1, // 1+2
                re: /^\/data-setting/,
            },
            {
                id: "manage-user-list",
                title: <FormattedMessage id="app.layout.menu.setting.user_list" />,
                path: "/manage/user",
                roles: 4,
                re: /^\/manage\/user/
            },
            {
                id: "manage-client-list",
                title: <FormattedMessage id="app.layout.menu.setting.brand_list" />,
                path: "/manage/client",
                roles: 6,
                re: /^\/manage\/client/
            },
            {
                id: "manage-analyst-list",
                title: <FormattedMessage id="app.layout.menu.setting.manager_list" />,
                path: "/manage/analyst",
                roles: 4,
                re: /^\/manage\/analyst/
            },
            {
                id: "analyst-ec-site",
                title: <FormattedMessage id="app.layout.menu.setting.ec_list" />,
                path: "/analyst/ec-site",
                roles: 2,
                re: /^\/analyst\/ec-site/
            },
            {
                id: "analyst-infringement-category",
                title: <FormattedMessage id="app.layout.menu.setting.infringement_category" />,
                path: "/analyst/infringement-category",
                roles: 2,
                re: /^\/analyst\/infringement-category/
            },
        ]
    },
]

export default menuList;
