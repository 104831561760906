import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Layout from "@/views/layout";
import LoginView from "@/views/login/login";
import SignUpView from "@/views/login/sign-up";
import ForgetPassView from "@/views/login/forget-pass";
import { getUserInfo } from "@/store/actions/user"


class Router extends React.Component {
  render() {
    const { token, role, getUserInfo, userid } = this.props;
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={LoginView} />
          <Route exact path="/sign-up" component={SignUpView} />
          <Route exact path="/forget-pass" component={ForgetPassView} />
          <Route
            path="/"
            render={() => {
              if (!token) {
                return <Redirect to="/login" />;
              } else {
                if (role) {
                  return <Layout />;
                } else {
                  getUserInfo(userid)
                    .then(() => {
                      return <Layout />;
                    })
                    .catch((error) => {
                      return <Redirect to="/login" />;
                    });
                }
              }
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.auth,
    ...state.user,
  };
};

export default connect(mapStateToProps, { getUserInfo })(Router);
