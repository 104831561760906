import "./index.less"

import store from "@/store";
import React, { Component } from "react";
import { Tabs } from "antd";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";

import EFMasterOtherSetting from "../../analyst/ec-master/ec-master-other"



class DataSettingDetail extends Component {

    // life circle
    componentDidMount = () => {
        store.dispatch(setMenuActiveKey("data-setting"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name:  <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.layout.menu.setting.data_setting" />,
                path: "/data-settings",
            }
        ]));
    }



    render() {
        document.title = this.props.intl.formatMessage(messages["title"])
        return (
            <div className="client-detail-container">
                <Tabs  >
                    <Tabs.TabPane tab={this.props.intl.formatMessage(messages["other_setting_tab"])} key="6">
                        <div>
                            <EFMasterOtherSetting clientId={this.props.companyInfo ? this.props.companyInfo.client_id : null} key="EFMasterOtherSetting" />
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    console.log(state)
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(injectIntl(DataSettingDetail))
