import React, { Component } from "react";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import store from "@/store";
import { Result } from 'antd';
import { FormattedMessage } from 'react-intl'

class Page404 extends Component {


    componentDidMount() {
        // store.dispatch(setMenuActiveKey("analyst-online-dashboard"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "404",
            },
        ]));
    }

    render() {
        document.title = "404"
        return (
            <Result
                status="404"
                title={
                    <>
                        <FormattedMessage id="app.ui.404.description1" />
                        <br />
                        <FormattedMessage id="app.ui.404.description2" />
                    </>}
                subTitle=""
                extra={
                    <p>
                        <FormattedMessage id="app.ui.404.contact"/> ipf@ip-fw.com
                    </p>
                }
            />
        )
    }
}

export default Page404