import "./index.less"
import React, { Component } from "react";
import { Form, Input } from "antd";

import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormText } from '@ant-design/pro-form';
import { reqCreateSpecialUrl, reqFetchSpecialUrls, reqRemoveSpecialUrl, reqUpdateSpecialUrl } from '../../../api/analyst-special-url'

class AnalystClientProductCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
        if (this.props.clientId) {
            reqFetchSpecialUrls({
                clientId: this.props.clientId,
                type: this.props.urlType
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ datesource: data.data.urls });
                }

            }).catch((error) => {

            })

        }
    }

    createColumns = () => {
        return [
            {
                title: '番号',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'URL',
                dataIndex: 'url',
                key: 'url',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"url_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "URLを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },

            {
                title: '出品者名',
                dataIndex: 'seller',
                key: 'seller',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"seller_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "出品者名を入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },
            {
                title: '出品者ID',
                dataIndex: 'sellerId',
                key: 'sellerId',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"sellerId_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "出品者IDを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '企業名',
                dataIndex: 'company',
                key: 'company',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"company_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "企業名を入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '備考',
                dataIndex: 'remarks',
                key: 'remarks',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"remarks_" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "備考を入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }

            },

        ];

    }

    createModalItems = (record) => {
        return (
            <>
                <ProFormText width="sm" name="url" label="URL" rules={[
                    {
                        required: false,
                        message: 'URLを入力ください。',
                    },
                ]}
                />
                <ProFormText width="sm" name="seller" label="出品者名" rules={[
                    {
                        required: false,
                        message: '出品者名を入力ください。',
                    },
                ]}
                />
                <ProFormText width="sm" name="sellerId" label="出品者ID" rules={[
                    {
                        required: false,
                        message: '出品者IDを入力ください。',
                    },
                ]}
                />
                <ProFormText width="sm" name="company" label="企業名" rules={[
                    {
                        required: false,
                        message: '企業名を入力ください。',
                    },
                ]}
                />
                <ProFormText width="sm" name="remarks" label="備考" rules={[
                    {
                        required: false,
                        message: '備考を入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    getEditFieldsValue = (record) => {
        let row = {}
        row["url_" + record.id] = record.url;
        row["seller_" + record.id] = record.seller;
        row["sellerId_" + record.id] = record.sellerId;
        row["company_" + record.id] = record.company;
        row["remarks_" + record.id] = record.remarks;
        return row
    }

    onRemoveClick = (record) => {
        reqRemoveSpecialUrl({
            urlId: record.id,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.urls });
            }
        }).catch((e) => {

        })
    }


    onUpdateClick = (record, values) => {
        reqUpdateSpecialUrl({
            urlId: record.id,
            url: values["url_" + record.id],
            seller: values["seller_" + record.id],
            sellerId: values["sellerId_" + record.id],
            company: values["company_" + record.id],
            remarks: values["remarks_" + record.id],
            type: this.props.urlType,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.urls });
            }
        }).catch((e) => {

        })
    }

    onCreateClick = (values) => {
        reqCreateSpecialUrl({
            url: values["url"],
            seller: values["seller"],
            sellerId: values["sellerId"],
            company: values["company"],
            remarks: values["remarks"],
            type: this.props.urlType,
            clientId: this.props.clientId,
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.urls });
            }
        }).catch((e) => {

        })
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }

    render() {
        return (
            <div className="master-container">
                <EFCMasterTable
                    modalTitle={this.props.urlType === 1 ? "ブラックリスト" : "ホワイトリスト"}
                    rowkey="id"
                    columns={this.createColumns()} 
                    dataSource={this.state.datesource}
                    getEditFieldsValue={this.getEditFieldsValue}
                    modalItems={this.createModalItems()}
                    onRemoveClick={this.onRemoveClick}
                    onSaveClick={this.onUpdateClick}
                    onModalFinished={this.onCreateClick}
                    tableProps={this.getTableProps()}
                />
            </div>
        )
    }
}

export default AnalystClientProductCategory