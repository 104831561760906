import { Button, Input, Select, Row, Col, DatePicker, Form, message } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormSelect, ProFormText,ProFormTextArea } from "@ant-design/pro-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { reqCreateECCrawlerSubtask, reqGetECCrawlerTask, reqRemoveECCrawlerSubtask, reqUpdateECCrawlerTask, reqUpdateECCrawlerSubtask } from "../../../api/analyst-ec-task.js";
import { reqFetchClients } from '@/api/analyst-client';
import { reqFetchECSites } from "../../../api/analyst-site.js";
import { ECCrawlerPurpose, ECCrawlerStatus } from '../common/master-data.js'
import "./index.less"
import moment from 'moment';


function EFECScrapyingTaskDetailView(props) {
    const formRef = useRef()
    const [subtasks, setSubtasks] = useState([])

    // 
    useEffect(() => {
        document.title = "詳細ECスクレイピング"
        store.dispatch(setMenuActiveKey("task-ec"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: "ECスクレイピング",
                path: "/task/ec-crawler-task",
            },
            {
                name: "詳細",
                // path: "/ec-list",
            },
        ]));
    }, [])

    const [task, setTask] = useState()
    useEffect(() => {
        const taskId = props.match.params.id
        reqGetECCrawlerTask({
            taskId: taskId
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                // console.log(data.data.task)
                updateState(data.data.task)
            }
        })
    }, [])

    const [sites, setSites] = useState([])
    useEffect(() => {
        reqFetchECSites().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setSites(data.data.sites.filter((site) => {
                    return site.hasCrawler
                }));
            }
        }).catch(e => {

        })
    }, [])

    const [clients, setClients] = useState([])
    useEffect(() => {
        reqFetchClients({all:true}).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setClients(data.data.client);
            }
        }).catch(e => {

        })
    }, [])

    const createSubtaskColumns = () => {
        return [
            {
                title: 'サイト',
                dataIndex: 'site',
                key: 'site',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"site" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "サイト名を入力ください",
                                }
                            ]}>
                            <Select style={{ width: 200 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}
                            >
                                {sites.map((site) => {
                                    return <Select.Option value={site.name} key={site.url} >{site.name}</Select.Option>
                                })}
                            </Select>
                        </ Form.Item >
                    )
                }
            },
            {
                title: 'キーワード',
                dataIndex: 'keyword',
                key: 'keyword',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"keyword" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "キーワードを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '開始URL',
                dataIndex: 'startUrl',
                key: 'startUrl',
                width: 500,
                renderText: ( record) => {
                    return <div className="task-start-url">{record.startUrl}</div>
                },

                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"startUrl" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "開始URLを入力ください",
                                }
                            ]}>
                            <Input.TextArea></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '希望件数',
                dataIndex: 'targetCount',
                key: 'targetCount',
                width: 100,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"targetCount" + record.id}
                            rules={[
                                {
                                    required: false,
                                    message: "希望件数を入力ください",
                                    type: "number"
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
        ];
    }

    const onUpdateTask = (values) => {
        const params = {}
        // console.log(values)
        // console.log(task)

        if (values["planStartDate"] && task.planStartDate !== values["planStartDate"]) {
            params["planStartDate"] = values["planStartDate"]
        }
        if (values["clientId"] && task.clientId !== values["clientId"]) {
            params["clientId"] = values["clientId"]
        }
        if (values["title"] && task.title !== values["title"]) {
            params["title"] = values["title"]
        }
        if (values["type"] && task.type !== values["type"]) {
            params["type"] = values["type"]
        }
        if (values["remarks"] && task.remarks !== values["remarks"]) {
            params["remarks"] = values["remarks"]
        }
        if (Object.keys(params).length > 0) {
            reqUpdateECCrawlerTask({ ...params, taskId: task.id }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success("成功しました。")
                    updateState(data.data.task)
                }
                // props.history.push("/task/ec-crawler-task")
            }).catch((e) => {
                message.error("失敗しました。")
            })
        }
    }

    const updateState = (task) => {
        formRef.current.setFieldsValue({
            clientId: task.clientId,
            title: task.title,
            type: task.type,
            remarks: task.remarks,
            planStartDate: moment(task.planStartDate)
        })
        setTask(task)
        setSubtasks(task.subtasks)

    }

    const onUpdateTaskFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        // this.setState({ error: errors[0] })
    }

    const onRemoveSubtaskClick = (record) => {
        if (record) {
            reqRemoveECCrawlerSubtask({
                subtaskId: record.id
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success("成功しました。")
                    setTask(data.data.task)
                    setSubtasks(data.data.task.subtasks)
                }
            }).catch(e => {
                // console.log(e)
            })
        }
    }

    const onUpdateSubtaskClick = (record, values) => {
        if (task.status !== ECCrawlerStatus.new.value) {
            return
        }
        const detail = {}
        if (record.site !== values["site" + record.id]) {
            detail["site"] = values["site" + record.id]
        }
        if (record.startUrl !== values["startUrl" + record.id]) {
            detail["startUrl"] = values["startUrl" + record.id]
        }
        if (record.targetCount !== values["targetCount" + record.id]) {
            detail["targetCount"] = values["targetCount" + record.id]
        }
        if (record.keyword !== values["targetCount" + record.id]) {
            detail["keyword"] = values["keyword" + record.id]
        }
        if (Object.keys(detail).length > 0) {
            reqUpdateECCrawlerSubtask({
                taskId: task.id,
                subtaskId: record.id,
                ...detail
            }).then((response) => {
                const { data } = response
                if (data.code === 0) {
                    message.success("成功しました。")
                    updateState(data.data.task)
                }
            })
        }
    }

    const onCreateSubtaskClick = (values) => {
        if (task.status !== ECCrawlerStatus.new.value) {
            return
        }

        reqCreateECCrawlerSubtask({
            detail: {
                ...values
            },
            taskId: task.id
        }).then((response) => {
            const { data } = response
            if (data.code === 0) {
                message.success("成功しました。")
                updateState(data.data.task)
            }
        }).catch(e => {
            // console.log(e)
        })
    }

    const createdModalItems = () => {
        return (
            <>
                <ProFormSelect width="100%" name="site" label="サイト" showSearch rules={[
                    {
                        required: true,
                        message: 'サイトを入力ください。',
                    },
                ]}
                    options={
                        sites.map(site => {
                            return {
                                value: site.name,
                                label: site.name,
                                key: site.name
                            }
                        })
                    }
                />
                <ProFormText width="100%" name="keyword" label="キーワード" rules={[
                    {
                        required: true,
                        message: 'キーワードを入力ください。',
                    },
                ]}
                />
                <ProFormTextArea width="100%" name="startUrl" label="開始URL" rules={[
                    {
                        required: true,
                        type: "url",
                        message: '開始URLを入力ください。',
                    },
                ]}
                />
                <ProFormText width="100%" name="targetCount" label="希望件数" rules={[
                    {
                        required: false,
                        message: '希望件数を入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    const getEditSubtaskFieldsValue = (record, index) => {
        const editInfo = {}
        editInfo["site" + record.id] = record["site"]
        editInfo["keyword" + record.id] = record["keyword"]
        editInfo["startUrl" + record.id] = record["startUrl"]
        editInfo["targetCount" + record.id] = record["targetCount"]
        return editInfo
    }

    return (
        <Form
            onFinish={onUpdateTask}
            onFinishFailed={onUpdateTaskFailed}
            ref={formRef}
        >
            <div className="new-ec-crawler-container">
                <Row justify="start" className="row">
                    <Col span={4}>開始日</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="planStartDate" rules={[
                            {
                                required: true,
                                message: "開始日を入力ください。",
                            },
                        ]}>
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="start" className="row">
                    <Col span={4}>クライアント</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="clientId" rules={[
                            {
                                required: true,
                                // message: this.props.intl.formatMessage(messages["message.error.no_client"]),
                            },
                        ]}>
                            <Select style={{ width: 260 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                {clients.map(client => {
                                    return (
                                        <Select.Option key={client.client_name} value={client.client_id}>
                                            {client.client_name}
                                        </Select.Option>
                                    )
                                })}

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>タイトル</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="title" rules={[
                            {
                                required: true,
                                // message: this.props.intl.formatMessage(messages["message.error.no_client"]),
                            },
                        ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>タイプ</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="type" default="" rules={[
                            {
                                required: true,
                                // message: this.props.intl.formatMessage(messages["message.error.no_client"]),
                            },
                        ]}>
                            <Select style={{ width: 260 }}>
                                <Select.Option value={ECCrawlerPurpose.normal.value} text="scrapying">{ECCrawlerPurpose.normal.text}</Select.Option>
                                <Select.Option value={ECCrawlerPurpose.dashboard.value} text="Dashboard">{ECCrawlerPurpose.dashboard.text}</Select.Option>
                                <Select.Option value={ECCrawlerPurpose.monitor.value} text="価格監視">{ECCrawlerPurpose.monitor.text}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>備考</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="remarks" default="" rules={[
                            {
                                max: 1000
                                // message: this.props.intl.formatMessage(messages["message.error.no_client"]),
                            },
                        ]}>
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={24}>
                        <EFCMasterTable
                            modalTitle="URL追加"
                            rowkey="id"
                            columns={createSubtaskColumns()}
                            dataSource={subtasks}
                            // tableProps={getBrandTableProps()}
                            getEditFieldsValue={getEditSubtaskFieldsValue}
                            modalItems={createdModalItems()}
                            onRemoveClick={onRemoveSubtaskClick}
                            onSaveClick={onUpdateSubtaskClick}
                            onModalFinished={onCreateSubtaskClick}
                            noAction={task && task.status !== ECCrawlerStatus.new.value}
                        >
                        </EFCMasterTable>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={24}><Button htmlType="submit">更新</Button></Col>
                </Row>
            </div>
        </Form>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
    };
};

export default connect(mapStateToProps)(injectIntl(EFECScrapyingTaskDetailView));



