import "./index.less"
import React, { Component } from "react";
import EFCSearchBar from "../../../components/ef-c-searchbar";
import { Table, Space, Button } from "antd";
import { reqSearchClients, reqRemoveClient } from '../../../api/admin-client'
import store from "@/store";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { getCurrencyName } from "@/utils/currency.js"
import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import moment from 'moment';
import { connect } from "react-redux";


class ClientList extends Component {

    constructor(props) {
        super(props);
        this.conditions = {}
        this.state = {
            dateSource: [],
            page: 1,
            count: 0,
            analystFilters: [],
        };
        this.dateSearchItems = [

            {
                name: "EC開始期間",
                value: "ec_startDate"
            },
            {
                name: "EC終了期間",
                value: "ec_endDate"
            },
            {
                name: "SNS開始期間",
                value: "sns_startDate"
            },
            {
                name: "SNS終了期間",
                value: "sns_endDate"
            },
            {
                name: "WS開始期間",
                value: "ws_startDate"
            },
            {
                name: "WS終了期間",
                value: "ws_endDate"
            },
            {
                name: "Other開始期間",
                value: "other_startDate"
            },
            {
                name: "Other終了期間",
                value: "other_endDate"
            },
        ];
        this.keywordSearchItems = [
            {
                name: "会社名",
                value: "client_name"
            },
            {
                name: "住所",
                value: "client_address"
            },
            {
                name: "備考",
                value: "client_remarks"
            },
        ];
        this.searchClients()
    }

    getColumns = () => {
        return [
            {
                title: 'ID',
                dataIndex: 'client_id',
                key: 'client_id',
                fixed: true,
                width: 80,
                sorter: (a, b) => compareNumber(a.client_id, b.client_id),
                showSorterTooltip: false,
            },
            {
                title: '会社名',
                dataIndex: 'client_name',
                key: 'client_name',
                sorter: (a, b) => compareString(a.client_name, b.client_name),
                showSorterTooltip: false,
            },
            {
                title: '連絡先',
                dataIndex: 'client_tel',
                key: 'client_tel',
                sorter: (a, b) => compareString(a.client_tel, b.client_tel),
                showSorterTooltip: false,
            },
            {
                title: '住所',
                dataIndex: 'client_address',
                key: 'client_address',
                sorter: (a, b) => compareString(a.client_address, b.client_address),
                showSorterTooltip: false,
            },
            {
                title: 'アナリスト',
                dataIndex: 'client_analyst',
                key: 'client_analyst',
                render: (text, record) => {
                    if (record.analystInfo) {
                        let analysts = record.analystInfo.map((analyst) => {
                            return analyst.user_name
                        })
                        return analysts.join("、")
                    }
                    return ""
                },
                onFilter: (value, record) => {
                    if (record.analystInfo) {
                        return record.analystInfo.some((analyst) => {
                            return analyst.user_id === value
                        })
                    } else {
                        return value === 0;
                    }
                },
                filters: [...this.state.analystFilters],
                filterMultiple: true,
                sorter: (a, b) => {
                    if (a.analystInfo) {
                        let a_analysts = a.analystInfo.map((analyst) => {
                            return analyst.user_name
                        })
                        let a_analysts_string = a_analysts.join("、")
                        if (b.analystInfo) {
                            let b_analysts = b.analystInfo.map((analyst) => {
                                return analyst.user_name
                            })
                            let b_analysts_string = b_analysts.join("、")
                            return compareString(a_analysts_string, b_analysts_string)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.analystInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },

            {
                title: '備考',
                dataIndex: 'client_remarks',
                key: 'client_remarks',
                width: 400,
                sorter: (a, b) => compareString(a.client_remarks, b.client_remarks),
                showSorterTooltip: false,
            },
            {
                title: 'EC開始期間',
                dataIndex: 'ec_startDate',
                key: 'ec_startDate',
                render: (text, record) => {
                    if (record.serviceInfo) {
                        if (record.serviceInfo.service_ec_start_date) {
                            return moment(record.serviceInfo.service_ec_start_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareDate(a.serviceInfo.service_ec_start_date, b.serviceInfo.service_ec_start_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }

                },
                showSorterTooltip: false,
            },
            {
                title: 'EC終了期間',
                dataIndex: 'ec_endDate',
                key: 'ec_endDate',
                render: (text, record) => {
                    if (record.serviceInfo) {
                        if (record.serviceInfo.service_ec_end_date) {
                            return moment(record.serviceInfo.service_ec_end_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareDate(a.serviceInfo.service_ec_end_date, b.serviceInfo.service_ec_end_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'EC金額',
                dataIndex: 'ec_fee',
                key: 'ec_fee',
                render: (text, record) => {
                    if (record.serviceInfo && record.serviceInfo.service_ec_fee) {
                        return record.serviceInfo.service_ec_fee + getCurrencyName(record.serviceInfo.service_ec_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareNumber(a.serviceInfo.service_ec_fee, b.serviceInfo.service_ec_fee)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'SNS開始期間',
                dataIndex: 'sns_startDate',
                key: 'sns_startDate',
                render: (text, record) => {
                    if (record.serviceInfo) {
                        if (record.serviceInfo.service_sns_start_date) {
                            return moment(record.serviceInfo.service_sns_start_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareDate(a.serviceInfo.service_sns_start_date, b.serviceInfo.service_sns_start_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'SNS終了期間',
                dataIndex: 'sns_endDate',
                key: 'sns_endDate',
                render: (text, record) => {
                    if (record.serviceInfo) {
                        if (record.serviceInfo.service_sns_end_date) {
                            return moment(record.serviceInfo.service_sns_end_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareDate(a.serviceInfo.service_sns_end_date, b.serviceInfo.service_sns_end_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'SNS金額',
                dataIndex: 'sns_fee',
                key: 'sns_fee',
                render: (text, record) => {
                    if (record.serviceInfo && record.serviceInfo.service_sns_fee) {
                        return record.serviceInfo.service_sns_fee + getCurrencyName(record.serviceInfo.service_sns_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareNumber(a.serviceInfo.service_sns_fee, b.serviceInfo.service_sns_fee)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'WS開始期間',
                dataIndex: 'ws_startDate',
                key: 'ws_startDate',
                render: (text, record) => {
                    if (record.serviceInfo) {
                        if (record.serviceInfo.service_ws_start_date) {
                            return moment(record.serviceInfo.service_ws_start_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareDate(a.serviceInfo.service_ws_start_date, b.serviceInfo.service_ws_start_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'WS終了期間',
                dataIndex: 'ws_endDate',
                key: 'ws_endDate',
                render: (text, record) => {
                    if (record.serviceInfo) {
                        if (record.serviceInfo.service_ws_end_date) {
                            return moment(record.serviceInfo.service_ws_end_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareDate(a.serviceInfo.service_ws_end_date, b.serviceInfo.service_ws_end_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'WS金額',
                dataIndex: 'ws_fee',
                key: 'ws_fee',
                render: (text, record) => {
                    if (record.serviceInfo && record.serviceInfo.service_ws_fee) {
                        return record.serviceInfo.service_ws_fee + getCurrencyName(record.serviceInfo.service_ws_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareNumber(a.serviceInfo.service_ws_fee, b.serviceInfo.service_ws_fee)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'Other開始期間',
                dataIndex: 'other_startDate',
                key: 'other_startDate',
                render: (text, record) => {
                    if (record.serviceInfo) {
                        if (record.serviceInfo.service_other_start_date) {
                            return moment(record.serviceInfo.service_other_start_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareDate(a.serviceInfo.service_other_start_date, b.serviceInfo.service_other_start_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'Other終了期間',
                dataIndex: 'other_endDate',
                key: 'other_endDate',
                render: (text, record) => {
                    if (record.serviceInfo) {
                        if (record.serviceInfo.service_other_end_date) {
                            return moment(record.serviceInfo.service_other_end_date).format("YYYY-MM-DD")
                        }
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareDate(a.serviceInfo.service_other_end_date, b.serviceInfo.service_other_end_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: 'Other金額',
                dataIndex: 'other_fee',
                key: 'other_fee',
                render: (text, record) => {
                    if (record.serviceInfo && record.serviceInfo.service_other_fee) {
                        return record.serviceInfo.service_other_fee + getCurrencyName(record.serviceInfo.service_other_currency)
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.serviceInfo) {
                        if (b.serviceInfo) {
                            return compareNumber(a.serviceInfo.service_other_fee, b.serviceInfo.service_other_fee)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.serviceInfo) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: '動作',
                key: 'action',
                render: (text, record) => {
                    if (this.props.role & 4) {
                        return (
                            <Space size="middle" >
                                <Button type="link" href={"/manage/client/" + record.client_id}>詳細</Button>
                                <Button type="link" onClick={() => { this.handleDelete(record) }} client={record.client_id}>削除</Button>
                            </Space>
                        )
                    } else {
                        return (
                            <Space size="middle" >
                                <Button type="link" href={"/manage/client/" + record.client_id}>詳細</Button>
                            </Space>
                        )
                    }
                }
                ,
                fixed: "right",
            },
        ];

    }

    componentDidMount() {
        store.dispatch(setMenuActiveKey("manage-client-list"));
        if (this.props.role & 4) {
            store.dispatch(setContentContainerHeaderAction([{
                name: "新規",
                path: "/manage/client/new",
            }]));
        } else {
            store.dispatch(setContentContainerHeaderAction([]));
        }
        
        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "ブランド登録一覧",
                path: "/manage/client",
            },
        ]));
    }

    searchClients = (conditions = {}) => {
        this.conditions = conditions;
        reqSearchClients({ ...conditions }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                let analystIds = {}
                let analystFilters = [];
                let dateSource = data.data.client.map((client) => {
                    let clientInfo = { key: client.client_id, ...client }
                    if (clientInfo.analystInfo) {
                        clientInfo.analystInfo.every((analyst) => {
                            if (!analystIds[analyst.user_id]) {
                                analystIds[analyst.user_id] = analyst
                                analystFilters.push({ text: analyst.user_name, value: analyst.user_id })
                            }
                            return true
                        })
                    }
                    return clientInfo;
                })
                analystFilters.push({ text: "空白", value: 0 })
                this.setState({ dateSource, analystFilters });
            }
        }).catch((error) => {

        })
    }

    handleSearch = (conditions) => {
        this.searchClients(conditions)
    };

    handleReset = () => {

    };

    handleDelete = (record) => {
        reqRemoveClient({
            clientId: record.client_id
        }).then((response) => {
            this.searchClients(this.conditions)
        }).catch((e) => {

        })
    };

    handleDetail = () => {

    };

    render() {
        document.title = "クブランド登録一覧";
        return (
            <div className="client-list-container">
                <div>
                    <EFCSearchBar onSearch={this.handleSearch} onReset={this.handleReset} dateItems={this.dateSearchItems} keywordItems={this.keywordSearchItems} />
                </div>
                <div className="result-table">
                    <Table
                        columns={this.getColumns()}
                        dataSource={this.state.dateSource}
                        size="small"
                        scroll={{
                            x: 'max-content',
                        }}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(ClientList)