import { defineMessages } from 'react-intl'

const messages = defineMessages({
    'title':{
        id: "app.layout.menu.setting.data_setting",
        defaultMessage: "Data Setting",

    },
    'other_setting_tab':{
        id: "app.ui.data_setting.other_setting.tag_title",
        defaultMessage: "Data Setting",

    },

})

export default messages
