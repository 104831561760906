import "./index.less"
import React, { Component } from "react";
import { Button, Upload, Table, Checkbox, Space, message, Popconfirm ,Col,Row} from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { reqUploadECReport, reqFetchReports, reqRemoveECReport, reqInheritPreECReport, reqDeleteRepeatRecords, reqPostReport, reqBatchUpdateECReport } from '../../../api/ec'
import ConvertWorker from "../../../worker/covertECExceltoJson.worker.js";
import { reqFetchCustomColumns } from '../../../api/analyst-custom-column'
import { reqSearchTrademarks } from '../../../api/trademark'
import { reqFetchInfringementCategories } from '../../../api/analyst-infringement-category'
import { reqFetchProductCategories } from '../../../api/analyst-product-category'
import { reqFetchECSites } from '../../../api/analyst-site';
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setCustomColumnAction, setProductCategoryAction } from "@/store/actions/ec-master.js"
import store from "@/store";

const FormData = require('form-data');

class AnalystClientECReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [],
            createUploadFileList: [],
            updateUploadFileList: []
        }
        this.sendUncategorizRecords = false;
    }

    componentDidMount = () => {
        if (this.props.clientId) {
            reqFetchReports({ clientId: this.props.clientId }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ reports: data.data.reports });
                }
            }).catch((e) => {

            })
        }
        this.covertWorker = new ConvertWorker();
        // sitesInfo, productsInfo, categorysInfo, trademarksInfo,customItemsInfo
        if (this.props.clientId) {
            reqFetchCustomColumns({
                clientId: this.props.clientId
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    // this.setState({ customItemsInfo: data.data.columns });
                    store.dispatch(setCustomColumnAction(this.props.clientId, data.data.columns));
                }
            }).catch((error) => {

            })
        }

        reqSearchTrademarks({ clientId: this.props.clientId }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ trademarksInfo: data.data.trademarks });
            }
        }).catch((error) => {

        })

        reqFetchInfringementCategories().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ categorysInfo: data.data.categories });
            }
        }).catch((error) => {

        })

        reqFetchProductCategories({
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                // this.setState({ productsInfo: data.data.categories });
                store.dispatch(setProductCategoryAction(this.props.clientId, data.data.categories));
            }
        }).catch((error) => {

        })

        reqFetchECSites().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ sitesInfo: data.data.sites });
            }
        }).catch((error) => {

        })
    }

    componentWillUnmount = () => {
        if (this.covertWorker) {
            this.covertWorker.terminate()
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (props.ecmaster) {
            if (props.ecmaster[props.clientId]) {
                current_state.productsInfo = props.ecmaster[props.clientId].productCategoryInfo;
                current_state.customItemsInfo = props.ecmaster[props.clientId].customColumnInfo;
            }
        }
        return props
    }

    onDetail = (record) => {
        // let effectFilters = effectFilterKeys.map((key) => {
        //     let result = {}
        //     result.key = key;
        //     result.values = filters[key];
        //     return result;
        // })
        // if (effectFilters && effectFilters.length > 0) {
        //     
        //     searchParams.set("filters", encodeURIComponent(JSON.stringify(effectFilters)))
        // } else {
        //     searchParams.set("filters", null)
        // }

        let result = {}
        result.key = "report_id";
        result.values = [record.report_id];
        this.props.history.push(`/ec-list?clientId=${this.props.clientId}&filters=${encodeURIComponent(JSON.stringify([result]))}`)
    }

    onDeleteReport = (record) => {
        reqRemoveECReport({ clientId: this.props.clientId, reportId: record.report_id }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ reports: data.data.reports });
            }
            message.success(record.report_name + "を削除しました。")
        }).catch((e) => {
            message.error("削除は失敗しました。")
        })
    }

    onDownloadReport = (record) => {
        window.open(`${process.env.REACT_APP_BASE_API}/api/ec-report/download-report?clientId=${this.props.clientId}&reportId=${record.report_id}`);
        // reqDownloadECReport({ clientId: this.props.clientId, reportId: record.report_id }).then((response) => {
        //     const { data } = response;
        //     if (data.code === 0) {

        //     }
        // }).catch((e) => {
        //     message.error("ダウンロードは失敗しました。")
        // })
    }

    onInheritReport = (record) => {
        reqInheritPreECReport({ clientId: this.props.clientId, reportId: record.report_id }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ reports: data.data.reports });
                message.success(record.report_name + "の引続は完了しました。")
            }
        }).catch((e) => {
            message.error("引続は失敗しました。")
        })
    }

    onDeleteRepeatReport = (record) => {
        reqDeleteRepeatRecords({ clientId: this.props.clientId, reportId: record.report_id }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ reports: data.data.reports });
                message.success(record.report_name + "の重複削除は完了しました。")

            }
        }).catch((e) => {
            message.error("重複削除は失敗しました。")
        })
    }

    onPostReport = (record) => {
        // sendUncategorizRecords
        reqPostReport({ clientId: this.props.clientId, reportId: record.report_id, sendUncategorizRecords: this.sendUncategorizRecords ? true : false }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ reports: data.data.reports });
                message.success(record.report_name + "の公開は完了しました。")
            }
        }).catch((e) => {
            message.error("公開は失敗しました。")
        })
    }


    createColumns = () => {
        return [
            {
                title: '番号',
                dataIndex: 'report_id',
                key: 'report_id',
                fixed: "left",
                width: 80,
            },
            {
                title: 'レポート名',
                dataIndex: 'report_name',
                key: 'report_name',
            },

            {
                title: 'アップロード日',
                dataIndex: 'report_upload_date',
                key: 'report_upload_date',
                render: (value, record, index) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: 'Scrape総数',
                dataIndex: 'report_scrape_count',
                key: 'report_scrape_count',
            },
            {
                title: '重複数',
                dataIndex: 'report_repeat_count',
                key: 'report_repeat_count',
            },
            {
                title: '公開日',
                dataIndex: 'report_send_date',
                key: 'report_send_date',
                render: (value, record, index) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: 'カテゴライズ数',
                dataIndex: "report_uncategrize_count",
                key: "report_uncategrize_count",
                render: (value, record, index) => {
                    return record.report_scrape_count - value;
                },
            },
            {
                title: '未カテゴライズ数',
                dataIndex: "report_uncategrize_count",
                key: "report_uncategrize_count"
            },
            {
                title: '回答日',
                dataIndex: 'report_response_date',
                key: 'report_response_date',
                render: (value, record, index) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: '回答数',
                dataIndex: 'report_response_count',
                key: 'report_response_count',
            },

            {
                title: '動作',
                key: 'action',
                fixed: "right",
                render: (text, record, index) => (
                    <Space size="middle" key="actions">
                        <Button key="detail" type="link" onClick={() => { this.onDetail(record) }}>詳細</Button>

                        <Popconfirm
                            key="delete"
                            title="削除しますか？"
                            onConfirm={() => { this.onDeleteReport(record) }}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <Button key="delete" type="link" >削除</Button>
                        </Popconfirm>
                        <Button type="link" onClick={() => { this.onDownloadReport(record) }}>ダウンロード</Button>
                        <Popconfirm
                            key="inherit"
                            title="引継しますか？"
                            onConfirm={() => { this.onInheritReport(record) }}
                            okText="はい"
                            cancelText="いいえ"
                        >

                            <Button key="inherit" type="link">引継</Button>
                        </Popconfirm>
                        <Popconfirm
                            key="removeDuplicate"
                            title="重複のレコードを削除しますか？"
                            onConfirm={() => { this.onDeleteRepeatReport(record) }}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <Button key="removeDuplicate" type="link">重複削除</Button>
                        </Popconfirm>
                        <Popconfirm
                            key="send"
                            title={<Checkbox onClick={(value) => this.sendUncategorizRecords = value}>カテゴライズなしのレコードをクライアントに表示します。</Checkbox>}
                            onConfirm={() => { this.onPostReport(record) }}
                            onCancel={() => this.sendUncategorizRecords = false}
                            okText="公開する"
                            cancelText="やめる"
                        >
                            <Button key="send" type="link">公開</Button>
                        </Popconfirm>

                    </Space>
                ),
            },
        ];
    }

    onBatchUpdateUpload = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {

        this.covertWorker.postMessage({
            records: file,
            sitesInfo: this.state.sitesInfo,
            productsInfo: this.state.productsInfo,
            categorysInfo: this.state.categorysInfo,
            trademarksInfo: this.state.trademarksInfo,
            customItemsInfo: this.state.customItemsInfo,
            isUpdate: true
        });
        this.covertWorker.onmessage = (event) => {
            // if (event.data.errorMsg) {
            //     onError(event.data.errorMsg)
            //     message.error(event.data.errorMsg);
            //     return
            // }
            if (event.data.errors && event.data.errors.length > 0) {
                const errorNodes = event.data.errors.map((error) => {
                    return <Row><Col>{error.errorMsg}</Col></Row>
                })
                onError(errorNodes)
                message.error(errorNodes, 20);
            }

            const formData = new FormData();
            formData.append('clientId', this.props.clientId);
            formData.append('report', event.data.records);

            reqBatchUpdateECReport(
                formData,
                (total, loaded) => {
                    onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
                }).then((response) => {
                    const { data } = response;
                    if (data.code === 0) {
                        onSuccess(response, file);
                        this.setState({ reports: data.data.reports });
                    } else {
                        onError(data.message)
                    }
                }).catch((error) => {
                    onError(error)
                })
        }
    }

    onUploadECRecord = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {
        this.covertWorker.postMessage({
            records: file,
            sitesInfo: this.state.sitesInfo,
            productsInfo: this.state.productsInfo,
            categorysInfo: this.state.categorysInfo,
            trademarksInfo: this.state.trademarksInfo,
            customItemsInfo: this.state.customItemsInfo
        });
        this.covertWorker.onmessage = (event) => {
            // if (event.data.errorMsg) {
            //     onError(event.data.errorMsg)
            //     message.error(event.data.errorMsg);
            //     return
            // }

            // console.log(event.data.errors)
            if (event.data.errors && event.data.errors.length > 0) {
                const errorNodes = event.data.errors.map((error) => {
                    return <Row><Col>{error.errorMsg}</Col></Row>
                })
                onError(errorNodes)
                message.error(errorNodes, 20);
            } else {
                const formData = new FormData();
                formData.append('clientId', this.props.clientId);
                formData.append('report', event.data.records);

                reqUploadECReport(
                    formData,
                    (total, loaded) => {
                        onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
                    }).then((response) => {
                        const { data } = response;
                        if (data.code === 0) {
                            onSuccess(response, file);
                            this.setState({ reports: data.data.reports });
                        } else {
                            onError(data.message)
                        }
                    }).catch((error) => {
                        onError(error)
                    })
            }
        }
    }

    handleCreateFileListChange = (info) => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {

            file.response = file.error;
            return file;
        });
        this.setState({ createUploadFileList: fileList });
    }

    handleUpdateFileListChange = (info) => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {

            file.response = file.error;
            return file;
        });
        this.setState({ updateUploadFileList: fileList });
    }


    render() {
        let isUploadable = !!this.state.sitesInfo && !!this.state.productsInfo && !!this.state.categorysInfo && !!this.state.trademarksInfo && !!this.state.customItemsInfo;

        return (
            <div className="master-container">
                <div>
                    <div className="report-upload-wrapper">
                        <div className="upload-wrapper">
                            <Upload
                                accept=".xls,.xlsx"
                                maxCount={1}
                                listType="picture"
                                customRequest={this.onUploadECRecord}
                                disabled={!isUploadable}
                                onChange={this.handleCreateFileListChange}
                                fileList={this.state.createUploadFileList}
                            >
                                <Button icon={<UploadOutlined />}>新規アップロード</Button>
                            </Upload>
                        </div>
                        <div className="upload-wrapper">
                            <Upload
                                accept=".xls,.xlsx"
                                maxCount={1}
                                listType="picture"
                                customRequest={this.onBatchUpdateUpload}
                                disabled={!isUploadable}
                                onChange={this.handleUpdateFileListChange}
                                fileList={this.state.updateUploadFileList}
                            >
                                <Button icon={<UploadOutlined />}>更新アップロード</Button>
                            </Upload>
                        </div>
                    </div>
                </div>
                <Table
                    key="table"
                    columns={this.createColumns()}
                    dataSource={this.state.reports}
                    scroll={{
                        x: "max-content"
                    }}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        ...state.user,
        ecmaster: state.ecMasterData
    };
};

export default connect(mapStateToProps)(withRouter(AnalystClientECReport));
