import "./index.less"
import React, { Component } from "react";
import { Button, Upload, Table, Checkbox, Space, message, Popconfirm } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { reqUploadWSReport, reqFetchReports, reqRemoveWSReport, reqPostReport, reqBatchUpdateWSReport } from '../../../api/ws'
import ConvertWorker from "../../../worker/covertECExceltoJson.worker.js";
import { reqSearchTrademarks } from '../../../api/trademark'
import moment from 'moment';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import store from "@/store";

const FormData = require('form-data');

class AnalystClientWSReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reports: [],
            createUploadFileList: [],
            updateUploadFileList: []
        }
        this.sendUncategorizRecords = false;
    }

    componentDidMount = () => {
        if (this.props.clientId) {
            reqFetchReports({ clientId: this.props.clientId }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ reports: data.data.reports });
                }
            }).catch((e) => {

            })
        }
        this.covertWorker = new ConvertWorker();
        reqSearchTrademarks({ clientId: this.props.clientId }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ trademarksInfo: data.data.trademarks });
            }
        }).catch((error) => {

        })
    }

    componentWillUnmount = () => {
        if (this.covertWorker) {
            this.covertWorker.terminate()
        }
    }

    onDetail = (record) => {
        // let effectFilters = effectFilterKeys.map((key) => {
        //     let result = {}
        //     result.key = key;
        //     result.values = filters[key];
        //     return result;
        // })
        // if (effectFilters && effectFilters.length > 0) {
        //     
        //     searchParams.set("filters", encodeURIComponent(JSON.stringify(effectFilters)))
        // } else {
        //     searchParams.set("filters", null)
        // }

        let result = {}
        result.key = "report_id";
        result.values = [record.report_id];
        this.props.history.push(`/ec-list?clientId=${this.props.clientId}&filters=${encodeURIComponent(JSON.stringify([result]))}`)
    }

    onDeleteReport = (record) => {
        reqRemoveWSReport({ clientId: this.props.clientId, reportId: record.id }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ reports: data.data.reports });
            }
            message.success(record.reportName + "を削除しました。")
        }).catch((e) => {
            message.error("削除は失敗しました。")
        })
    }

    onDownloadReport = (record) => {
        window.open(`${process.env.REACT_APP_BASE_API}/api/ws-report/download-report?clientId=${this.props.clientId}&reportId=${record.id}`);
    }

    onPostReport = (record) => {
        // sendUncategorizRecords
        reqPostReport({ clientId: this.props.clientId, reportId: record.id, sendUncategorizRecords: this.sendUncategorizRecords ? true : false }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ reports: data.data.reports });
                message.success(record.reportName + "の公開は完了しました。")
            }
        }).catch((e) => {
            message.error("公開は失敗しました。")
        })
    }

    createColumns = () => {
        return [
            {
                title: '番号',
                dataIndex: 'id',
                key: 'id',
                fixed: "left",
                width: 80,
            },
            {
                title: 'レポート名',
                dataIndex: 'reportName',
                key: 'reportName',
            },

            {
                title: 'アップロード日',
                dataIndex: 'uploadDate',
                key: 'uploadDate',
                render: (value, record, index) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: '総数',
                dataIndex: 'recordCount',
                key: 'recordCount',
            },
            {
                title: '公開日',
                dataIndex: 'sendDate',
                key: 'sendDate',
                render: (value, record, index) => {
                    if (value) {
                        return moment(value).format("YYYY-MM-DD")
                    }
                    return ""
                },
            },
            {
                title: 'カテゴライズ数',
                dataIndex: "categrizeCount",
                key: "categrizeCount",
                
            },
            {
                title: '未カテゴライズ数',
                dataIndex: "uncategrizeCount",
                key: "uncategrizeCount"
            },
            {
                title: '回答数',
                dataIndex: 'responseCount',
                key: 'responseCount',
            },

            {
                title: '動作',
                key: 'action',
                fixed: "right",
                render: (text, record, index) => (
                    <Space size="middle" key="actions">
                        <Button key="detail" type="link" onClick={() => { this.onDetail(record) }}>詳細</Button>

                        <Popconfirm
                            key="delete"
                            title="削除しますか？"
                            onConfirm={() => { this.onDeleteReport(record) }}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <Button key="delete" type="link" >削除</Button>
                        </Popconfirm>
                        <Button type="link" onClick={() => { this.onDownloadReport(record) }}>ダウンロード</Button>
                        {/* <Popconfirm
                            key="inherit"
                            title="引継しますか？"
                            onConfirm={() => { this.onInheritReport(record) }}
                            okText="はい"
                            cancelText="いいえ"
                        >

                            <Button key="inherit" type="link">引継</Button>
                        </Popconfirm> */}
                        {/* <Popconfirm
                            key="removeDuplicate"
                            title="重複のレコードを削除しますか？"
                            onConfirm={() => { this.onDeleteRepeatReport(record) }}
                            okText="はい"
                            cancelText="いいえ"
                        >
                            <Button key="removeDuplicate" type="link">重複削除</Button>
                        </Popconfirm> */}
                        <Popconfirm
                            key="send"
                            title={<Checkbox onClick={(value) => this.sendUncategorizRecords = value}>カテゴライズなしのレコードをクライアントに表示します。</Checkbox>}
                            onConfirm={() => { this.onPostReport(record) }}
                            onCancel={() => this.sendUncategorizRecords = false}
                            okText="公開する"
                            cancelText="やめる"
                        >
                            <Button key="send" type="link">公開</Button>
                        </Popconfirm>

                    </Space>
                ),
            },
        ];
    }

    onBatchUpdateUpload = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {

            const formData = new FormData();
            formData.append('clientId', this.props.clientId);
            formData.append('report', file);

            reqBatchUpdateWSReport(
                formData,
                (total, loaded) => {
                    onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
                }).then((response) => {
                    const { data } = response;
                    if (data.code === 0) {
                        onSuccess(response, file);
                        this.setState({ reports: data.data.reports });
                    } else {
                        onError(data.message)
                    }
                }).catch((error) => {
                    onError(error)
                })

        // this.covertWorker.postMessage({
        //     records: file,
        //     sitesInfo: this.state.sitesInfo,
        //     productsInfo: this.state.productsInfo,
        //     categorysInfo: this.state.categorysInfo,
        //     trademarksInfo: this.state.trademarksInfo,
        //     customItemsInfo: this.state.customItemsInfo,
        //     isUpdate: true
        // });
        // this.covertWorker.onmessage = (event) => {
        //     if (event.data.errorMsg) {
        //         onError(event.data.errorMsg)
        //         message.error(event.data.errorMsg);
        //         return
        //     }

        //     const formData = new FormData();
        //     formData.append('clientId', this.props.clientId);
        //     formData.append('report', event.data.records);

        //     reqBatchUpdateWSReport(
        //         formData,
        //         (total, loaded) => {
        //             onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
        //         }).then((response) => {
        //             const { data } = response;
        //             if (data.code === 0) {
        //                 onSuccess(response, file);
        //                 this.setState({ reports: data.data.reports });
        //             } else {
        //                 onError(data.message)
        //             }
        //         }).catch((error) => {
        //             onError(error)
        //         })
        // }
    }

    onUploadECRecord = (
        {
            file,
            filename,
            onError,
            onProgress,
            onSuccess,
        }) => {

            const formData = new FormData();
            formData.append('clientId', this.props.clientId);
            formData.append('report', file);

            reqUploadWSReport(
                formData,
                (total, loaded) => {
                    onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
                }).then((response) => {
                    const { data } = response;
                    if (data.code === 0) {
                        onSuccess(response, file);
                        this.setState({ reports: data.data.reports });
                    } else {
                        onError(data.message)
                    }
                }).catch((error) => {
                    onError(error)
                })

        // this.covertWorker.postMessage({
        //     records: file,
        //     sitesInfo: this.state.sitesInfo,
        //     productsInfo: this.state.productsInfo,
        //     categorysInfo: this.state.categorysInfo,
        //     trademarksInfo: this.state.trademarksInfo,
        //     customItemsInfo: this.state.customItemsInfo
        // });
        // this.covertWorker.onmessage = (event) => {
        //     if (event.data.errorMsg) {
        //         onError(event.data.errorMsg)
        //         message.error(event.data.errorMsg);
        //         return
        //     }

        //     const formData = new FormData();
        //     formData.append('clientId', this.props.clientId);
        //     formData.append('report', event.data.records);

        //     reqUploadWSReport(
        //         formData,
        //         (total, loaded) => {
        //             onProgress({ percent: Math.round((loaded / total) * 100).toFixed(2) }, file);
        //         }).then((response) => {
        //             const { data } = response;
        //             if (data.code === 0) {
        //                 onSuccess(response, file);
        //                 this.setState({ reports: data.data.reports });
        //             } else {
        //                 onError(data.message)
        //             }
        //         }).catch((error) => {
        //             onError(error)
        //         })
        // }
    }

    handleCreateFileListChange = (info) => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {

            file.response = file.error;
            return file;
        });
        this.setState({ createUploadFileList: fileList });
    }

    handleUpdateFileListChange = (info) => {
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);

        // 2. Read from response and show file link
        fileList = fileList.map(file => {

            file.response = file.error;
            return file;
        });
        this.setState({ updateUploadFileList: fileList });
    }


    render() {
        let isUploadable =  !!this.state.trademarksInfo ;

        return (
            <div className="master-container">
                <div>
                    <div className="report-upload-wrapper">
                        <div className="upload-wrapper">
                            <Upload
                                accept=".xls,.xlsx"
                                maxCount={1}
                                listType="picture"
                                customRequest={this.onUploadECRecord}
                                disabled={!isUploadable}
                                onChange={this.handleCreateFileListChange}
                                fileList={this.state.createUploadFileList}
                            >
                                <Button icon={<UploadOutlined />}>新規アップロード</Button>
                            </Upload>
                        </div>
                        {/* <div className="upload-wrapper">
                            <Upload
                                accept=".xls,.xlsx"
                                maxCount={1}
                                listType="picture"
                                customRequest={this.onBatchUpdateUpload}
                                disabled={!isUploadable}
                                onChange={this.handleUpdateFileListChange}
                                fileList={this.state.updateUploadFileList}
                            >
                                <Button icon={<UploadOutlined />}>更新アップロード</Button>
                            </Upload>
                        </div> */}
                    </div>
                </div>
                <Table
                    key="table"
                    columns={this.createColumns()}
                    dataSource={this.state.reports}
                    scroll={{
                        x: "max-content"
                    }}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        ...state.user,
        ecmaster: state.ecMasterData
    };
};

export default connect(mapStateToProps)(withRouter(AnalystClientWSReport));
