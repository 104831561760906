import ec_crawler_task_ja_lang from './ec-crawler-task/ja.js'

const common_ja_lang = {
    // infrigment common categories name key
    'app.config.infirgment_common_cateogry.name_locale_key': 'ja',

    // locale name key
    'app.config.locale.name_locale_key': 'ja',

    // product type
    'app.config.locale.product_locale_key': "name",

    // infringement
    'app.config.locale.infringement_locale_key': "name",

    // subinfringement
    'app.config.locale.subinfringement_locale_key': "name",

    // header
    'app.layout.header.language': '日本語',
    'app.layout.header.logout': 'ログアウト',

    // sider menu
    'app.layout.menu.home': 'ホーム',

    // common label
    'app.common.label.blank': '空白',
    'app.common.label.no': 'なし',
    'app.common.label.yes': 'あり',

    'app.common.flag.yes': 'はい',
    'app.common.flag.no': 'いいえ',

    // common column name
    'app.common.action': '動作',
    'app.common.action.detail': '詳細',
    'app.common.action.delete': '削除',
    'app.common.action.add': '新規',
    'app.common.action.update': '更新',
    'app.common.action.back': '戻る',
    'app.common.action.download': 'ダウンロード',
    'app.common.action.upload': "アップロード",


    // 404
    'app.ui.404.description1': 'こちらのページは年間サービス会員様専用ページとなります。',
    'app.ui.404.description2': '御覧になるには、弊所より発行いたします専用アカウントとパスワードが必要です。',
    'app.ui.404.contact': '連絡先：',

    // efc tableheader
    'app.components.ec_table_header.total_count': '総件数:{count}',
    'app.components.ec_table_header.error_count': '異常件数:{count}',
    'app.components.ec_table_header.update': '更新',

    // efc searchbar
    'app.components.ef_search_bar.date_item': '日付項目',
    'app.components.ef_search_bar.date_from': '開始日',
    'app.components.ef_search_bar.date_to': '終了日',
    'app.components.ef_search_bar.search_item': '検索項目',
    'app.components.ef_search_bar.search_hint': '検索',
    'app.components.ef_search_bar.search_button': '検索',
    'app.components.ef_search_bar.reset_button': 'リセット',
    'app.components.ef_search_bar.search_tpye_fuzzy_match': '曖昧検索',
    'app.components.ef_search_bar.search_tpye_exactly_match': '完全一致',
    'app.components.ef_search_bar.search_tpye_previous_match': '前一致',
    'app.components.ef_search_bar.search_tpye_post_match': '後一致',

    // efc searchbar
    'app.components.ef_code_button.title': '取得する',
    'app.components.ef_code_button.count_down': '後{countDown}秒、再取得できます。',

    // data filter
    'app.components.data_filter.check_all': '全部',
    'app.components.data_filter.cancel': '取消し',
    'app.components.data_filter.submit': '確認',

    'app.layout.menu.online_protection': 'Online Protection',
    'app.layout.menu.online_protection.ec_record_list': 'EC詳細データ一覧',
    'app.layout.menu.online_protection.sns_record_list': 'SNS詳細データ一覧',
    'app.layout.menu.online_protection.ws_record_list': 'WS詳細データ一覧',
    'app.layout.menu.online_protection.report_list': 'ECレポート',

    'app.layout.menu.offline_protection': 'Offilne Protection',

    'app.layout.menu.legal_services': 'Legal Services',
    'app.layout.menu.legal_services.tm_search': '商標検索',
    'app.layout.menu.legal_services.tm_register': '商標出願',
    'app.layout.menu.legal_services.tm_application_status': '進行状況確認',
    'app.layout.menu.legal_services.tm_update_status': '商標更新手続き',
    'app.layout.menu.legal_services.tm_rejection_reason': '拒絶理由レポート',
    'app.layout.menu.legal_services.tm_transfer': '商標変更・移転',
    'app.layout.menu.legal_services.tm_monitoring': '商標監視',
    'app.layout.menu.legal_services.tm_correction_invaildation_trail': '登録無効審判',
    'app.layout.menu.legal_services.tm_case_management': 'ケース管理',
    'app.layout.menu.legal_services.tm_registration': '商標登録状況',

    'app.layout.menu.biz_supprot': 'Biz Support',
    'app.layout.menu.news': 'News',
    'app.layout.menu.notice': 'Notice',

    'app.layout.menu.other_support': 'Other',
    'app.layout.menu.test_purfchase': 'Test Purchase',

    'app.layout.menu.task': 'Task',
    'app.layout.menu.task.ec_crawler_task': 'EC Task',

    'app.layout.menu.setting': 'Setting',
    'app.layout.menu.setting.profile': 'プロフィール設定',
    'app.layout.menu.setting.data_setting' : 'データ設定',
    'app.layout.menu.setting.user_list': 'ユーザー登録一覧',
    'app.layout.menu.setting.brand_list': 'ブランド登録一覧',
    'app.layout.menu.setting.manager_list': '管理者登録一覧',
    'app.layout.menu.setting.ec_list': 'EC登録',
    'app.layout.menu.setting.infringement_category': 'カテゴライズ基準',

    // ec-dashboard
    'app.ui.dashboard.title': 'ダッシュボード',
    'app.ui.dashboard.breadcrumb.dashboard': 'ダッシュボード',
    'app.ui.dashboard.search.brand': 'クライアント名',
    'app.ui.dashboard.search.date_column': '日付コラム',
    'app.ui.dashboard.search.date_from': '期間',
    'app.ui.dashboard.search.date_to': '~',
    'app.ui.dashboard.status.title': '基本ステータス',
    'app.ui.dashboard.search.find': '作成',

    'app.ui.dashboard.status.impact_revenue': 'Infringement Impact Revenue',
    'app.ui.dashboard.status.impact_revenue.usd': 'USD:',
    'app.ui.dashboard.status.impact_revenue.rate': 'Rate:',
    'app.ui.dashboard.status.impact_revenue.jpy': 'JPY:',
    'app.ui.dashboard.status.annual_date': 'Annual Status - Date',
    'app.ui.dashboard.status.analyst': '担当者',

    'app.ui.dashboard.chart.overall_categorization_status.title': 'Overall Categorization Status',
    'app.ui.dashboard.chart.overall_categorization_status.legend.categorized': 'Categorized',
    'app.ui.dashboard.chart.overall_categorization_status.legend.infringment': 'Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.legend.infringment_percent': 'Infringement%',
    'app.ui.dashboard.chart.overall_categorization_status.left_y_axis_name': '件数',
    'app.ui.dashboard.chart.overall_categorization_status.right_y_axis_name': '%',

    'app.ui.dashboard.chart.overall_infringement_type.title': 'Overall Infringement Type',
    'app.ui.dashboard.map.overall_infringement_country.title': 'Overall Infringement By Region',
    'app.ui.dashboard.chart.overall_infringement_country.title': 'Overall Infringement By Region',
    'app.ui.dashboard.table.categorization_status.title': 'Categorization Status',
    'app.ui.dashboard.chart.locale_filtering.title': 'Region Filtering',
    'app.ui.dashboard.filter.locale_filtering.title': '国・地区',

    'app.ui.dashboard.chart.ec_infringement_categorization.title': 'EC Infringement Categorization',
    'app.ui.dashboard.list.top_infringers.title': 'Top Infringers',
    'app.ui.dashboard.chart.takedown.title': 'Takedown',

    'app.ui.dashboard.chart.ws_takedown.title': 'Web Site Takedown',
    'app.ui.dashboard.chart.ws_category.title': 'Web Site Categorization',

    // ec-list
    'app.ui.eclist.title': 'ECデータ一覧',
    'app.ui.eclist.to_trademark_list': '商標登録リスト',
    'app.ui.eclist.table.action_history': '編集履歴',

    'app.ui.eclist.column_modal.title': "コラム表示設定",

    'app.ui.eclist.table.id': "ID",
    'app.ui.eclist.table.ec_no': "管理番号",
    'app.ui.eclist.table.ec_site': "ECサイト",
    'app.ui.eclist.table.ec_scan_month': "スキャン月",
    'app.ui.eclist.table.ec_scan_date': "スキャン月日",
    'app.ui.eclist.table.ec_search_keyword': "検索KW",
    'app.ui.eclist.table.ec_product_no': "Listing #",
    'app.ui.eclist.table.ec_product_title': "出品タイトル",
    'app.ui.eclist.table.ec_product_url': "出品URL",
    'app.ui.eclist.table.ec_product_brand': "ブランド欄",
    'app.ui.eclist.table.ec_product_seller': "出品者名",
    'app.ui.eclist.table.ec_product_seller_url': "出品者URL",
    'app.ui.eclist.table.ec_product_price': "価格",
    'app.ui.eclist.table.ec_product_currency': "通貨",
    'app.ui.eclist.table.ec_product_image_url': "画像URL",
    'app.ui.eclist.table.ec_product_onsell_count': "在庫数",
    'app.ui.eclist.table.ec_product_sold_count': "販売件数",
    'app.ui.eclist.table.ec_product_region': "出品国",
    'app.ui.eclist.table.ec_product_city': "出品地域",
    'app.ui.eclist.table.ec_categorize_date': "レポート提出日",
    'app.ui.eclist.table.ec_blacklist_object': "ブラックリスト対象",
    'app.ui.eclist.table.ec_whitelist_object': "ホワイトリスト対象",
    'app.ui.eclist.table.ec_is_need_to_check': "簡易診断",
    'app.ui.eclist.table.ec_title_hint_word': "ブランド表記判定（タイトル）",
    'app.ui.eclist.table.ec_brand_hint_word': "ブランド表記判定",
    'app.ui.eclist.table.ec_product_category_id_code': "製品判定入力欄",
    'app.ui.eclist.table.ec_product_category_id_word': "製品判定参照結果",
    'app.ui.eclist.table.ec_category_rule_id': "採用基準表",
    'app.ui.eclist.table.ec_category_id_code': "入力欄",
    'app.ui.eclist.table.ec_category_id_word': "カテゴリ",
    'app.ui.eclist.table.ec_category_id_common': "集計",
    'app.ui.eclist.table.ec_categorize_remarks': "IPFコメント",
    'app.ui.eclist.table.ec_response_action': "申告実施フラグ",
    'app.ui.eclist.table.ec_response_reason': "貴社判定コメント",
    'app.ui.eclist.table.ec_response_trademark': "商標・著作権登録番号",
    'app.ui.eclist.table.ec_response_date': "回答日",
    'app.ui.eclist.table.ec_process1_date': "1回目申告日",
    'app.ui.eclist.table.ec_process1_result': "1回目申告結果",
    'app.ui.eclist.table.ec_process1_confirm_date': "1回目確認日",
    'app.ui.eclist.table.ec_process2_date': "2回目申告日",
    'app.ui.eclist.table.ec_process2_result': "2回目申告結果",
    'app.ui.eclist.table.ec_process2_confirm_date': "2回目確認日",
    'app.ui.eclist.table.ec_process3_date': "3回目申告日",
    'app.ui.eclist.table.ec_process3_result': "3回目申告結果",
    'app.ui.eclist.table.ec_process3_confirm_date': "3回目確認日",
    'app.ui.eclist.table.declaration_status': "申告状況",
    'app.ui.eclist.table.ec_process_result': "最終結果",
    'app.ui.eclist.table.ec_process_confirm_date': "最終結果確認日",
    'app.ui.eclist.table.ec_process_remarks': "備考",
    'app.ui.eclist.table.report_id': "レポート名",

    // ec list history
    'app.ui.eclist_history.table.edited_by': "編集者",
    'app.ui.eclist_history.table.edited_date': "編集日付",

    // profile
    'app.ui.profile.section.basicinfo.title': '基本情報',
    'app.ui.profile.section.basicinfo.name': '名前（必須）',
    'app.ui.profile.section.basicinfo.mail': 'メール',
    'app.ui.profile.section.basicinfo.tel': '電話番号',
    'app.ui.profile.section.basicinfo.name.error': '名前を入力ください。',
    'app.ui.profile.section.basicinfo.tel.error': '正しい電話番号を入力ください。',
    'app.ui.profile.section.basicinfo.update': '更新',

    'app.ui.profile.section.company.title': '会社情報',
    'app.ui.profile.section.company.name': '会社名',
    'app.ui.profile.section.company.address': '住所',
    'app.ui.profile.section.company.tel': '連絡先',
    'app.ui.profile.section.company.remarks': '備考',

    'app.ui.profile.section.analyst.title': 'アナリスト情報',
    'app.ui.profile.section.analyst.table.name': '名前',
    'app.ui.profile.section.analyst.table.mail': 'メール',
    'app.ui.profile.section.analyst.table.tel': '電話番号',

    'app.ui.profile.section.contract.title': '契約情報',
    'app.ui.profile.section.contract.table.name': 'サービス',
    'app.ui.profile.section.contract.table.duration_from': '開始日付',
    'app.ui.profile.section.contract.table.duration_to': '終了日付',
    'app.ui.profile.section.contract.table.fee': '金額',

    'app.ui.profile.action.reset_password': 'パスワードリセット',
    'app.ui.profile.breadcrumb.password': 'パスワード',
    'app.ui.profile.breadcrumb.profile': 'プロフィール設定',
    'app.ui.profile.breadcrumb.home': 'ホーム',

    'app.ui.profile.message.success.update_success': 'アカウント情報を更新しました。',
    'app.ui.profile.message.error.update_failed': 'エラーが発生しました。',
    'app.ui.profile.message.error.password_character': 'パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。',
    'app.ui.profile.message.error.confirm_password_same': '新パスワードと新パスワード（確認）は同じではなく、確認してください。',
    'app.ui.profile.message.success.password_reset_success': 'パスワードをリセットしました。',
    'app.ui.profile.message.error.password_reset_failed': 'パスワードのリセットを失敗しました。',

    'app.ui.profile.password_reset.title': 'パスワードリセット情報',
    'app.ui.profile.password_reset.old_password': '旧パスワード（必須）',
    'app.ui.profile.password_reset.new_password': '新パスワード（必須）',
    'app.ui.profile.password_reset.new_password_confirm': '新パスワード（確認）（必須）',
    'app.ui.profile.password_reset.code': '確認コード（必須）',
    'app.ui.profile.password_reset.reset_button': 'リセット',

    'app.ui.profile.password_reset.message.error.no_current_password': '旧パスワードを入力ください。',
    'app.ui.profile.password_reset.message.error.no_new_password': '新パスワードを入力ください。',
    'app.ui.profile.password_reset.message.error.no_confirm_new_password': '新パスワード（確認）を入力ください。',
    'app.ui.profile.password_reset.message.error.no_code': '確認コードを入力ください。',

    // login
    'app.ui.login.label.mail': 'メール：',
    'app.ui.login.label.password': 'パスワード：',
    'app.ui.login.label.password_confirm': 'パスワード（確認）：',
    'app.ui.login.label.name': '名前：',
    'app.ui.login.label.verification_code': '確認コード：',
    'app.ui.login.button.login': 'ログイン',
    'app.ui.login.button.setup': '新規',
    'app.ui.login.button.to_setup': '新規登録',
    'app.ui.login.button.forget_password': 'パスワードを忘れた方はこちら',
    'app.ui.login.button.to_login': 'ログイン画面へ',

    'app.ui.login.label.new_password': '新パスワード：',
    'app.ui.login.label.new_password_confirm': '新パスワード（確認）：',
    'app.ui.login.button.reset': 'リセット',

    'app.ui.login.message.error.no_mail': 'メールを入力ください。',
    'app.ui.login.message.error.mail_format': '正しいメールを入力ください。',
    'app.ui.login.error.no_password': 'パスワードを入力ください。',
    'app.ui.login.error.no_confirm_password': 'パスワード（確認）を入力ください。',

    // T/M trademark
    'app.ui.trademark.register_title': '商標出願',
    'app.ui.trademark.container.trademark_info.title': '商標情報',
    'app.ui.trademark.label.trademark_word': '商標文字',
    'app.ui.trademark.label.trademark_word_description': '商標が文字だけの場合は、こちらに直接ご記入ください。ただし、デザイン文字の場合はこちらに記入せずに、その画像を添付してください。',
    'app.ui.trademark.label.trademark_image': '商標画像',
    'app.ui.trademark.label.trademark_image_description': 'ロゴでの登録をご希望の場合は、画像を添付してください。(10MB以内)',
    'app.ui.trademark.label.trademark_detail': '商標詳細（必須）',
    'app.ui.trademark.label.trademark_detail_description': 'その商標をどうのような商品・サービスに使用しますか？具代的にご記入ください。',
    'app.ui.trademark.label.trademark_owner': '出願人名（必須）',
    'app.ui.trademark.label.trademark_owner_description': '法人の場合は、登記上の法人名をご入力ください。',
    'app.ui.trademark.container.contact_info.title': 'ご連絡情報',
    'app.ui.trademark.label.contact_name': 'ご担当者（必須）',
    'app.ui.trademark.label.contact_mail': 'メール（必須）',
    'app.ui.trademark.label.contact_tel': 'Tel（必須）',
    'app.ui.trademark.label.contact_fax': 'Fax',
    'app.ui.trademark.label.remarks': '通信欄',
    'app.ui.trademark.label.remarks_description': 'ご要望など',

    'app.ui.trademark.message.error.no_word_or_image_mark': '商標文字あるいは商標画像を入力してください。',
    'app.ui.trademark.message.error.tel_format': '正しい電話番号を入力してください。',
    'app.ui.trademark.message.success.mail_success': '送信しました。',
    'app.ui.trademark.message.error.no_trademark_discraption': '商標詳細を入力ください。',
    'app.ui.trademark.message.error.no_owner': '出願人名を入力ください。',
    'app.ui.trademark.message.error.no_contact_person': 'ご担当者を入力ください。',
    'app.ui.trademark.message.error.no_contact_mail': 'ご担当者のメールを入力ください。',
    'app.ui.trademark.message.error.contact_mail_format': '正しいメールを入力ください。',
    'app.ui.trademark.message.error.no_contact_tel': 'ご担当者の電話番号を入力ください。',

    'app.ui.trademark.button.send_mail': '送信',
    'app.ui.trademark.button.to_registration': '新規',
    'app.ui.trademark.button.to_request_trademark': '商標出願希望',
    'app.ui.trademark.button.to_trademark_list': 'リスト登録',

    'app.ui.trademark.list.title': '商標',


    'app.ui.trademark.table.id': 'ID',
    'app.ui.trademark.table.client': 'クライアント',
    'app.ui.trademark.table.trademark_no': '商標番号',
    'app.ui.trademark.table.trademark_region': '国・地区',
    'app.ui.trademark.table.trademark_product_genre': '製品ジャンル',
    'app.ui.trademark.table.trademark_word': '文字・ロゴ',
    'app.ui.trademark.table.trademark_registration_date': '登録日',
    'app.ui.trademark.table.trademark_expire_date': '失効日',
    'app.ui.trademark.table.remind_mail_flg': 'リマインドメール',
    'app.ui.trademark.table.trademark_right_holder': '権利者',
    'app.ui.trademark.table.description_ja': '指定製品・役務（和文）',
    'app.ui.trademark.table.description_en': '指定製品・役務（英文）',
    'app.ui.trademark.table.description_cn': '指定製品・役務（中文）',
    'app.ui.trademark.table.trademark_registration_status': 'ステータス',
    'app.ui.trademark.table.trademark_remarks': '備考',


    'app.trademark_registration_status.register': '登録',
    'app.trademark_registration_status.applying': '申請中',
    'app.trademark_registration_status.rejection': '拒否',

    'app.ui.trademark.add.title': '商標新規',
    'app.ui.trademark.add.container_search.title': '商標情報検索',
    'app.ui.trademark.add.container_search.button': '外部検索',
    'app.ui.trademark.add.container_body.title': '商標情報',
    'app.ui.trademark.add.product_genre_prefix': "第{number}類",
    'app.ui.trademark.add.remind_message': "失効日の30、60、90日前に通知",

    'app.ui.trademark.update.title': '商標詳細',

    'app.ui.trademark.message.error.no_search_db': "外部データベースを選択してください。",
    'app.ui.trademark.message.info.image_uploading': "商標画像はまだアップロード中です。少々お待ちください。",
    'app.ui.trademark.message.info.trademark_updated': "更新しました。",
    'app.ui.trademark.message.error.no_region': "国・地区を入力ください。",
    'app.ui.trademark.message.error.no_trademark_no': "商標番号を入力ください。",
    'app.ui.trademark.message.error.no_product_genre': "製品ジャンルを入力ください。",
    'app.ui.trademark.message.error.no_word_mark': "文字・ロゴを入力ください。",

    'app.ui.trademark.message.info.trademark_added': "完了しました。",
    'app.ui.trademark.message.error.no_client': "クライアントを入力ください。",

    // reports
    'app.ui.ec_reports.table.id': "ID",
    'app.ui.ec_reports.table.report_name': "レポート名",
    'app.ui.ec_reports.table.upload_date': "アップロード日",
    'app.ui.ec_reports.table.scrape_total': "Scrape総数",
    'app.ui.ec_reports.table.send_date': "公開日",
    'ap.ui.ec_reports.table.categorized_count': "カテゴライズ数",
    'app.ui.ec_reports.table.not_categorize_date': "未カテゴライズ数",
    'app.ui.ec_reports.table.response_date': "回答日",
    'app.ui.ec_reports.table.response_total': "回答数",
    'app.ui.ec_reports.action.response': "回答",

    'app.ui.ec_reports.message.info.had_notify_analyst': "アナリストに知らせました。",
    'app.ui.ec_reports.message.error.nofity_failed': "エラーが発生しました。",
    'app.ui.ec_reports.message.error.unknown': "エラーが発生しました。",

    // ec detail
    'app.ui.ec_detail.title': "EC詳細データ",
    'app.ui.ec_detail.tab.web': "Web",
    'app.ui.ec_detail.tab.thumbnail': "サムネイル",
    'app.ui.ec_detail.tab.screenshot': "違反画像",
    'app.ui.ec_detail.tab.scrape': "Scrape情報",
    'app.ui.ec_detail.tab.categorized_info': "カテゴライズ情報",
    'app.ui.ec_detail.tab.client_response': "クライアント回答情報",
    'app.ui.ec_detail.tab.ipf_report_info': "IPF申告情報",
    'app.ui.ec_detail.button.back': "一覧に戻る",
    'app.ui.ec_detail.step.1': "申告結果(1回目)",
    'app.ui.ec_detail.step.2': "申告結果(2回目)",
    'app.ui.ec_detail.step.3': "申告結果(3回目)",
    'app.ui.ec_detail.step.4': "最終結果",
    'app.ui.ec_detail.message.info.update_success': "更新成功しました。",
    'app.ui.ec_detail.message.error.update_failed': "更新失敗しました。",

    // home
    'app.ui.home.online.cases': "ブランド保護件数",
    'app.ui.home.offline.cases': "法的措置",
    'app.ui.home.registration.cases': "出願件数",
    'app.ui.home.test_purchase.cases': "テスト購入数",

    'app.ui.home.section.tm_register_region': "権利出願国",
    'app.ui.home.section.online_bad_seller': "オンライン権利侵害出品者",
    'app.ui.home.section.test_purchase_cases_number': "テスト購入件数",
    'app.ui.home.section.offline_protection': "オンライン法的措置",
    'app.ui.home.section.ipf_news': "IP FORWARD NEWS",

    'app.ui.home.section.unit': "件",

    // notification center
    'app.ui.notification_center.title': 'お知らせ',
    'app.ui.notification_center.table.column.id': "ID",
    'app.ui.notification_center.table.column.title': "タイトル",
    'app.ui.notification_center.table.column.content': "内容",
    'app.ui.notification_center.table.column.type': "種類",
    'app.ui.notification_center.table.column.from': "発信元",
    'app.ui.notification_center.table.column.send_date': "発信日付",
    'app.ui.notification_center.table.column.viewed': "既読",

    // ws
    'app.ui.wslist.table.search_engine': '検索エンジン',
    'app.ui.wslist.table.page_domain': 'ドメイン',
    'app.ui.wslist.table.page_url': 'URL',
    'app.ui.wslist.table.domain_region': 'ドメイン範囲',
    'app.ui.wslist.table.tld': 'TLD',
    'app.ui.wslist.table.domain_contact': '連絡先',
    'app.ui.wslist.table.redirect_url': 'リダイレクト先',
    'app.ui.wslist.table.whois': 'ドメイン情報',
    'app.ui.wslist.table.domain_created_at': 'ドメイン作成日付',
    'app.ui.wslist.table.domain_expire_at': 'ドメイン失効日付',
    'app.ui.wslist.table.domain_updated_at': 'ドメイン更新日付',
    'app.ui.wslist.search.collapse': "検索条件",

    "app.ui.ws_detail.title": "WS詳細データ",
    'app.ui.ws_detail.section.scrape_info': "基本情報",

    "app.ui.data_setting.other_setting.tag_title": "インパクト金額",
    "app.ui.data_setting.other_setting.impact_func": "インパクト金額計算式：A＊B＊C",
    "app.ui.data_setting.other_setting.price_factor": "単価(A)：",
    "app.ui.data_setting.other_setting.displayed_price": "表示単価",
    "app.ui.data_setting.other_setting.flat_price": "一律単価",
    "app.ui.data_setting.other_setting.count_factor": "係数(B)：",
    "app.ui.data_setting.other_setting.inventory": "在庫数",
    "app.ui.data_setting.other_setting.sold_count": "販売（Sold)数",
    "app.ui.data_setting.other_setting.flat_count": "一律係数",
    "app.ui.data_setting.other_setting.percent_factor": "その他(C)：",
}

const combine_ja_lang = {
    ...common_ja_lang,
    ...ec_crawler_task_ja_lang
}

export default combine_ja_lang


