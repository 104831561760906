import { List } from "antd";
import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store";
import { reqFetchIPFNews } from '@/api/ipf-news.js'
import './index.less'
function ECIPFNews(props) {
    const [news, setNews] = useState([])
    useEffect(() => {
        document.title = "News"
        // store.dispatch(setMenuActiveKey("analyst-ec-list"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.layout.menu.news" />,
                path: "/news",
            },
        ]));

        reqFetchIPFNews({ page: 1, pageSize: 1000 }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setNews(data.data.news)
            } else {
            }
        }).catch(e => {
        })

    }, [])

    return (
        <div className="ipf-news-container">
            <List
                pagination={{
                        pageSize:20
                    }}
                loading={false}
                itemLayout="horizontal"
                dataSource={news}
                renderItem={item => (
                    <List.Item>
                        <div><a class="news-item" href={item.url} target="_blank" rel="noreferrer">{item.title}</a></div>
                    </List.Item>
                )}
            />
        </div>
    )
}

export default ECIPFNews