// trademark intl messages

import { defineMessages } from 'react-intl'
const messages = defineMessages({
    'register_title': {
        id: "app.ui.trademark.register_title",
        defaultMessage: "T/M Register",
    },
    'message.error.no_word_or_image_mark': {
        id: "app.ui.trademark.message.error.no_word_or_image_mark",
        defaultMessage: "Enter the word mark or image mark.",
    },
    'message.error.tel_format': {
        id: "app.ui.trademark.message.error.no_word_or_image_mark",
        defaultMessage: "Please enter a valid phone number.",
    },

    'message.success.mail_success': {
        id: "app.ui.trademark.message.success.mail_success",
        defaultMessage: "Submitted trademark information to the IPF.",
    },
    'message.error.no_trademark_discraption': {
        id: "app.ui.trademark.message.error.no_trademark_discraption",
        defaultMessage: "Please enter your trademark discraption.",
    },
    'message.error.no_owner': {
        id: "app.ui.trademark.message.error.no_owner",
        defaultMessage: "Please enter the applicant name.",
    },
    'message.error.no_contact_person': {
        id: "app.ui.trademark.message.error.no_contact_person",
        defaultMessage: "Please enter the person in charge.",
    },

    'message.error.no_contact_mail': {
        id: "app.ui.trademark.message.error.no_contact_mail",
        defaultMessage: "Please enter the email of the person in charge.",
    },

    'message.error.contact_mail_format': {
        id: "app.ui.trademark.message.error.contact_mail_format",
        defaultMessage: "Please enter a valid email.",
    },

    'message.error.no_contact_tel': {
        id: "app.ui.trademark.message.error.no_contact_tel",
        defaultMessage: "Please enter the phone number of the person in charge.",
    },

    'table.trademark_word': {
        id: "app.ui.trademark.table.trademark_word",
        defaultMessage: "Text/Logo",
    },

    'table.trademark_right_holder': {
        id: "app.ui.trademark.table.trademark_right_holder",
        defaultMessage: "Right holder",
    },

    'table.description_ja': {
        id: "app.ui.trademark.table.description_ja",
        defaultMessage: "Designated products and services (Japanese)",
    },

    'table.description_en': {
        id: "app.ui.trademark.table.description_en",
        defaultMessage: "Designated products and services (English)",
    },

    'table.description_cn': {
        id: "app.ui.trademark.table.description_cn",
        defaultMessage: "Designated products and services (Chinese)",
    },

    'table.trademark_registration_date': {
        id: "app.ui.trademark.table.trademark_registration_date",
        defaultMessage: "Registration date",
    },

    'table.trademark_expire_date': {
        id: "app.ui.trademark.table.trademark_expire_date",
        defaultMessage: "Expire date",
    },
    
    'registration_add_title': {
        id: "app.ui.trademark.add.title",
        defaultMessage: "T/M Registration - Add",
    },
    'add.remind_message': {
        id: "app.ui.trademark.add.remind_message",
        defaultMessage: "Notify when it is 30, 60, or 90 days before the expiration date.",
    },
    
    'registration_update_title': {
        id: "app.ui.trademark.update.title",
        defaultMessage: "T/M Registration - Update",
    },

    'message.error.no_search_db': {
        id: "app.ui.trademark.message.error.no_search_db",
        defaultMessage: "Choose a trademark database.",
    },

    'message.info.image_uploading': {
        id: "app.ui.trademark.message.info.image_uploading",
        defaultMessage: "Trademark images are still being uploaded. please wait a little bit.",
    },

    'message.info.trademark_updated': {
        id: "app.ui.trademark.message.info.trademark_updated",
        defaultMessage: "Trademark information has been updated.",
    },

    'message.error.no_region': {
        id: "app.ui.trademark.message.error.no_region",
        defaultMessage: "Please select the trademark region.",
    },

    'message.error.no_trademark_no': {
        id: "app.ui.trademark.message.error.no_trademark_no",
        defaultMessage: "Please input the trademark number.",
    },

    'message.error.no_product_genre': {
        id: "app.ui.trademark.message.error.no_product_genre",
        defaultMessage: "Please select the product genre.",
    },

    'message.error.no_word_mark': {
        id: "app.ui.trademark.message.error.no_word_mark",
        defaultMessage: "Please input the word mark.",
    },

    'message.info.trademark_added': {
        id: "app.ui.trademark.message.info.trademark_added",
        defaultMessage: "Adding trademark is completed.",
    },

    'message.error.no_client': {
        id: "app.ui.trademark.message.error.no_client",
        defaultMessage: "Please select the client.",
    },

    'registration_list_title': {
        id: "app.layout.menu.legal_services.tm_registration",
        defaultMessage: "T/M Registration",
    },

})

export default messages