import "./index.less"
import React, { Component } from "react";
import { Form, Select, DatePicker, Checkbox } from "antd";

import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormSelect, ProFormDatePicker, ProFormCheckbox } from '@ant-design/pro-form';
import { CountryConfig } from "@/config/country.js"
import moment from 'moment';

import { reqCreateAttorneyLetter, reqFetchAttorneyLetters, reqRemoveAttorneyLetter, reqUpdateAttorneyLetter } from '../../../api/analyst-attorney-letter'

class AnalystClientAttorneyLetter extends Component {


    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
        if (this.props.clientId) {
            reqFetchAttorneyLetters({
                clientId: this.props.clientId,
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ datesource: data.data.letters });
                }

            }).catch((error) => {

            })

        }
    }

    createColumns = () => {
        return [
            {
                title: '番号',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '国・地区',
                dataIndex: 'region',
                key: 'region',
                renderText: (record) => {
                    for (const country of CountryConfig) {
                        if (country.code === record.region) {
                            return country.name.ja
                        }
                    }
                },
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"region_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "国・地区を入力ください",
                                }
                            ]}>
                            <Select allowClear
                                showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                {CountryConfig.map((country) => {
                                    return <Select.Option key={`${country.code}${country.name.ja}${country.name.en}`} value={country.code}>{country.name.ja}</Select.Option>
                                })}
                            </Select>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '開始日',
                dataIndex: 'startDate',
                key: 'startDate',
                renderText: (record) => {
                    if (record.startDate) {
                        return moment(record.startDate, "YYYY-MM-DD").format("YYYY-MM-DD")
                    }
                    return ""
                },

                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"startDate_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "開始日を入力ください",
                                }
                            ]}>
                            <DatePicker />
                        </ Form.Item >
                    )
                }
            },
            {
                title: '終了日',
                dataIndex: 'endDate',
                key: 'endDate',
                renderText: (record) => {
                    if (record.endDate) {
                        return moment(record.endDate, "YYYY-MM-DD").format("YYYY-MM-DD")
                    }
                    return ""
                },

                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"endDate_" + record.id}
                            rules={[
                                {
                                    required: true,
                                    message: "終了日を入力ください",
                                }
                            ]}>
                            <DatePicker />
                        </ Form.Item >
                    )
                }
            },

            {
                title: 'リマインドメール',
                dataIndex: 'remindFlag',
                key: 'remind',
                renderText: (record) => {
                    if (record.remindFlag) {
                        return "あり"
                    } else {
                        return "なし"
                    }
                },

                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"remind_" + record.id}
                        >
                            <Checkbox.Group options={[{ value: "1" }]} />
                        </ Form.Item >
                    )
                }
            },
        ];

    }

    createModalItems = () => {
        return (
            <>
                <ProFormSelect
                    width="sm"
                    options={
                        CountryConfig.map((country) => {
                            return {
                                value: country.code,
                                label: country.name.ja,
                                key: `${country.name.ja}${country.name.en}`
                            }
                        })
                    }
                    fieldProps={{
                        optionFilterProp: "children",
                        filterOption: (inputValue, option) => {
                            return option.key.indexOf(inputValue) > -1 ? true : false;
                        }
                    }}
                    name="region"
                    label="国・地区"
                    rules={[
                        {
                            required: true,
                            message: '国・地区を入力ください。',
                        },
                    ]}
                />
                <ProFormDatePicker
                    width="sm"
                    name="startDate"
                    label="開始日"
                    rules={[
                        {
                            required: true,
                            message: '開始日を入力ください。',
                        },
                    ]}
                />
                <ProFormDatePicker
                    width="sm"
                    name="endDate"
                    label="終了日"
                    rules={[
                        {
                            required: true,
                            message: '終了日を入力ください。',
                        },
                    ]}
                />
                <ProFormCheckbox
                    width="sm"
                    name="remindFlag"
                    label="リマインドメール"
                    rules={[
                        {
                            required: false,
                            message: 'リマインドメールを入力ください。',
                        },
                    ]}
                />

            </>
        )
    }

    getEditFieldsValue = (record) => {
        let row = {}
        row["region_" + record.id] = record.region;
        row["startDate_" + record.id] = moment(record.startDate, "YYYY-MM-DD");
        row["endDate_" + record.id] = moment(record.endDate, "YYYY-MM-DD");
        row["remind_" + record.id] = record.remindFlag ? ["1"] : ["0"];
        return row
    }

    onRemoveClick = (record) => {
        reqRemoveAttorneyLetter({
            letterId: record.id,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.letters });
            }
        }).catch((e) => {

        })
    }

    onUpdateClick = (record, values) => {
        reqUpdateAttorneyLetter({
            letterId: record.id,
            region: values["region_" + record.id],
            startDate: values["startDate_" + record.id].format("YYYY-MM-DD"),
            endDate: values["endDate_" + record.id].format("YYYY-MM-DD"),
            remindFlag: values["remind_" + record.id] && values["remind_" + record.id][0] === "1" ? true : false,
            clientId: this.props.clientId
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.letters });
            }
        }).catch((e) => {

        })
    }

    onCreateClick = (values) => {

        reqCreateAttorneyLetter({
            region: values["region"],
            startDate: values["startDate"],
            endDate: values["endDate"],
            remindFlag: values["remindFlag"],
            clientId: this.props.clientId,
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({ datesource: data.data.letters });
            }
        }).catch((e) => {

        })
    }

    getTableProps = () => {
        let props = {
            pagination: false,
        }
        return props
    }

    render() {
        return (
            <div className="master-container">
                <EFCMasterTable
                    modalTitle="委任状"
                    rowkey="id"
                    columns={this.createColumns()}
                    dataSource={this.state.datesource}
                    getEditFieldsValue={this.getEditFieldsValue}
                    modalItems={this.createModalItems()}
                    onRemoveClick={this.onRemoveClick}
                    onSaveClick={this.onUpdateClick}
                    onModalFinished={this.onCreateClick}
                    tableProps={this.getTableProps()}
                />
            </div>
        )
    }
}

export default AnalystClientAttorneyLetter