import React, { Component } from "react";
import { Layout } from "antd";
import EFHeader from "./header"
import EFSider from "./sider"
import EFContent from "./content"
import { connect } from "react-redux";
import "./index.less"

class EFMainLayout extends Component {

    render() {
        const contentClassName = this.props.isCollapsed ?  "content-container-small":"content-container-normal"
        return (
            <Layout className='container' hasSider>
                <EFSider />
                <Layout className={contentClassName}>
                    <EFHeader />
                    <EFContent />
                </Layout>
            </Layout>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        ...state.mainMenu,
    }
}


export default connect(mapStateToProps)(EFMainLayout);
