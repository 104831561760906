import "./index.less"
import moment from 'moment';
import React, { Component } from "react";
import { connect } from "react-redux";
import { Table, Select, Button, message, Spin } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import EFCSearchBar from "@/components/ef-c-searchbar";
import { setBreadcrumbs, setContentContainerHeaderAction } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store";
import { reqSearchNotices, reqGetNoticeFilters, reqViewNotices } from "@/api/notice-center.js"

import { FormattedMessage, injectIntl } from "react-intl"
import messages from "./messages";
import ECListCommon from "../ec/common/ec-list-common.js"
import NoticeType from "./notice-config";

class NoticeCenterView extends Component {
    constructor(props) {
        super(props);
        this.defaultPageSize = 20
        this.state = {
            init_loading: false,
            search_loading: false,
            total: 0,
            unviewed: 0,
        };
    }
    getDateSearchItems() {
        return [
            {
                name: "Create at",
                intlKey: "app.ui.notification_center.table.column.send_date",
                value: "createdAt"
            },
        ]
    }

    getKeywordSearchItems() {
        return [
            {
                name: "Title",
                intlKey: "app.ui.notification_center.table.column.title",
                value: "title"
            },
            {
                name: "Content",
                intlKey: "app.ui.notification_center.table.column.content",
                value: "content"
            },
        ]
    }

    getTableColumns(onDetailClick) {
        return [
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.id" />,
                dataIndex: "id",
                key: "notice_id",
                width: 80,
            },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.title" />,
                dataIndex: "title",
                key: "notice_title",
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.content" />,
                dataIndex: "content",
                key: "notice_content",
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.type" />,
                dataIndex: "type",
                key: "notice_type",
                filters: NoticeType.map((item) => {
                    return {
                        text: item.name[this.props.intl.formatMessage(messages["notice_type.locale_key"])],
                        value: item.value
                    }
                }),
                onFilter: (value, record) => {
                    return value === record.type
                },
                filterMultiple: true,
                render: (value, record) => {
                    let item = NoticeType.find((notice) => {
                        return notice.value === value
                    })
                    return item.name[this.props.intl.formatMessage(messages["notice_type.locale_key"])]
                }
            },
            // {
            //     title: <FormattedMessage id="app.ui.notification_center.table.column.from" />,
            //     dataIndex: "from",
            //     key: "notice_from",
            // },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.send_date" />,
                dataIndex: "createdAt",
                key: "notice_send_date",
                sorter: true,
                render: (value, record) => {
                    return moment(value).format("YYYY-MM-DD")
                }
            },
            {
                title: <FormattedMessage id="app.ui.notification_center.table.column.viewed" />,
                dataIndex: "viewed",
                key: "notice_viewed",
                sorter: true,
                width: 180,
                render: (value, record) => {
                    return value ? "Yes" : "No"
                },

                filters: [
                    {
                        text: "Yes",
                        value: true
                    },
                    {
                        text: "No",
                        value: false
                    }
                ],

                onFilter: (value, record) => {
                    return value === record.viewed
                },
                filterMultiple: true,
            },
            {
                title: <FormattedMessage id="app.common.action" />,
                defaultShow: true,
                key: 'actions',
                fixed: "right",
                width: 150,
                render: (value, record) => (
                    <Button type="link" onClick={() => {
                        if (onDetailClick) {
                            onDetailClick(record)
                        }
                    }
                    }>
                        <FormattedMessage id="app.common.action.detail" />
                    </Button>
                ),
            },
        ]
    }

    async getInitData(searchParams) {
        await reqSearchNotices({
            "offset": 0,
            "limit": this.defaultPageSize,
        }).then((resp) => {
            // console.log(resp.data.data.notices)
            this.setState({
                init_loading: false,
                dataSource: resp.data.data.notices,
                total: resp.data.data.total,
                unviewed: resp.data.data.unviewed
            })
        })
        // Promise.all(filtersRequest, firstPageRequest).then(() => {
        //     this.setState({
        //         init_loading: false,
        //     })
        // })
    }

    componentDidMount() {
        this.getInitData()
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setMenuActiveKey("notice"));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.notification_center.title" />,
                path: "/notice",
            },
        ]));
    }

    onDetailClick = (record) => {
        if (record.viewed === false) {
            reqViewNotices({ noticeId: record.id })
            this.search()
        }
        window.open(record.target_url, 'target');
    }

    onTableValueChange = (pagination, filters, sorter, extraInfo) => {
        let searchParams = new URLSearchParams(this.props.location.search);
        if (pagination) {
            searchParams.set("pagination", encodeURIComponent(JSON.stringify(pagination)))
        }
        if (filters && extraInfo.action === "filter") {
            let effectFilterKeys = Object.keys(filters).filter((key) => {
                return filters[key]
            })
            let effectFilters = effectFilterKeys.map((key) => {
                let result = {}
                result.key = key;
                result.values = filters[key];
                return result;
            })
            if (effectFilters && effectFilters.length > 0) {
                searchParams.set("filters", encodeURIComponent(JSON.stringify(effectFilters)))
            } else {
                searchParams.set("filters", null)
            }
        }

        if (sorter && sorter.field) {
            if (sorter.order) {
                let sortInfo = {}
                sortInfo["key"] = sorter.field;
                sortInfo.value = sorter.order === "ascend" ? 1 : 2;
                searchParams.set("sorter", encodeURIComponent(JSON.stringify([sortInfo])))
            } else {
                searchParams.delete("sorter")
            }
        }

        this.props.history.push({
            pathname: this.props.location.pathname,
            search: `?${searchParams.toString()}`
        })


        let conditions = ECListCommon.converSearchParmasToConditions(searchParams)

        this.search(conditions)
    }

    search = (conditions) => {
        // let conditions = {}
        // this.handleSearch(conditions)
        this.setState({
            table_loading: true,
        })
        reqSearchNotices({ ...conditions }).then((resp) => {
            this.setState({
                table_loading: false,
                dataSource: resp.data.data.notices,
                total: resp.data.data.total,
                unviewed: resp.data.data.unviewed
            })
        }).catch((e) => {
            this.setState({
                table_loading: false,
            })
        })
    }

    // on search button click
    handlerSearch = (conditions) => {
        // console.log(conditions)
        this.search(conditions)
    }

    // on reset button click
    handleReset() {

    }

    createContentView() {
        return (<>
            <div className="notice-search-container">
                <EFCSearchBar
                    onSearch={this.handlerSearch}
                    onReset={this.handleReset}
                    dateItems={this.getDateSearchItems()}
                    keywordItems={this.getKeywordSearchItems()}
                    defaultValues={this.currentSearchConditions}
                />
            </div>
            <div className="notice-table-container">
                <div className="notice-tableview-header">
                    <span>{"Total:" + this.state.total} </span>
                    <span>{"Unviewed:" + this.state.unviewed} </span>
                </div>
                <Table
                    key="noticeTable"
                    bordered
                    tableLayout="fixed"
                    rowKey={(record) => {
                        return "notice_row_" + record.id
                    }}
                    columns={this.getTableColumns(this.onDetailClick)}
                    dataSource={this.state.dataSource}
                    onChange={this.onTableValueChange}
                    size="small"
                    loading={this.state.table_loading}

                    rowClassName={(record) => {
                        if (record.viewed) {
                            return ""
                        } else {
                            return "notice-unviewd-row"
                        }
                    }}

                    pagination={{
                        showQuickJumper: true,
                        total: this.state.total,
                        pageSizeOptions: [10, 20, 50, 100, 200, 500],
                        defaultPageSize: this.defaultPageSize,
                        showSizeChanger: true,
                        itemRender: (page, type, originalElement) => {
                            if (type === "prev") {
                                // eslint-disable-next-line jsx-a11y/no-access-key
                                return <Button type="button" className="ant-pagination-item-link" icon={<LeftOutlined />} accesskey="P"></Button>
                            } else if (type === "next") {
                                // eslint-disable-next-line jsx-a11y/no-access-key
                                return <Button type="link" className="ant-pagination-item-link" icon={<RightOutlined />} accesskey="N"></Button>
                            } else {
                                return originalElement
                            }
                        }
                    }}
                    scroll={{
                        x: 'max-content',
                        y: 600
                    }}
                />
            </div>
        </>)
    }

    render() {
        document.title = this.props.intl.formatMessage(messages["notice.title"])
        if (this.state.init_loading) {
            return (
                <div>
                    <Spin tip="Loading...">
                        {this.createContentView()}
                    </Spin>
                </div>
            )
        } else {
            return (
                <div>
                    {this.createContentView()}
                </div>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(injectIntl(NoticeCenterView))