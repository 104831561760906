import { Button, Input, Select, Row, Col, Form, message } from "antd";
import React, { useState, useEffect, useRef } from "react";
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import EFCMasterTable from "@/components/ef-c-master-table";
import { ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { FormattedMessage, injectIntl } from "react-intl";
import { reqUpdateECSite, reqGetECSite } from '@/api/analyst-site';
import { reqCreateSiteAccount } from '@/api/site-account';
import { CountryConfig } from "@/config/country.js"
import { reqFetchInfringementCategories } from '@/api/analyst-infringement-category'

import "./index.less"
import { reqCreateECSite } from "../../../../api/analyst-site";


function EFECSiteCreateView(props) {
    const formRef = useRef()
    const [accounts, setAccounts] = useState([])
    const addAccountIndex = useRef(0)
    const [categories, setCategories] = useState([])

    useEffect(() => {
        document.title = "ECサイト詳細"
        store.dispatch(setMenuActiveKey("analyst-ec-site"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: "EC登録",
                path: "/analyst/ec-site",
            },
            {
                name: "詳細",
            },
        ]));
    }, [])

    useEffect(() => {
        reqFetchInfringementCategories().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setCategories(data.data.categories)
            }
        }).catch((error) => {
        })
    }, [])

    const createAccountColumns = () => {
        return [
            {
                title: 'アカウント',
                dataIndex: 'account',
                key: 'account',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"account" + record["key"]}
                            rules={[
                                {
                                    required: true,
                                    message: "アカウントを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: 'パスワード',
                dataIndex: 'password',
                key: 'password',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"password" + record["key"]}
                            rules={[
                                {
                                    required: true,
                                    message: "パスワードを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '携帯電話',
                dataIndex: 'phone',
                key: 'phone',
                width: 250,
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"phone" + record["key"]}
                            rules={[
                                {
                                    required: false,
                                    message: "パスワードを入力ください",
                                }
                            ]}>
                            <Input></Input>
                        </ Form.Item >
                    )
                }
            },
            {
                title: 'ヘッダー',
                dataIndex: 'headers',
                key: 'headers',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"headers" + record["key"]}
                            rules={[
                                {
                                    required: false,
                                    message: "ヘッダーを入力ください",
                                }
                            ]}>
                            <Input.TextArea ></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
            {
                title: 'クッキー',
                dataIndex: 'cookies',
                key: 'cookies',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"cookies" + record["key"]}
                            rules={[
                                {
                                    required: false,
                                    message: "クッキーを入力ください",
                                }
                            ]}>
                            <Input.TextArea ></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
            {
                title: '備考',
                dataIndex: 'remarks',
                key: 'remarks',
                editCell: (text, record) => {
                    return (
                        <Form.Item
                            noStyle={true}
                            name={"remarks" + record["key"]}
                            rules={[
                                {
                                    required: false,
                                    message: "希望件数を入力ください",
                                    type: "number"
                                }
                            ]}>
                            <Input.TextArea ></Input.TextArea>
                        </ Form.Item >
                    )
                }
            },
        ];
    }

    const onCreateSite = (values) => {
        const params = {...values,accounts:accounts}
        reqCreateECSite(params).then((response)=>{
            message.success("成功しました。")
            props.history.push("/analyst/ec-site")
        }).catch((e)=>{
            message.error("失敗しました。")
        })
    }

    const onCreateSiteFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        // this.setState({ error: errors[0] })
    }

    const onRemoveAccountClick = (record) => {
        const index = accounts.findIndex(obj => {
            return obj.key === record.key
        })
        if(index !== -1){
            const newAccounts = [...accounts].splice(index,1)
            setAccounts(newAccounts)
        }
    }

    const onUpdateAccountClick = (record, values) => {
        const index = accounts.findIndex(obj => {
            return obj.key === record.key
        })
        if(index !== -1){
            const newAccounts = [...accounts]
            const newAccount = {...record}
            newAccount["account"] = values["account" + record.key]
            newAccount["password"] = values["password" + record.key]
            newAccount["phone"] = values["phone" + record.key]
            newAccount["headers"] = values["headers" + record.key]
            newAccount["cookies"] = values["cookies" + record.key]
            newAccount["remarks"] = values["remarks" + record.key]
            newAccounts[index] = newAccount
            setAccounts(newAccounts)
        }
    }

    const onCreateAccountClick = (values) => {
        addAccountIndex.current = addAccountIndex.current + 1
        const key = "key" + addAccountIndex.current
        const newAccount = { key: key, ...values }
        setAccounts([...accounts, newAccount])
    }

    const createdModalItems = () => {
        return (
            <>
                <ProFormText width="100%" name="account" label="アカウント" rules={[
                    {
                        required: true,
                        message: 'アカウントを入力ください。',
                    },
                ]}
                />
                <ProFormText width="100%" name="password" label="パスワード" rules={[
                    {
                        required: true,
                        message: 'パスワードを入力ください。',
                    },
                ]}
                />
                <ProFormText width="100%" name="phone" label="携帯電話" rules={[
                    {
                        required: false,
                        message: '携帯電話を入力ください。',
                    },
                ]}
                />
                <ProFormTextArea width="100%" name="headers" label="ヘッダー" rules={[
                    {
                        required: true,
                        message: 'ヘッダーを入力ください。',
                    },
                ]}
                />
                <ProFormTextArea width="100%" name="cookies" label="クッキー" rules={[
                    {
                        required: true,
                        message: 'クッキーを入力ください。',
                    },
                ]}
                />
                <ProFormTextArea width="100%" name="remarks" label="備考" rules={[
                    {
                        required: false,
                        message: '備考を入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    const getEditAccountFieldsValue = (record, index) => {
        const editInfo = {}
        editInfo["account" + record["key"]] = record["account"]
        editInfo["password" + record["key"]] = record["password"]
        editInfo["phone" + record["key"]] = record["phone"]
        editInfo["headers" + record["key"]] = record["headers"]
        editInfo["cookies" + record["key"]] = record["cookies"]
        editInfo["remarks" + record["key"]] = record["remarks"]
        return editInfo
    }

    return (
        <Form
            onFinish={onCreateSite}
            onFinishFailed={onCreateSiteFailed}
            ref={formRef}
        >
            <div className="ec-site-detail-container">
                <Row justify="start" className="row">
                    <Col span={4}>サイト名</Col>
                    <Col span={8}>
                        <Form.Item noStyle={true} name="siteName" rules={[
                            {
                                required: true,
                                message: "サイトを入力ください。",
                            },
                        ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row justify="start" className="row">
                    <Col span={4}>URL</Col>
                    <Col span={8}>
                        <Form.Item noStyle={true} name="siteURL" rules={[
                            {
                                required: true,
                                message: "URLを入力ください。",
                            },
                        ]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>国・地区</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="region" rules={[
                            {
                                required: true,
                                message: "国・地区を入力ください。",
                            },
                        ]}>
                            <Select style={{ width: 250 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                {CountryConfig.map(country => {
                                    return (
                                        <Select.Option key={`${country.name.ja}${country.name.en}`} value={country.code}>
                                            {country.name.ja}
                                        </Select.Option>
                                    )
                                })}

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>採用カテゴル</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="categoryId" default="" rules={[
                            {
                                required: true,
                                message: "採用カテゴルを入力ください。",
                            },
                        ]}>
                            <Select style={{ width: 250 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                {categories.map((category) => {
                                    return <Select.Option value={category.id}>{category.name}</Select.Option>
                                })}

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>スクレイピング可能</Col>
                    <Col span={4}>
                        <Form.Item noStyle={true} name="hasCrawler" default="" rules={[
                            {
                                required: false,
                                message: "スクレイピング可能を入力ください。",
                            },
                        ]}>
                            <Select style={{ width: 250 }} showSearch
                                filterOption={(inputValue, option) => {
                                    return option.key.indexOf(inputValue) > -1 ? true : false;
                                }}>
                                <Select.Option key="kano" value={1}>
                                    {"可能"}
                                </Select.Option>
                                <Select.Option key="hukano" value={0}>
                                    {"不可能"}
                                </Select.Option>

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={4}>備考</Col>
                    <Col span={8}>
                        <Form.Item noStyle={true} name="remarks" default="" rules={[
                            {
                                max: 1000
                            },
                        ]}>
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={24}>
                        <EFCMasterTable
                            modalTitle="URL追加"
                            rowkey="id"
                            columns={createAccountColumns()}
                            dataSource={accounts}
                            getEditFieldsValue={getEditAccountFieldsValue}
                            modalItems={createdModalItems()}
                            onRemoveClick={onRemoveAccountClick}
                            onSaveClick={onUpdateAccountClick}
                            onModalFinished={onCreateAccountClick}
                        >
                        </EFCMasterTable>
                    </Col>
                </Row>
                <Row justify="start" className="row">
                    <Col span={24}><Button htmlType="submit">新規</Button></Col>
                </Row>
            </div>
        </Form>
    )
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
    };
};

export default connect(mapStateToProps)(injectIntl(EFECSiteCreateView));
