// dashboard intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    'dashboard.title':{
        id: "app.ui.dashboard.title",
        defaultMessage: "Dashboard",

    },

    'overall_categorization_status.title': {
        id: "app.ui.dashboard.chart.overall_categorization_status.title",
        defaultMessage: "Overall Categorization Status",
    },

    'overall_categorization_status.legend.categorized': {
        id: "app.ui.dashboard.chart.overall_categorization_status.legend.categorized",
        defaultMessage: "Categorized",
    },
    'overall_categorization_status.legend.infringment': {
        id: "app.ui.dashboard.chart.overall_categorization_status.legend.infringment",
        defaultMessage: "Infringement",
    },
    'overall_categorization_status.legend.infringment_percent': {
        id: "app.ui.dashboard.chart.overall_categorization_status.legend.infringment_percent",
        defaultMessage: "Infringement%",
    },

    'overall_categorization_status.left_y_axis_name': {
        id: "app.ui.dashboard.chart.overall_categorization_status.left_y_axis_name",
        defaultMessage: "Count",
    },

    'overall_categorization_status.right_y_axis_name': {
        id: "app.ui.dashboard.chart.overall_categorization_status.right_y_axis_name",
        defaultMessage: "%",
    },

    'overall_infringement_type.title': {
        id: "app.ui.dashboard.chart.overall_infringement_type.title",
        defaultMessage: "Overall Infringement Type",
    },

    'map.overall_infringement_country.title': {
        id: "app.ui.dashboard.map.overall_infringement_country.title",
        defaultMessage: "Overall Infringement By Locale",
    },

    'chart.overall_infringement_country.title': {
        id: "app.ui.dashboard.chart.overall_infringement_country.title",
        defaultMessage: "Overall Infringement By Locale",
    },
    'table.categorization_status.title': {
        id: "app.ui.dashboard.table.categorization_status.title",
        defaultMessage: "Categorization Status",
    },
    'chart.locale_filtering.title': {
        id: "app.ui.dashboard.chart.locale_filtering.title",
        defaultMessage: "Locale Filtering",
    },
    'filter.locale_filtering.title': {
        id: "app.ui.dashboard.filter.locale_filtering.title",
        defaultMessage: "Locale",
    },
    'ec_infringement_categorization.title': {
        id: "app.ui.dashboard.chart.ec_infringement_categorization.title",
        defaultMessage: "EC Infringement Categorization",
    },

    'list.top_infringers.title': {
        id: "app.ui.dashboard.list.top_infringers.title",
        defaultMessage: "Top Infringers",
    },

    'chart.takedown.title': {
        id: "app.ui.dashboard.chart.takedown.title",
        defaultMessage: "Takedown",
    },

    'chart.ws_takedown.title': {
        id: "app.ui.dashboard.chart.ws_takedown.title",
        defaultMessage: "Takedown",
    },

    'chart.ws_category.title': {
        id: "app.ui.dashboard.chart.ws_category.title",
        defaultMessage: "Takedown",
    },

})

export default messages