import { defineMessages } from 'react-intl'

const messages = defineMessages({
    "message_success": {
        id: "app.ui.ec_detail.message.info.update_success",
        defaultMessage: "Update successful.",
    },
    "message_failed": {
        id: "app.ui.ec_detail.message.error.update_failed",
        defaultMessage: "Failed to update.",
    },
    "other_setting.impact_func": {
        id: "app.ui.data_setting.other_setting.impact_func",
        defaultMessage: "Impact Amount：A＊B＊C",
    },

    "other_setting.price_factor": {
        id: "app.ui.data_setting.other_setting.price_factor",
        defaultMessage: "Factor(A)：",
    },

    "other_setting.displayed_price": {
        id: "app.ui.data_setting.other_setting.displayed_price",
        defaultMessage: "Displayed Price0",
    },

    "other_setting.flat_price": {
        id: "app.ui.data_setting.other_setting.flat_price",
        defaultMessage: "Flat Price",
    },

    "other_setting.count_factor": {
        id: "app.ui.data_setting.other_setting.count_factor",
        defaultMessage: "Factor(B)：",
    },

    "other_setting.inventory": {
        id: "app.ui.data_setting.other_setting.inventory",
        defaultMessage: "Inventory",
    },

    "other_setting.sold_count": {
        id: "app.ui.data_setting.other_setting.sold_count",
        defaultMessage: "Sold Count",
    },

    "other_setting.flat_count": {
        id: "app.ui.data_setting.other_setting.flat_count",
        defaultMessage: "Flat Count",
    },

    "other_setting.percent_factor": {
        id: "app.ui.data_setting.other_setting.percent_factor",
        defaultMessage: "Factor(C)：",
    },
})

export default messages
