import "./index.less"

import React, { Component } from "react";
import { Button, message } from "antd";
import { reqUpdateUser } from '@/api/admin-user'
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import { isValidPassword } from "../../../utils/ef-re";

class EFAdminResetPassword extends Component {

    constructor(props) {
        super(props)
        this.modalFormRef = React.createRef();
        this.userId = this.props.userId;
        this.state = {
            isloading: false
        }
    }

    onFinish = (values) => {
        const { password, password2 } = values;
        if (!isValidPassword(password)) {
            message.error("パスワードは数字/大英文字/小英文字/記号をそれぞれ1つ以上使用し、8文字以上で設定して下さい。")
            return
        }
        if (password !== password2) {
            message.error("新パスワードと新パスワード（確認）は同じではなく、確認してください。")
            return
        }
        let body = {
            userId: this.userId,
            password,
            password2,
        }
        this.setState({ isloading: true })
        reqUpdateUser(body).then((response) => {
            let { data } = response
            if (data.code === 0) {
                this.setState({ resetCode: true, isloading: false })
                message.success("パスワードをリセットしました。")
            }

        }).catch((error) => {
            this.setState({ resetCode: true, isloading: false })
            message.error("パスワードのリセットを失敗しました。")
        })
        return true
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        message.error(errors[0])
    }

    render() {
        return (
            <ModalForm
                formRef={this.modalFormRef}
                title={this.props.modalTitle}
                trigger={
                    <Button type="primary">パスワードリセット</Button>
                }
                modalProps={{
                    onCancel: () => {
                        this.modalFormRef.current?.resetFields();
                    },
                }}
                onFinish={this.onFinish}
            >
                <ProFormText.Password label="新パスワード" name="password" rules={[
                    {
                        required: true,
                        message: '新パスワードを入力ください。',
                    }
                ]}
                />
                <ProFormText.Password label="新パスワード（確認）" name="password2" rules={[
                    {
                        required: true,
                        message: '新パスワード（確認）を入力ください。',
                    }
                ]}
                />
            </ModalForm >
        );
    }
}

export default EFAdminResetPassword