import "./index.less"
import EFSimpleContainer from "@/components/ef-simple-container"
import store from "@/store";
import React, { Component } from "react";
import { Form, Input, Button, Row, Col, Select, message, Modal } from "antd";
import { connect } from "react-redux";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js";
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqGetUser, reqUpdateUser, reqRemoveUser } from '../../../api/admin-user'
import EFAdminResetPassword from "../reset-password"
import moment from 'moment';
import { isValidTelNo } from "../../../utils/ef-re";

const { Option } = Select;

class ManagerDetail extends Component {

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.analystId = parseInt(this.props.location.pathname.split("/")[3]);
        this.state = {
            analyst: {},
            isloading: false,
            isModalLoading: false,
            isModalsho: false,
        }
        reqGetUser({ userId: this.analystId, roles: [4, 2] }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                if (data.data.user) {
                    this.setFormFields(data.data.user)
                    this.setState({ analyst: data.data.user })
                }
            } else {

            }
        }).catch((error) => {

        })
    }

    componentDidMount = () => {
        

        store.dispatch(setMenuActiveKey("manage-analyst-list"));
        store.dispatch(setContentContainerHeaderAction([
            {
                name: "削除",
                onClick: () => {
                    reqRemoveUser({
                        userId: this.analystId
                    }).then((response) => {
                        this.props.history.push("/manage/analyst")
                    }).catch((e) => [

                    ])
                }
            },
            {
                item:<EFAdminResetPassword userId={this.analystId}/>
            }
        ]));

        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "管理者登録一覧",
                path: "/manage/analyst",
            },
            {
                name: "詳細",
            }
        ]));
    }

    setFormFields = (userInfo) => {
        let roles = ["4", "2"].filter((role) => {
            return parseInt(role) & userInfo.user_role;
        })

        this.formRef.current.setFieldsValue({
            name: userInfo.user_name,
            tel: userInfo.user_tel,
            remarks: userInfo.user_remarks,
            role: roles
        });
    }
    // form
    onFinish = (values) => {
        const { name, tel, remarks, role } = values;
        if (tel && !isValidTelNo(tel)) {
            message.error("正しい電話番号を入力してください。")
            return
        }

        let body = {
            userId: this.analystId,
            name,
            tel,
            remarks,
        }
        if (role) {
            let newRole = role.reduce((preRole, role) => {
                return parseInt(preRole) + parseInt(role);
            }, 0)
            body.role = parseInt(newRole);
        }
        this.setState({ isloading: true })
        reqUpdateUser(body).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                message.success("情報を更新しました。")
            }
            this.setState({ isloading: false })
        }).catch((error) => {
            this.setState({ isloading: false })
            message.error("情報の更新は失敗しました。")
        })
    };

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        this.setState({ error: errors[0] })
        message.error(errors[0])
    }
    render() {
        document.title = "アナリスト詳細"
        return (
            <div className="manager-detail-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <Row>
                        <Col span={12}>
                            <EFSimpleContainer title="基本情報">
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>ID</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.analyst.user_id}</p>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>名前（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="name" rules={[
                                            {
                                                type: "string",
                                                max: 30,
                                                required: true,
                                                message: "名前を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>メール</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.analyst.user_mail}</p>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>ロール</p>
                                        <p>（必須）</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="role" rules={[
                                            {
                                                required: true,
                                                message: "ロールを選んでください。",
                                            },
                                        ]}>
                                            <Select mode="multiple" showArrow={true} style={{ minWidth: 200 }}>
                                                <Option value="4">管理者</Option>
                                                <Option value="2">アナリスト</Option>
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>電話番号</p>
                                    </div>
                                    <div className="item-content-container">
                                        <Form.Item noStyle={true} name="tel" rules={[
                                            {
                                                type: "string",
                                                message: "正しい電話番号を入力ください。",
                                            },
                                        ]}>
                                            <Input className="input-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>備考</p>
                                    </div>
                                    <div className="item-content-container">

                                        <Form.Item noStyle={true} name="remarks" rules={[
                                            {
                                                type: "string",
                                                max: 500,
                                                message: "500字以内の備考を入力ください。",
                                            },
                                        ]}>
                                            <Input.TextArea className="remarks-text" />
                                        </Form.Item>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </EFSimpleContainer>
                        </Col>
                        <Col span={12}>
                            <EFSimpleContainer title="登録情報">
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>最終ログイン日</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p>{this.state.analyst.user_last_used_date ? moment(this.state.analyst.user_last_used_date).format("YYYY-MM-DD") : ""}</p>
                                    </div>
                                    <br />
                                </div>
                                <div className="item-wrapper">
                                    <div className="item-label-container">
                                        <p>新規日付</p>
                                    </div>
                                    <div className="item-content-container">
                                        <p >{this.state.analyst.user_create_at ? moment(this.state.analyst.user_create_at).format("YYYY-MM-DD") : ""}</p>
                                    </div>
                                    <br />
                                </div>
                            </EFSimpleContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div className="acition-container">
                                <Button className="update-button" htmlType="submit" type="primary" loading={this.state.isloading}>更新</Button>
                            </div>
                        </Col>
                    </Row>
                </Form>

                <Modal
                    title="Title"
                    visible={this.state.isModalVisible}
                    onOk={ ()=>{} }
                    confirmLoading={this.state.isModalLoading}
                    onCancel={ ()=>{} }
                >

                </Modal>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.auth,
    }
}

export default connect(mapStateToProps)(ManagerDetail)
