export const TakedownResult = [
    {
        text: "審査中",
        name: {
            en: "In review",
            ja: "審査中"
        },

        value: 1,
    },
    {
        text: "完了",
        name: {
            en: "Takedown",
            ja: "完了"
        },
        value: 2,

    },
    {
        text: "対応なし",
        name: {
            en: "No action",
            ja: "対応なし"
        },
        value: 3,
    },
];

export const TakedownRequest = [
    {
        text: "Takedown",
        name: {
            en: "Takedown",
            ja: "申告実施"
        },
        value: 1,
    },
    {
        text: "Deleted",
        name: {
            en: "Deleted",
            ja: "販売終了"
        },
        value: 2,
    },
    {
        text: "Pending",
        name: {
            en: "Pending",
            ja: "保留"
        },
        value: 3,
    },
    {
        text: "No Action",
        name: {
            en: "No Action",
            ja: "措置不要"
        },
        value: 4,
    },
];
// ブラックリスト対象 ホワイトリスト対象
export const TrueOrFalse = [
    {
        text: "はい",
        value: 1,
    },
    {
        text: "いいえ",
        value: 2,
    },
]

// 簡易判断
export const NeedCheck = [
    {
        text: "",
        value: 0,
    },
    {
        text: "チェック必要",
        value: 1,
    },
]
