import React, { Component } from "react";
import EFCSearchBar from "@/components/ef-c-searchbar";
import { connect } from "react-redux";
import { Table, Space, Button } from "antd";
import store from "@/store";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqSearchTrademarks, reqRemoveTrademark } from "@/api/trademark.js"
import { reqFetchClients } from '@/api/analyst-client'
import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import { CountryConfig } from "@/config/country.js"
import { ProductTrademarkType } from "@/config/product-type.js"

import moment from 'moment';
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "../messages"
import common_messages from "../../../locales/messages";
import { getCountryConfigDict } from "../../../config/country";

class TrademarkList extends Component {
    constructor(props) {
        super(props)
        if (this.props.role & 1) {
            this.conditions = { clientId: this.props.companyInfo ? this.props.companyInfo.client_id : undefined}
        } else {
            this.conditions = {}
            this.setState({ clients: [] });
            reqFetchClients().then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({ clients: data.data.client });
                }
            }).catch((error) => {

            })
        }
        this.searchWithConditions(this.conditions);

        this.state = {
            dataSource: []
        }
    }

    getKeywordSearchItems = () => {
        return [
            // {
            //     name: "製品ジャンル",
            //     value: "trademark_type"
            // },
            {
                name: this.props.intl.formatMessage(messages["table.trademark_word"]),

                value: "trademark_text"
            },
            {
                name: this.props.intl.formatMessage(messages["table.trademark_right_holder"]),
                value: "trademark_belongsto"
            },
            {
                name: this.props.intl.formatMessage(messages["table.description_ja"]),
                value: "trademark_description_ja"
            },
            {
                name: this.props.intl.formatMessage(messages["table.description_en"]),
                value: "trademark_description_en"
            },
            {
                name: this.props.intl.formatMessage(messages["table.description_cn"]),
                value: "trademark_description_cn"
            },
        ];
    }

    getDateSearchItems = () => {
        return [
            {
                name: this.props.intl.formatMessage(messages["table.trademark_registration_date"]),
                value: "trademark_register_date"
            },
            {
                name: this.props.intl.formatMessage(messages["table.trademark_expire_date"]),
                value: "trademark_invalid_date"
            },
        ]
    }

    getClientFilters = () => {
        if (this.state.clients) {
            let clientFilters = []
            let clientDict = {}
            this.state.clients.every((client) => {
                if (!clientDict[client.client_id]) {
                    clientDict[client.client_id] = client;
                    clientFilters.push({ text: client.client_name, value: client.client_id })
                }
                return true
            })
            return clientFilters;
        } else {
            return []
        }
    }

    getRegionFilters = () => {
        if (this.state.trademarks) {
            let regionFilters = []
            let regionDict = {}
            let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
            let localeDict = getCountryConfigDict()
            this.state.trademarks.every((trademark) => {
                if (!regionDict[trademark.trademark_region]) {
                    regionDict[trademark.trademark_region] = trademark;
                    let localeInfo = localeDict[trademark.trademark_region]
                    let region = localeInfo ? localeInfo.name[localeKey] : trademark.trademark_region
                    regionFilters.push({
                        text: region,
                        value: trademark.trademark_region
                    })
                }
                return true
            })
            return regionFilters;
        } else {
            return []
        }
    }

    getProductTypeFilters = () => {
        let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
        return ProductTrademarkType.map(trademark => {
            return { text: trademark.name[localeKey], value: trademark.value };
        })
    }

    getColumns = () => {
        let columns = [
            {
                title: <FormattedMessage id="app.ui.trademark.table.id" />,
                dataIndex: 'trademark_id',
                key: 'trademark_id',
                fixed: true,
                width: 80,
                sorter: (a, b) => compareNumber(a.trademark_id, b.trademark_id),
                showSorterTooltip: false,
            },
        ]
        if (this.props.role & 1) {

        } else {
            columns.push({
                title: <FormattedMessage id="app.ui.trademark.table.client" />,
                dataIndex: 'trademark_client_id',
                key: 'trademark_client_id',
                render: (text, record) => {
                    if (this.state.clients && this.state.clients.length > 0) {
                        let client = this.state.clients.find((client) => {
                            return client.client_id === record.trademark_client_id
                        })
                        if (client) {
                            return client.client_name;
                        } else {
                            return record.trademark_client_id;
                        }
                    } else {
                        return record.trademark_client_id;
                    }
                },
                sorter: (a, b) => compareNumber(a.trademark_client_id, b.trademark_client_id),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.trademark_client_id) {
                        return value === record.trademark_client_id
                    } else {
                        return value === 0
                    }
                },
                filters: this.getClientFilters(),
                filterMultiple: true,
            })
        }

        let elseColumns = [
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_no" />,
                dataIndex: 'trademark_no',
                key: 'trademark_no',
                sorter: (a, b) => compareString(a.trademark_no, b.trademark_no),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_region" />,
                dataIndex: 'trademark_region',
                key: 'trademark_region',
                sorter: (a, b) => compareString(a.trademark_region, b.trademark_region),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.trademark_region) {
                        return value === record.trademark_region
                    } else {
                        return value === 0
                    }
                },
                filters: this.getRegionFilters(),
                filterMultiple: true,
                render: (text, record) => {
                    let info = CountryConfig.find(country => {
                        return country.code === text;
                    })
                    let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                    return info ? info.name[localeKey] : ""
                },
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_product_genre" />,
                dataIndex: 'trademark_type',
                key: 'trademark_type',
                sorter: (a, b) => compareString(a.trademark_type, b.trademark_type),
                showSorterTooltip: false,
                render: (text, record) => {
                    let info = ProductTrademarkType.find(trademark => {
                        return trademark.value === text;
                    })
                    let localeKey = this.props.intl.formatMessage(common_messages["locale.name_locale_key"])
                    return info ? info.name[localeKey] : ""
                },
                filters: this.getProductTypeFilters(),
                filterMultiple: true,
                onFilter: (value, record) => {
                    if (record.trademark_type) {
                        return value === record.trademark_type
                    } else {
                        return value === 0
                    }
                },
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_word" />,
                dataIndex: 'trademark_text',
                key: 'trademark_text',
                sorter: (a, b) => compareString(a.trademark_text, b.trademark_text),
                showSorterTooltip: false,
            },

            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_registration_date" />,
                dataIndex: 'trademark_register_date',
                key: 'trademark_register_date',
                render: (text, record) => {
                    if (record.trademark_register_date) {
                        return moment(record.trademark_register_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.trademark_register_date) {
                        if (b.trademark_register_date) {
                            return compareDate(a.trademark_register_date, b.trademark_register_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.trademark_register_date) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_expire_date" />,
                dataIndex: 'trademark_invalid_date',
                key: 'trademark_invalid_date',
                render: (text, record) => {
                    if (record.trademark_invalid_date) {
                        return moment(record.trademark_invalid_date).format("YYYY-MM-DD")
                    }
                    return ""
                },
                sorter: (a, b) => {
                    if (a.trademark_invalid_date) {
                        if (b.trademark_invalid_date) {
                            return compareDate(a.trademark_invalid_date, b.trademark_invalid_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.trademark_invalid_date) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.remind_mail_flg" />,
                dataIndex: 'trademark_remind_flag',
                key: 'trademark_remind_flag',
                render: (text, record) => {
                    if (record.trademark_remind_flag) {
                        return <FormattedMessage id="app.common.label.yes" />;
                    } else {
                        return <FormattedMessage id="app.common.label.no" />;
                    }
                },
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_right_holder" />,
                dataIndex: 'trademark_belongsto',
                key: 'trademark_belongsto',
                sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.description_ja" />,
                dataIndex: 'trademark_description_ja',
                key: 'trademark_description_ja',
                width: 400,
                sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.description_en" />,
                dataIndex: 'trademark_description_en',
                key: 'trademark_description_en',
                width: 400,
                sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.description_cn" />,
                dataIndex: 'trademark_description_cn',
                key: 'trademark_description_cn',
                width: 400,
                sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_registration_status" />,
                dataIndex: 'trademark_status',
                key: 'trademark_status',
                render: (text, record) => {
                    if (record.trademark_status === 1) {
                        return <FormattedMessage id="app.trademark_registration_status.register" />
                    } else if (record.trademark_status === 2) {
                        return <FormattedMessage id="app.trademark_registration_status.applying" />
                    } else if (record.trademark_status === 3) {
                        return <FormattedMessage id="app.trademark_registration_status.rejection" />
                    }
                    return ""
                },
                sorter: (a, b) => compareNumber(a.trademark_status, b.trademark_status),
                showSorterTooltip: false,
                onFilter: (value, record) => {
                    if (record.trademark_status) {
                        return value === record.trademark_status
                    } else {
                        return value === 0
                    }
                },
                filters: [
                    {
                        text: <FormattedMessage id="app.common.label.blank" />,
                        value: 0
                    },
                    {
                        text: <FormattedMessage id="app.trademark_registration_status.register" />,
                        value: 1
                    },
                    {
                        text: <FormattedMessage id="app.trademark_registration_status.applying" />,
                        value: 2
                    },
                    {
                        text: <FormattedMessage id="app.trademark_registration_status.rejection" />,
                        value: 3
                    },
                ],
                filterMultiple: true,
            },
            {
                title: <FormattedMessage id="app.ui.trademark.table.trademark_remarks" />,
                dataIndex: 'trademark_remarks',
                key: 'trademark_remarks',
                width: 400,
                sorter: (a, b) => compareString(a.trademark_belongsto, b.trademark_belongsto),
                showSorterTooltip: false,
            },
            {
                title: <FormattedMessage id="app.common.action" />,
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Button type="link" href={"/trademark/" + record.trademark_id}>
                            <FormattedMessage id="app.common.action.detail" />
                        </Button>
                        <Button type="link" onClick={() => { this.handleDelete(record) }}>
                            <FormattedMessage id="app.common.action.delete" />
                        </Button>
                    </Space>
                ),
                fixed: "right",
                width: 150
            },
        ];
        return columns.concat(elseColumns);
    }

    searchWithConditions(conditions) {
        reqSearchTrademarks(this.conditions).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                // data.data.trademarks.map((trademark) => {
                //     this.keywordDict[trademark.trademark_id] = brand.keywords;
                //     this.modelDict[brand.brand_id] = brand.models
                //     return brand;
                // });
                this.setState({ trademarks: data.data.trademarks });
            }
        }).catch((error) => {

        })

    }

    componentDidMount() {

        store.dispatch(setMenuActiveKey("trademark"));
        if (this.props.role & 1) {
            store.dispatch(setContentContainerHeaderAction([
                {
                    name: <FormattedMessage id="app.ui.trademark.button.to_registration" />,
                    path: "/trademark/new",
                },
                {
                    name: <FormattedMessage id="app.ui.trademark.button.to_request_trademark" />,
                    path: "/trademark/registration",
                }
            ]));
        } else {
            store.dispatch(setContentContainerHeaderAction([
                {
                    name: <FormattedMessage id="app.ui.trademark.button.to_registration" />,
                    path: "/trademark/new",
                }
            ]));
        }
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.layout.menu.legal_services.tm_registration" />,
                path: "/trademark",
            },
        ]));
    }

    handleDelete = (record) => {
        reqRemoveTrademark({
            "trademarkId": record.trademark_id
        }).then((response) => {
            const { data } = response;



            if (data.code === 0) {
                this.searchWithConditions(this.conditions)
            }
        }).catch((error) => {

        })
    }
    handleSearch = (conditions) => {
        if (conditions) {
            this.conditions = { ...this.conditions, ...conditions }
        }
        this.searchWithConditions(this.conditions)
    }

    handleReset = () => {
        if (this.props.role & 1) {
            this.conditions = { clientId: this.props.companyInfo ? this.props.companyInfo.client_id : undefined }
        } else {
            this.conditions = {}
        }
    }

    render() {
        document.title = this.props.intl.formatMessage(messages["registration_list_title"])
        return (
            <div className="client-list-container">
                <div>
                    <EFCSearchBar
                        onSearch={this.handleSearch}
                        onReset={this.handleReset}
                        dateItems={this.getDateSearchItems()}
                        keywordItems={this.getKeywordSearchItems()} />
                </div>
                <div className="result-table">
                    <Table
                        columns={this.getColumns()}
                        dataSource={this.state.trademarks}
                        size="small"
                        pagination={false}
                        scroll={{
                            x: 'max-content',
                        }}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
    };
};

export default connect(mapStateToProps)(injectIntl(TrademarkList));
