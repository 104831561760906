import React, { Component } from 'react'
import { Breadcrumb, Button, Divider, Space } from "antd";
import "./index.less"
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";


class EFCBreadcrumb extends Component {
    render() {
        return (
            <div className="breadContainer">
                <div className="breadHeader"></div>
                <Breadcrumb className="bread" separator=">">
                    {
                        this.props.breadcrumbs.map((breadcrumb) => {
                            return <Breadcrumb.Item key={breadcrumb.path ? breadcrumb.path : "empty"}><Link to={breadcrumb.path ? breadcrumb.path : ""}>{breadcrumb.name}</Link></Breadcrumb.Item>
                        })
                    }
                </Breadcrumb>
                <Space className="actionContainer">
                    {
                        this.props.actions.map((action) => {
                            if (action.item) {
                                return action.item
                            } else {
                                let linkItem = action.path ? (<Link to={action.path ? action.path : ""}>{action.name}</Link>) : action.name

                                return <Button key={action.name ? action.name : "empty"} onClick={action.onClick} type="primary" className="breadcrumb-button">
                                    {/* {action.name} */}
                                    {linkItem}
                                </Button>
                            }
                        })
                    }
                </Space>
                <Divider className="divider" />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        ...state.contentContainerHeader,
    }
}

export default connect(mapStateToProps)(withRouter(EFCBreadcrumb))
