import moment from 'moment';
import { Spin, Space, Button, Image } from "antd";
import { reqGetECFilters } from "@/api/ec.js"
import { TakedownRequest, TakedownResult } from '../../../config/ec-report-select-items'
import { InfringementCommonCategories } from '../../../config/infringement-common-category'
import Highlighter from 'react-highlight-words';
import EFCFilterView from "../../../components/ef-c-tablefilter";
import { ECColumnInfo } from '../../../config/column';
import { FormattedMessage } from 'react-intl';
import common_messages from '../../../locales/messages';
import { CountryConfig, getCountryConfigDict } from '../../../config/country';
import messages from './ec-list-messages';

const getTableColumnFilters = (dataIndex, key, getDatasource) => {
    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return <EFCFilterView
                setSelectedKeys={setSelectedKeys}
                selectedKeys={selectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                getDatasource={getDatasource}
                key={key}
            />
        },
    }
}

const wrapperRenderResult = (result) => {
    return (
        <div className='cell-wapper'>
            {result}
        </div>
    )
}

const renderSearchResultHighLight = (value, record, dataIndex, searchWords, searchColumns) => {
    let content = searchColumns && searchColumns.some(column => column === dataIndex) ? (
        <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={searchWords}
            autoEscape
            textToHighlight={value ? value.toString() : ''}
        />
    ) : (
        value
    )
    return wrapperRenderResult(content)
}

const getBasicColumns = (config) => {

    let { currentSearchClientId, allReportsInfo,
        currentSearchConditions, allProductsInfo,
        categorysInfo, allTrademarksInfo, currentSelectClientId,
        onDetailClick, onHistoryClick, searchWords, searchColumns, allSearchableCoulumns, intl } = config;
    let columns = [
        {
            // title: getTitleBySafe(ECColumnInfo.ec_id.name, intl),
            title:
                <span onClick={e => e.stopPropagation()}>
                    <FormattedMessage id="app.ui.eclist.table.id" />
                </span>,
            dataIndex: ECColumnInfo.ec_id.dataIndex,
            key: ECColumnInfo.ec_id.dataIndex,
            width: 80,
            fixed: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_image_url.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_image_url" />,
            defaultShow: true,
            dataIndex: ECColumnInfo.ec_product_image_url.dataIndex,//'ec_product_image_url',
            key: ECColumnInfo.ec_product_image_url.dataIndex,
            width: 150,
            render: (value, record) => {
                let image = <Image
                    width={40}
                    height={40}
                    src={value ? value : "error"}
                    preview={false}
                    fallback="no-image.png"
                />
                return wrapperRenderResult(image)
            },
        },
        // todo : no report id
        {
            // title: getTitleBySafe(ECColumnInfo.report_id, intl),//'レポート名',
            title: <FormattedMessage id="app.ui.eclist.table.report_id" />,
            dataIndex: 'report_id',
            key: 'report_id',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let reportsInfo = allReportsInfo["reportsInfo_" + currentSearchClientId];
                    if (reportsInfo) {
                        let info = reportsInfo.find((report) => {
                            return report.report_id === value;
                        })
                        let content = info ? info.report_name : value;
                        return wrapperRenderResult(content)
                        // return content;
                        // return (< Tooltip placement="topLeft" title={content} >
                        //     {content}
                        // </Tooltip >)
                    }
                }
                return wrapperRenderResult(value)
            },
            ...getTableColumnFilters('report_id', 'report_id' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    let reportsInfo = allReportsInfo["reportsInfo_" + currentSearchClientId];

                    if (reportsInfo) {
                        let whereFields = currentSearchConditions["whereFields"]
                        let filterInfo = whereFields ? whereFields.find(field => field.key === "report_id") : {}
                        let filters = reportsInfo.map((item) => {
                            let isChecked = filterInfo && filterInfo.values ? filterInfo.values.some((value) => { return value === item.report_id }) : false
                            return {
                                text: item.report_name,
                                value: item.report_id,
                                checked: isChecked
                            }
                        })
                        resolve({ filters })
                    }
                })
                return promise
            }),
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_no.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_no" />,
            dataIndex: ECColumnInfo.ec_no.dataIndex,
            key: ECColumnInfo.ec_no.dataIndex,
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_site.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_site" />,
            defaultShow: true,
            dataIndex: ECColumnInfo.ec_site.dataIndex,
            key: 'ec_site',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_site.dataIndex, ECColumnInfo.ec_site.dataIndex + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    reqGetECFilters({
                        clientId: currentSearchClientId,
                        "fieldName": ECColumnInfo.ec_site.dataIndex,
                    }).then((response) => {
                        const { data } = response;
                        if (data.code === 0) {
                            let whereFileds = currentSearchConditions["whereFields"]
                            let filterInfo = whereFileds ? whereFileds.find(filed => filed.key === ECColumnInfo.ec_site.dataIndex) : {}
                            let filters = data.data.filters.map((item) => {
                                let isChecked = filterInfo && filterInfo.values ? filterInfo.values.some((value) => { return value === item }) : false
                                return {
                                    text: item,
                                    value: item,
                                    checked: isChecked
                                }
                            })
                            resolve({ filters, total: data.data.total, offset: data.data.offset })
                        }
                    }).catch((e) => {

                    })
                })

                return promise
            }),
            render: (value, record) => {
                return wrapperRenderResult(value)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_scan_month.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_scan_month" />,
            dataIndex: ECColumnInfo.ec_scan_date.dataIndex,
            key: 'ec_scan_month',
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_scan_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_scan_date" />,
            dataIndex: ECColumnInfo.ec_scan_date.dataIndex,
            key: 'ec_scan_date',
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_search_keyword.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_search_keyword" />,
            defaultShow: true,
            dataIndex: ECColumnInfo.ec_search_keyword.dataIndex,
            key: 'ec_search_keyword',
            width: 150,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
            ...getTableColumnFilters(ECColumnInfo.ec_search_keyword.dataIndex, 'ec_search_keyword' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    reqGetECFilters({
                        clientId: currentSearchClientId,
                        "fieldName": ECColumnInfo.ec_search_keyword.dataIndex,
                    }).then((response) => {
                        const { data } = response;
                        if (data.code === 0) {
                            let filters = data.data.filters.map((item) => {
                                let isChecked = false;
                                return {
                                    text: item,
                                    value: item,
                                    checked: isChecked
                                }
                            })
                            resolve({ filters, total: data.data.total, offset: data.data.offset })
                        }
                    })
                })
                return promise
            }),
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_no.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_no" />,
            dataIndex: ECColumnInfo.ec_product_no.dataIndex,
            showSorterTooltip: false,
            key: 'ec_product_no',
            width: 150,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_title.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_title" />,
            defaultShow: true,
            dataIndex: ECColumnInfo.ec_product_title.dataIndex,
            key: 'ec_product_title',
            showSorterTooltip: false,
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_url.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_url" />,
            dataIndex: ECColumnInfo.ec_product_url.dataIndex,
            key: 'ec_product_url',
            width: 150,
            ellipsis: true,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_product_url.dataIndex, 'ec_product_url' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    reqGetECFilters({
                        clientId: currentSearchClientId,
                        "fieldName": ECColumnInfo.ec_product_url.dataIndex,
                    }).then((response) => {
                        const { data } = response;
                        if (data.code === 0) {
                            let filters = data.data.filters.map((item) => {
                                let isChecked = false;
                                return {
                                    text: item,
                                    value: item,
                                    checked: isChecked
                                }
                            })
                            resolve({ filters, total: data.data.total, offset: data.data.offset })
                        }
                    })
                })
                return promise
            }),
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_brand.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_brand" />,
            dataIndex: ECColumnInfo.ec_product_brand.dataIndex,
            key: 'ec_product_brand',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_seller.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_seller" />,
            dataIndex: ECColumnInfo.ec_product_seller.dataIndex,
            key: 'ec_product_seller',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_seller_url.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_seller_url" />,
            dataIndex: ECColumnInfo.ec_product_seller_url.dataIndex,
            key: 'ec_product_seller_url',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_price.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_price" />,
            dataIndex: ECColumnInfo.ec_product_price.dataIndex,
            key: 'ec_product_price',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        // {
        //     title: '最小単価',
        //     dataIndex: 'ec_product_min_price',
        //     key: 'ec_product_min_price',
        //     showSorterTooltip: false,
        //     sorter: true,
        // },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_currency.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_currency" />,
            dataIndex: ECColumnInfo.ec_product_currency.dataIndex,
            key: 'ec_product_currency',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },

        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_onsell_count.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_onsell_count" />,
            dataIndex: ECColumnInfo.ec_product_onsell_count.dataIndex,
            key: 'ec_product_onsell_count',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_sold_count.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_sold_count" />,
            dataIndex: ECColumnInfo.ec_product_sold_count.dataIndex,
            key: 'ec_product_sold_count',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_region.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_region" />,
            dataIndex: ECColumnInfo.ec_product_region.dataIndex,
            key: 'ec_product_region',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_product_region.dataIndex, 'ec_product_region' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    reqGetECFilters({
                        clientId: currentSearchClientId,
                        "fieldName": ECColumnInfo.ec_product_region.dataIndex,
                    }).then((response) => {
                        const { data } = response;
                        if (data.code === 0) {
                            let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                            let countryDict = getCountryConfigDict()
                            let whereFileds = currentSearchConditions["whereFields"]
                            let filterInfo = whereFileds ? whereFileds.find(filed => filed.key === ECColumnInfo.ec_product_region.dataIndex) : {}
                            let filters = data.data.filters.map((item) => {
                                let isChecked = filterInfo && filterInfo.values ? filterInfo.values.some((value) => { return value === item }) : false
                                let text = intl.formatMessage(messages["label.blank"])
                                if (countryDict[item] && countryDict[item].name) {
                                    text = countryDict[item].name[localeKey]
                                }
                                return {
                                    key: item,
                                    text: text,
                                    value: item,
                                    checked: isChecked
                                }
                            })
                            resolve({ filters, total: data.data.total, offset: data.data.offset })
                        }
                    })
                })
                return promise
            }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let countryDict = getCountryConfigDict()
                let text = value
                if (countryDict[value] && countryDict[value].name) {
                    text = countryDict[value].name[localeKey]
                }
                return wrapperRenderResult(text)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_city.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_city" />,
            dataIndex: ECColumnInfo.ec_product_city.dataIndex,
            key: 'ec_product_city',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_categorize_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_date" />,
            dataIndex: ECColumnInfo.ec_categorize_date.dataIndex,
            key: ECColumnInfo.ec_categorize_date.dataIndex,
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_blacklist_object.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_blacklist_object" />,
            dataIndex: ECColumnInfo.ec_blacklist_object.dataIndex,
            key: 'ec_blacklist_object',
            width: 200,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_blacklist_object.dataIndex, 'ec_blacklist_object' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    resolve({
                        filters: [
                            { text: intl.formatMessage(messages["flag.yes"]), value: 1 },
                            { text: intl.formatMessage(messages["flag.no"]), value: 0 },
                        ]
                    })
                })
                return promise
            }),
            render: (value, record) => {
                let content = value
                if (value) {
                    content = <FormattedMessage id="app.common.flag.yes" />
                } else {
                    content = <FormattedMessage id="app.common.flag.no" />
                }
                return wrapperRenderResult(content)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_whitelist_object.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_whitelist_object" />,
            dataIndex: ECColumnInfo.ec_whitelist_object.dataIndex,
            key: 'ec_whitelist_object',
            width: 200,
            ellipsis: false,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_whitelist_object.dataIndex, 'ec_whitelist_object' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    resolve({
                        filters: [
                            { text: intl.formatMessage(messages["flag.yes"]), value: 1 },
                            { text: intl.formatMessage(messages["flag.no"]), value: 0 },
                        ]
                    })
                })
                return promise
            }),
            render: (value, record) => {
                let content = value
                if (value) {
                    content = <FormattedMessage id="app.common.flag.yes" />
                } else {
                    content = <FormattedMessage id="app.common.flag.no" />
                }
                return wrapperRenderResult(content)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_is_need_to_check.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_is_need_to_check" />,
            defaultShow: true,
            dataIndex: ECColumnInfo.ec_is_need_to_check.dataIndex,
            key: 'ec_is_need_to_check',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_is_need_to_check.dataIndex, 'ec_is_need_to_check' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    resolve({
                        filters: [
                            { text: intl.formatMessage(messages["flag.yes"]), value: 1 },
                            { text: intl.formatMessage(messages["flag.no"]), value: 0 },
                        ]
                    })
                })
                return promise
            }),
            render: (value, record) => {
                let content = value
                if (value) {
                    content = <FormattedMessage id="app.common.flag.yes" />
                } else {
                    content = <FormattedMessage id="app.common.flag.no" />
                }
                return wrapperRenderResult(content)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_title_hint_word.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_title_hint_word" />,
            dataIndex: ECColumnInfo.ec_title_hint_word.dataIndex,
            key: 'ec_title_hint_word',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_brand_hint_word.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_brand_hint_word" />,
            dataIndex: ECColumnInfo.ec_brand_hint_word.dataIndex,
            key: 'ec_brand_hint_word',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_product_category_id_word.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_product_category_id_word" />,
            dataIndex: ECColumnInfo.ec_product_category_id_word.dataIndex,
            key: 'ec_product_category_id',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_product_category_id_word.dataIndex, 'ec_product_category_id' + currentSearchClientId, () => {
                // product_locale_key
                let productLocaleKey = intl.formatMessage(common_messages["locale.product_locale_key"])
                let promise = new Promise((resolve, reject) => {
                    let productsInfo = allProductsInfo["productsInfo_" + currentSearchClientId];
                    if (productsInfo) {
                        let filters = productsInfo.map((info) => {
                            return { text: info[productLocaleKey], value: info.id };
                        })
                        let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                        filters = blank.concat(filters)
                        resolve({ filters })
                    }
                })
                return promise
            }),
            render: (value, record) => {
                let productLocaleKey = intl.formatMessage(common_messages["locale.product_locale_key"])
                let productsInfo = allProductsInfo["productsInfo_" + currentSearchClientId];
                if (productsInfo) {
                    let info = productsInfo.find((info) => {
                        return info.id === value;
                    })
                    let content = info ? info[productLocaleKey] : "";
                    return wrapperRenderResult(content)
                } else {
                    return wrapperRenderResult(<Spin />)
                }
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_category_rule_id.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_rule_id" />,
            dataIndex: ECColumnInfo.ec_category_rule_id.dataIndex,
            key: 'ec_category_rule_id',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_category_rule_id.dataIndex, 'ec_category_rule_id' + currentSearchClientId, () => {
                let infringementLocaleKey = intl.formatMessage(common_messages["locale.infringement_locale_key"])

                let promise = new Promise((resolve, reject) => {
                    if (categorysInfo) {
                        let filters = categorysInfo.map((category) => {
                            return {
                                text: category[infringementLocaleKey],
                                value: category.id
                            }
                        })
                        let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                        filters = blank.concat(filters)
                        resolve({ filters })
                    }
                })
                return promise
            }),
            render: (value, record) => {
                if (categorysInfo) {
                    let infringementLocaleKey = intl.formatMessage(common_messages["locale.infringement_locale_key"])
                    let category = categorysInfo.find((category) => {
                        return category.id === value;
                    })
                    if (category) {
                        return wrapperRenderResult(category[infringementLocaleKey])
                    } else {
                        return wrapperRenderResult("")
                    }
                } else {
                    return wrapperRenderResult(<Spin />)
                }
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_category_id_word.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_id_word" />,
            dataIndex: ECColumnInfo.ec_category_id_word.dataIndex,
            key: 'ec_category_id',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_category_id_word.dataIndex, 'ec_category_id' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    if (categorysInfo) {
                        let subinfringementLocaleKey = intl.formatMessage(common_messages["locale.subinfringement_locale_key"])
                        let filters = categorysInfo.flatMap((category) => {
                            return category.subcategories.map((subcategory) => {
                                return {
                                    text: subcategory[subinfringementLocaleKey],
                                    value: subcategory.id
                                }
                            })
                        })
                        let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                        filters = blank.concat(filters)
                        resolve({ filters })
                    }
                })
                return promise
            }),
            render: (value, record) => {
                if (categorysInfo) {
                    let category = categorysInfo.find((category) => {
                        return category.id === record[ECColumnInfo.ec_category_rule_id.dataIndex];
                    })
                    if (category) {
                        let subcategory = category.subcategories.find((subcategory) => {
                            return subcategory.id === value;
                        })
                        if (subcategory) {
                            let subinfringementLocaleKey = intl.formatMessage(common_messages["locale.subinfringement_locale_key"])
                            return wrapperRenderResult(subcategory[subinfringementLocaleKey])
                        } else {
                            return wrapperRenderResult("")
                        }
                    } else {
                        return wrapperRenderResult("")
                    }
                } else {
                    return wrapperRenderResult(<Spin />)

                }
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_category_id_common.name, intl),//'集計',
            title: <FormattedMessage id="app.ui.eclist.table.ec_category_id_common" />,
            defaultShow: true,
            dataIndex: 'ec_category_common_type_id',
            key: 'ec_category_common_type_id',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters('ec_category_common_type_id', 'ec_category_common_type_id' + currentSearchClientId, () => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let promise = new Promise((resolve, reject) => {
                    let whereFileds = currentSearchConditions["whereFields"]
                    let filterInfo = whereFileds ? whereFileds.find(filed => filed.key === "ec_category_common_type_id") : {}
                    let filters = InfringementCommonCategories.map((commonCategory => {
                        let isChecked = filterInfo && filterInfo.values ? filterInfo.values.some((value) => { return value === commonCategory.id }) : false
                        return {
                            key: commonCategory.id,
                            text: commonCategory.name[localeKey],
                            value: commonCategory.id,
                            checked: isChecked
                        }
                    }))
                    let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                    filters = blank.concat(filters)
                    resolve({ filters })
                })
                return promise
            }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let commonCategory = InfringementCommonCategories.find((commonCategory) => {
                    return commonCategory.id === value;
                })
                let content = commonCategory ? commonCategory.name[localeKey] : ""
                return wrapperRenderResult(content)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_categorize_remarks.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_categorize_remarks" />,
            dataIndex: ECColumnInfo.ec_categorize_remarks.dataIndex,
            key: 'ec_categorize_remarks',
            showSorterTooltip: false,
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_response_action.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_action" />,
            defaultShow: true,
            dataIndex: ECColumnInfo.ec_response_action.dataIndex,
            key: 'ec_response_action',
            width: 250,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_response_action.dataIndex, 'ec_response_action' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                    let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                    let takedownRequestFilters = TakedownRequest.map((action) => {
                        return {
                            text: action.name[localeKey],
                            value: action.value
                        }
                    })
                    let filters = blank.concat(takedownRequestFilters)
                    resolve({ filters })
                })
                return promise
            }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = TakedownRequest.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_response_reason.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_reason" />,
            dataIndex: ECColumnInfo.ec_response_reason.dataIndex,
            key: 'ec_response_reason',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_response_trademark.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_trademark" />,
            dataIndex: ECColumnInfo.ec_response_trademark.dataIndex,
            key: 'ec_response_trademark',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_response_trademark.dataIndex, 'ec_response_trademark' + currentSearchClientId, () => {
                let promise = new Promise((resolve, reject) => {
                    let trademarksInfo = allTrademarksInfo["trademarksInfo_" + currentSelectClientId];
                    if (trademarksInfo) {
                        let filters = trademarksInfo.map((trademark) => {
                            return {
                                text: trademark.trademark_no,
                                value: trademark.trademark_id
                            }
                        })
                        let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                        filters = blank.concat(filters)
                        resolve({ filters })
                    }
                })
                return promise
            }),
            render: (value, record) => {
                let trademarksInfo = allTrademarksInfo["trademarksInfo_" + currentSelectClientId];
                if (trademarksInfo) {
                    let trademark = trademarksInfo.find((trademark) => {
                        return trademark.trademark_id === value
                    })
                    let content = trademark ? trademark.trademark_no : value;
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult(value)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_response_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_response_date" />,
            dataIndex: ECColumnInfo.ec_response_date.dataIndex,
            key: 'ec_response_date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return ""
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process1_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_date" />,
            dataIndex: ECColumnInfo.ec_process1_date.dataIndex,
            key: 'ec_process1_date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process1_result.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_result" />,
            dataIndex: ECColumnInfo.ec_process1_result.dataIndex,
            key: 'ec_process1_result',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_process1_result.dataIndex, 'ec_process1_result' + currentSearchClientId, () => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let promise = new Promise((resolve, reject) => {
                    let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                    let takedownResultFilters = TakedownResult.map((result) => {
                        return {
                            text: result.name[localeKey],
                            value: result.value
                        }
                    })
                    let filters = blank.concat(takedownResultFilters)
                    resolve({ filters })
                })
                return promise
            }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = TakedownResult.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process1_confirm_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process1_confirm_date" />,
            dataIndex: ECColumnInfo.ec_process1_confirm_date.dataIndex,
            key: 'ec_process1_confirm_date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD");
                    return wrapperRenderResult(content)

                }
                return wrapperRenderResult("")
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process2_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_date" />,
            dataIndex: ECColumnInfo.ec_process2_date.dataIndex,
            key: 'ec_process2_date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process2_result.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_result" />,
            dataIndex: ECColumnInfo.ec_process2_result.dataIndex,
            key: 'ec_process2_result',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_process2_result.dataIndex, 'ec_process2_result' + currentSearchClientId, () => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let promise = new Promise((resolve, reject) => {
                    let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                    let takedownResultFilters = TakedownResult.map((result) => {
                        return {
                            text: result.name[localeKey],
                            value: result.value
                        }
                    })
                    let filters = blank.concat(takedownResultFilters)
                    resolve({ filters })
                })
                return promise
            }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = TakedownResult.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)

            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process2_confirm_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process2_confirm_date" />,
            dataIndex: ECColumnInfo.ec_process2_confirm_date.dataIndex,
            key: 'ec_process2_confirm_date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process3_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_date" />,
            dataIndex: ECColumnInfo.ec_process3_date.dataIndex,
            key: 'ec_process3_date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("")
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process3_result.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_result" />,
            dataIndex: ECColumnInfo.ec_process3_result.dataIndex,
            key: 'ec_process3_result',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_process3_result.dataIndex, 'ec_process3_result' + currentSearchClientId, () => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let promise = new Promise((resolve, reject) => {
                    let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                    let takedownResultFilters = TakedownResult.map((result) => {
                        return {
                            text: result.name[localeKey],
                            value: result.value
                        }
                    })
                    let filters = blank.concat(takedownResultFilters)
                    resolve({ filters })
                })
                return promise
            }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = TakedownResult.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)
            }
        },

        {
            // title: getTitleBySafe(ECColumnInfo.ec_process3_confirm_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process3_confirm_date" />,
            dataIndex: ECColumnInfo.ec_process3_confirm_date.dataIndex,
            key: 'ec_process3_confirm_date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)
                }
                return wrapperRenderResult("");
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process_remarks.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.declaration_status" />,
            dataIndex: ECColumnInfo.declarationStatus.dataIndex,
            key: 'declarationStatus',
            showSorterTooltip: false,
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process_result.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_result" />,
            defaultShow: true,
            dataIndex: ECColumnInfo.ec_process_result.dataIndex,
            key: 'ec_process_result',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            ...getTableColumnFilters(ECColumnInfo.ec_process_result.dataIndex, 'ec_process_result' + currentSearchClientId, () => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let promise = new Promise((resolve, reject) => {
                    let blank = [{ text: intl.formatMessage(messages["label.blank"]), value: "null" }]
                    let takedownResultFilters = TakedownResult.map((result) => {
                        return {
                            text: result.name[localeKey],
                            value: result.value
                        }
                    })
                    let filters = blank.concat(takedownResultFilters)
                    resolve({ filters })
                })
                return promise

            }),
            render: (value, record) => {
                let localeKey = intl.formatMessage(common_messages["locale.name_locale_key"])
                let flg = TakedownResult.find((result) => {
                    return result.value === value
                })
                let content = flg ? flg.name[localeKey] : "";
                return wrapperRenderResult(content)
            }
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process_confirm_date.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_confirm_date" />,
            defaultShow: true,
            dataIndex: ECColumnInfo.ec_process_confirm_date.dataIndex,
            key: 'ec_process_confirm_date',
            width: 150,
            ellipsis: true,
            showSorterTooltip: false,
            sorter: true,
            render: (value, record) => {
                if (value) {
                    let content = moment(value).format("YYYY-MM-DD")
                    return wrapperRenderResult(content)

                }
                return wrapperRenderResult("")

            },
        },
        {
            // title: getTitleBySafe(ECColumnInfo.ec_process_remarks.name, intl),
            title: <FormattedMessage id="app.ui.eclist.table.ec_process_remarks" />,
            dataIndex: ECColumnInfo.ec_process_remarks.dataIndex,
            key: 'ec_process_remarks',
            showSorterTooltip: false,
            width: 150,
            ellipsis: true,
            sorter: true,
            render: (value, record) => {
                return wrapperRenderResult(value)
            },
        },
        {
            title: <FormattedMessage id="app.common.action" />,
            defaultShow: true,
            key: 'actions',
            fixed: "right",
            width: 150,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => {
                        if (onDetailClick) {
                            onDetailClick(record)
                        }
                    }
                    }>
                        <FormattedMessage id="app.common.action.detail" />
                    </Button>
                    <Button type="link" onClick={() => {
                        if (onHistoryClick) {
                            onHistoryClick(record)
                        }
                    }}>
                        <FormattedMessage id="app.ui.eclist.table.action_history" />
                    </Button>
                </Space>
            ),
        },
    ]

    return columns.map(column => {
        if (allSearchableCoulumns.some(searchItem => searchItem.value === column.dataIndex)) {
            return {
                ...column,
                render: (value, record) => {
                    return renderSearchResultHighLight(value, record, column.dataIndex, searchWords, searchColumns)
                },
            }
        } else {
            return column
        }
    })
}

export default getBasicColumns;