export const ECColumnInfo = {
    'ec_id': {
        dataIndex: 'ec_id',
        name:{
            en:'ID',
            ja:'ID'
        }
    },
    'ec_no': {
        dataIndex: 'ec_no',
        name:{
            en:'No.',
            ja:'管理番号'
        }
    },
    'ec_site': {
        dataIndex: 'ec_site',
        name:{
            en:'Marketplaces',
            ja:'ECサイト'
        }
    },
    'ec_scan_month': {
        dataIndex: 'ec_scan_date',
        name:{
            en:'Scan Month',
            ja:'スキャン月'
        }
    },
    'ec_scan_date': {
        dataIndex: 'ec_scan_date',
        name:{
            en:'Scan Date',
            ja:'スキャン月日'
        }
    },
    'ec_search_keyword': {
        dataIndex: 'ec_search_keyword',
        name:{
            en:'Query',
            ja:'検索KW'
        }
    },
    'ec_product_no': {
        dataIndex: 'ec_product_no',
        name:{
            en:'Listing #',
            ja:'Listing #'
        }
    },
    'ec_product_title': {
        dataIndex: 'ec_product_title',
        name:{
            en:'Listing Title',
            ja:'出品タイトル'
        }
    },
    'ec_product_url': {
        dataIndex: 'ec_product_url',
        name:{
            en:'Listing URL',
            ja:'出品URL'
        }
    },
    'ec_product_brand': {
        dataIndex: 'ec_product_brand',
        name:{
            en:'Brand Field',
            ja:'ブランド欄'
        }
    },
    'ec_product_seller': {
        dataIndex: 'ec_product_seller',
        name:{
            en:'Seller Name',
            ja:'出品者名'
        }
    },
    'ec_product_seller_url': {
        dataIndex: 'ec_product_seller_url',
        name:{
            en:'Seller URL',
            ja:'出品者URL'
        }
    },
    'ec_product_price': {
        dataIndex: 'ec_product_price',
        name:{
            en:'Price',
            ja:'価格'
        }
    },
    'ec_product_currency': {
        dataIndex: 'ec_product_currency',
        name:{
            en:'Currency',
            ja:'通貨'
        }
    },
    'ec_product_image_url': {
        dataIndex: 'ec_product_image_url',
        name:{
            en:'Image URL',
            ja:'画像URL'
        }
    },
    'ec_product_onsell_count': {
        dataIndex: 'ec_product_onsell_count',
        name:{
            en:'Inventory',
            ja:'在庫数'
        }
    },
    'ec_product_sold_count': {
        dataIndex: 'ec_product_sold_count',
        name:{
            en:'Sold Number',
            ja:'販売件数'
        }
    },
    'ec_product_region': {
        dataIndex: 'ec_product_region',
        name:{
            en:'Ship From(Country)',
            ja:'出品国'
        }
    },
    'ec_product_city': {
        dataIndex: 'ec_product_city',
        name:{
            en:'Ship From(Area)',
            ja:'出品地域'
        }
    },
    'ec_categorize_date': {
        dataIndex: 'ec_categorize_date',
        name:{
            en:'Report Date',
            ja:'レポート提出日'
        }
    },
    'ec_blacklist_object': {
        dataIndex: 'ec_blacklist_object',
        name:{
            en:'Black List',
            ja:'ブラックリスト対象'
        }
    },
    'ec_whitelist_object': {
        dataIndex: 'ec_whitelist_object',
        name:{
            en:'White List',
            ja:'ホワイトリスト対象'
        }
    },
    'ec_is_need_to_check': {
        dataIndex: 'ec_is_need_to_check',
        name:{
            en:'Fast Check',
            ja:'簡易診断'
        }
    },
    'ec_title_hint_word': {
        dataIndex: 'ec_title_hint_word',
        name:{
            en:'Brandname in Listing Title',
            ja:'ブランド表記判定（タイトル）'
        }
    },
    'ec_brand_hint_word': {
        dataIndex: 'ec_brand_hint_word',
        name:{
            en:'Brandname in Brand Field',
            ja:'ブランド表記判定'
        }
    },
    'ec_product_category_id_code': {
        dataIndex: 'ec_product_category_id',
        name:{
            en:'Product Type #',
            ja:'製品判定入力欄'
        }
    },
    'ec_product_category_id_word': {
        dataIndex: 'ec_product_category_id',
        name:{
            en:'Product Type',
            ja:'製品判定参照結果'
        }
    },
    'ec_category_rule_id': {
        dataIndex: 'ec_category_rule_id',
        name:{
            en:'Complaint Type',
            ja:'採用基準表'
        }
    },
    'ec_category_id_code': {
        dataIndex: 'ec_category_id',
        name:{
            en:'Input Field',
            ja:'入力欄'
        }
    },
    'ec_category_id_word': {
        dataIndex: 'ec_category_id',
        name:{
            en:'Infringement Category',
            ja:'カテゴリ'
        }
    },
    'ec_category_id_common': {
        dataIndex: 'ec_category_id',
        name:{
            en:'Infringement Type',
            ja:'集計'
        }
    },
    'ec_categorize_remarks': {
        dataIndex: 'ec_categorize_remarks',
        name:{
            en:'IPF Comment',
            ja:'IPFコメント'
        }
    },
    'ec_response_action': {
        dataIndex: 'ec_response_action',
        name:{
            en:'Enforcement Flag',
            ja:'申告実施フラグ'
        }
    },
    'ec_response_reason': {
        dataIndex: 'ec_response_reason',
        name:{
            en:"Client's Comment",
            ja:'貴社判定コメント'
        }
    },
    'ec_response_trademark': {
        dataIndex: 'ec_response_trademark',
        name:{
            en:"Trademark/Copyright registration number",
            ja:'商標・著作権登録番号'
        }
    },
    'ec_response_date': {
        dataIndex: 'ec_response_date',
        name:{
            en:"Response date",
            ja:'回答日'
        }
    },
    'ec_process1_date': {
        dataIndex: 'ec_process1_date',
        name:{
            en:"Report Date(1st)",
            ja:'1回目申告日'
        }
    },
    'ec_process1_result': {
        dataIndex: 'ec_process1_result',
        name:{
            en:"Complaint Output(1st)",
            ja:'1回目申告結果'
        }
    },
    'ec_process1_confirm_date': {
        dataIndex: 'ec_process1_confirm_date',
        name:{
            en:"Confirmation Date(1st)",
            ja:'1回目確認日'
        }
    },
    'ec_process2_date': {
        dataIndex: 'ec_process2_date',
        name:{
            en:"Report Date(2nd)",
            ja:'2回目申告日'
        }
    },
    'ec_process2_result': {
        dataIndex: 'ec_process2_result',
        name:{
            en:"Complaint Output(2nd)",
            ja:'2回目申告結果'
        }
    },
    'ec_process2_confirm_date': {
        dataIndex: 'ec_process2_confirm_date',
        name:{
            en:"Confirmation Date(2nd)",
            ja:'2回目確認日'
        }
    },
    'ec_process3_date': {
        dataIndex: 'ec_process3_date',
        name:{
            en:"Report Date(3rd)",
            ja:'3回目申告日'
        }
    },
    'ec_process3_result': {
        dataIndex: 'ec_process3_result',
        name:{
            en:"Complaint Output(3rd)",
            ja:'3回目申告結果'
        }
    },
    'ec_process3_confirm_date': {
        dataIndex: 'ec_process3_confirm_date',
        name:{
            en:"Confirmation Date(3rd)",
            ja:'3回目確認日'
        }
    },
    'declarationStatus': {
        dataIndex: 'declarationStatus',
        name:{
            en:"Declaration Status",
            ja:'申告状況'
        }
    },
    'ec_process_result': {
        dataIndex: 'ec_process_result',
        name:{
            en:"Final Result",
            ja:'最終結果'
        }
    },
    'ec_process_confirm_date': {
        dataIndex: 'ec_process_confirm_date',
        name:{
            en:"Confirmation Date(Final)",
            ja:'最終結果確認日'
        }
    },
    'ec_process_remarks': {
        dataIndex: 'ec_process_remarks',
        name:{
            en:"Remarks",
            ja:'備考'
        }
    },
// 
    'report_id': {
        dataIndex: 'report_id',
        name:{
            en:"Report",
            ja:'レポート名'
        }
    },
}