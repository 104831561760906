import { message, Table, Tooltip, Tag, Card, Form, Select, Input } from "antd";
import React, { useState, useEffect } from "react";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { FormattedMessage } from "react-intl"
import store from "@/store";
import './index.less'
import { } from "antd";
import EFCMasterTable from "@/components/ef-c-master-table";
import { useRef } from "react";
import { ProFormText, ProFormSelect, } from '@ant-design/pro-form';
import { reqFetchECSites } from '../../api/analyst-site';
import { reqFetchClients } from '@/api/analyst-client'
import { reqFetchTestPurchase, reqAddTestPurchase, reqRemoveTestPurchase, reqUpdateTestPurchase } from '@/api/test-purchase'

import { CountryConfig } from "@/config/country.js"

function TestPurchase(props) {
    const [datasource, setDatasource] = useState([])
    const fetchAllTestPurchase = () => {
        reqFetchTestPurchase().then(response => {
            const { data } = response;
            if (data.code === 0) {
                setDatasource(data.data.testPurchase)
            }
        }).catch(e => {

        })
    }

    useEffect(() => {
        document.title = "Test Purchase"
        // store.dispatch(setMenuActiveKey("analyst-ec-list"));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: "Test Purchase",
                path: "/test-purchase",
            },
        ]));
        fetchAllTestPurchase()
    }, [])


    const formRef = useRef()

    const [sites, setSites] = useState([])
    useEffect(() => {
        reqFetchECSites().then(response => {
            const { data } = response;
            if (data.code === 0) {
                setSites(data.data.sites)
            }
        }).catch(e => {

        })
    }, [])

    const [clients, setClients] = useState([])
    useEffect(() => {
        reqFetchClients().then((response) => {
            const { data } = response;
            if (data.code === 0) {
                setClients(data.data.client)
            }
        }).catch((error) => {

        })
    }, [])
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            fixed: true,
            width: 80,
            showSorterTooltip: false,
        },
        {
            title: 'クライアント',
            dataIndex: 'client',
            key: 'client',
            renderText: (record) => {
                if (record.client) {
                    let client = clients.find(client => client.client_id === record.client)
                    if (client) {
                        return client.client_name
                    } else {
                        return ""
                    }
                }
                return ""
            },

            editCell: (text, record) => {
                return (
                    <Form.Item
                        noStyle={true}
                        name={"client_" + record.id}
                        rules={[
                            {
                                required: true,
                                message: "クライアントを入力ください",
                            }
                        ]}>
                        <Select>
                            {
                                clients.map(client => {
                                    return (
                                        <Select.Option value={client.client_id} key={client.client_id}>
                                            {client.client_name}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </ Form.Item >
                )
            },
            showSorterTooltip: false,
            filterSearch: true,
        },
        {
            title: '国・地区',
            dataIndex: 'region',
            key: 'region',
            renderText: (record) => {
                if (record.region) {
                    let country = CountryConfig.find(country => country.code === record.region)
                    if (country) {
                        return country.name.ja
                    } else {
                        return ""
                    }
                }
                return ""
            },
            editCell: (text, record) => {
                return (
                    <Form.Item
                        noStyle={true}
                        name={"region_" + record.id}
                        rules={[
                            {
                                required: true,
                                message: "国・地区を入力ください",
                            }
                        ]}>
                        <Select allowClear style={{ width: 240 }}
                            showSearch
                            filterOption={(inputValue, option) => {
                                return option.key.indexOf(inputValue) > -1 ? true : false;
                            }}>
                            {CountryConfig.map((country) => {
                                return <Select.Option key={`${country.code}${country.name.ja}${country.name.en}`} value={country.code}>{country.name.ja}</Select.Option>
                            })}
                        </Select>
                    </ Form.Item >
                )
            },
            showSorterTooltip: false,
            onFilter: (value, record) => {
                if (record.region) {
                    return value === record.region
                } else {
                    return value === 0
                }
            },
            filterMultiple: true,
        },
        {
            title: 'サイト名',
            dataIndex: 'site',
            key: 'site',
            renderText: (record) => {
                if (record.site) {
                    let site = sites.find(site => site.name === record.site)
                    if (site) {
                        return site.name
                    } else {
                        return ""
                    }
                }
                return ""
            },
            editCell: (text, record) => {
                return (
                    <Form.Item
                        noStyle={true}
                        name={"site_" + record.id}
                        rules={[
                            {
                                required: true,
                                message: "サイト名を入力ください",
                            }
                        ]}>
                        <Select>
                            {
                                sites.map(site => {
                                    return (
                                        <Select.Option value={site.name} key={site.name}>
                                            {site.name}
                                        </Select.Option>
                                    )
                                })
                            }
                        </Select>

                    </ Form.Item >
                )
            },
            showSorterTooltip: false,
            filterSearch: true,
        },
        {
            title: '購入点数',
            dataIndex: 'count',
            key: 'count',
            editCell: (text, record) => {
                return (
                    <Form.Item
                        noStyle={true}
                        name={"count_" + record.id}
                        rules={[
                            {
                                required: true,
                                message: "件数を入力ください",
                            }
                        ]}>
                        <Input></Input>
                    </ Form.Item >
                )
            },
            showSorterTooltip: false,
        },
    ]

    const onRemoveClick = (record) => {
        reqRemoveTestPurchase({ id: record.id }).then((response) => {
            fetchAllTestPurchase()
        }).catch(e => {

        })
    }

    const getEditFieldsValue = (record) => {
        let row = {}
        row["client_" + record.id] = record.client;
        row["region_" + record.id] = record.region;
        row["site_" + record.id] = record.site;
        row["count_" + record.id] = record.count;
        return row
    }

    const onSaveClick = (record, values) => {
        let client = values["client_" + record.id];
        let region = values["region_" + record.id];
        let site = values["site_" + record.id];
        let count = parseInt(values["count_" + record.id]);
        reqUpdateTestPurchase({
            id: record.id, client, region, site, count
        }).then(response => {
            fetchAllTestPurchase()
        }).catch(e => {

        })
    }

    const onModalFinished = (values) => {
        let { client, region, site, count } = values;
        count = parseInt(count)
        reqAddTestPurchase({
            client, region, site, count
        }).then(response => {
            fetchAllTestPurchase()
        }).catch(e => {

        })
    }

    const modalItems = () => {
        return (
            <>
                <ProFormSelect
                    width="sm"
                    showSearch
                    options={
                        clients.map((client) => {
                            return {
                                value: client.client_id,
                                label: client.client_name,
                                key: client.client_id
                            }
                        })
                    }
                    name="client"
                    label="クライアント"
                    rules={[
                        {
                            required: true,
                            message: 'クライアントを入力ください。',
                        },
                    ]}
                />
                <ProFormSelect
                    width="sm"
                    options={
                        CountryConfig.map((country) => {
                            return {
                                key: `${country.code}${country.name.ja}${country.name.en}`,
                                value: country.code,
                                label: country.name.ja
                            }
                        })
                    }
                    name="region"
                    label="国・地区"
                    rules={[
                        {
                            required: true,
                            message: '国・地区を入力ください。',
                        },
                    ]}
                />

                <ProFormSelect
                    width="sm"
                    showSearch
                    options={
                        sites.map((site) => {
                            return {
                                value: site.name,
                                label: site.name,
                                key: site.name
                            }
                        })
                    }
                    name="site"
                    label="サイト"
                    rules={[
                        {
                            required: true,
                            message: 'サイトを入力ください。',
                        },
                    ]}
                />
                <ProFormText width="sm" name="count" label="件数" rules={[
                    {
                        required: true,
                        message: '件数を入力ください。',
                    },
                ]}
                />
            </>
        )
    }

    return (
        <div className="master-container">
            <div>
                <Card bordered={false}>
                    <Form
                        ref={formRef}
                        component={false}
                    >
                        <EFCMasterTable
                            columns={columns}
                            dataSource={datasource}
                            modalTitle="サイトを追加"
                            onRemoveClick={onRemoveClick}
                            getEditFieldsValue={getEditFieldsValue}
                            onSaveClick={onSaveClick}
                            onModalFinished={onModalFinished}
                            modalItems={modalItems()}
                            tableProps={{
                                pagination: {
                                    showQuickJumper: true,
                                    total: datasource.length,
                                    pageSizeOptions: [10, 20, 50, 100, 200, 500],
                                    defaultPageSize: 20,
                                    showSizeChanger: true,
                                }
                            }}
                        />
                    </Form>
                </Card>
            </div>
        </div>
    )
}

export default TestPurchase