import ec_crawler_task_en_lang from './ec-crawler-task/en.js'

const common_en_lang = {
    // infrigment common categories name key
    'app.config.infirgment_common_cateogry.name_locale_key': 'en',

    // locale name key
    'app.config.locale.name_locale_key': 'en',

    // product type
    'app.config.locale.product_locale_key': "name_en",

    // infringement
    'app.config.locale.infringement_locale_key': "name_en",

    // subinfringement
    'app.config.locale.subinfringement_locale_key': "name_en",

    // header
    'app.layout.header.language': 'English',
    'app.layout.header.logout': 'Log out',

    // sider menu
    'app.layout.menu.home': 'Home',

    // common label
    'app.common.label.blank': 'Blank',
    'app.common.label.no': 'No',
    'app.common.label.yes': 'Yes',

    'app.common.flag.yes': 'Yes',
    'app.common.flag.no': 'No',


    // common column name
    'app.common.action': 'Actions',
    'app.common.action.detail': 'Detail',
    'app.common.action.delete': 'Delete',
    'app.common.action.add': 'Add',
    'app.common.action.update': 'Update',
    'app.common.action.back': 'Back',
    'app.common.action.download': 'Download',
    'app.common.action.upload': "Upload",

    // 404
    'app.ui.404.description1': 'This page is for IPF regular membership.',
    'app.ui.404.description2': 'For more information, please contact your sales.',
    'app.ui.404.contact': 'Contact:',


    // efc tableheader
    'app.components.ec_table_header.total_count': 'Total:{count}',
    'app.components.ec_table_header.error_count': 'Error:{count}',
    'app.components.ec_table_header.update': 'Update',

    // efc searchbar
    'app.components.ef_code_button.title': 'Get the verification code.',
    'app.components.ef_code_button.count_down': 'Get the new code after {countDown}s.',

    // efc searchbar
    'app.components.ef_search_bar.date_item': 'Date',
    'app.components.ef_search_bar.date_from': 'From',
    'app.components.ef_search_bar.date_to': 'To',
    'app.components.ef_search_bar.search_item': 'Search by',
    'app.components.ef_search_bar.search_hint': 'keyword',
    'app.components.ef_search_bar.search_button': 'Find',
    'app.components.ef_search_bar.reset_button': 'Reset',
    'app.components.ef_search_bar.search_tpye_fuzzy_match': 'Fuzzy match',
    'app.components.ef_search_bar.search_tpye_exactly_match': 'Exact match',
    'app.components.ef_search_bar.search_tpye_previous_match': 'Previous match',
    'app.components.ef_search_bar.search_tpye_post_match': 'Post match',

    // data filter
    'app.components.data_filter.check_all': 'Check All',
    'app.components.data_filter.cancel': 'Cancel',
    'app.components.data_filter.submit': 'Submit',

    'app.layout.menu.online_protection': 'Online Protection',
    'app.layout.menu.online_protection.ec_record_list': 'Marketplace Database',
    'app.layout.menu.online_protection.sns_record_list': 'Social Media Database',
    'app.layout.menu.online_protection.ws_record_list': 'Website Database',
    'app.layout.menu.online_protection.report_list': 'Report',

    'app.layout.menu.offline_protection': 'Offilne Protection',

    'app.layout.menu.legal_services': 'Legal Services',
    'app.layout.menu.legal_services.tm_search': 'T/M Search',
    'app.layout.menu.legal_services.tm_register': 'T/M Register',
    'app.layout.menu.legal_services.tm_application_status': 'T/M Application status',
    'app.layout.menu.legal_services.tm_update_status': 'T/M Update status',
    'app.layout.menu.legal_services.tm_rejection_reason': 'T/M Reasons for rejection',
    'app.layout.menu.legal_services.tm_transfer': 'T/M Transfer',
    'app.layout.menu.legal_services.tm_monitoring': 'T/M Monitoring',
    'app.layout.menu.legal_services.tm_correction_invaildation_trail': 'T/M Trial for invalidation of correction',
    'app.layout.menu.legal_services.tm_case_management': 'T/M Case Management',
    'app.layout.menu.legal_services.tm_registration': 'T/M Registration',

    'app.layout.menu.biz_supprot': 'Biz Support',
    'app.layout.menu.news': 'News',
    'app.layout.menu.notice': 'Notice',

    'app.layout.menu.other_support': 'Other',
    'app.layout.menu.test_purfchase': 'Test Purchase',


    'app.layout.menu.task': 'Task',
    'app.layout.menu.task.ec_crawler_task': 'EC Task',

    'app.layout.menu.setting': 'Setting',
    'app.layout.menu.setting.profile': 'Profile',
    'app.layout.menu.setting.data_setting' : 'Data Setting',
    'app.layout.menu.setting.user_list': 'User list',
    'app.layout.menu.setting.brand_list': 'Brand Reg.',
    'app.layout.menu.setting.manager_list': 'Manager Reg.',
    'app.layout.menu.setting.ec_list': 'Marketplace Reg.',
    'app.layout.menu.setting.infringement_category': 'Marketplace Reason code',

    // ec-dashboard
    'app.ui.dashboard.title': 'Dashboard',
    'app.ui.dashboard.breadcrumb.dashboard': 'Dashboard',
    'app.ui.dashboard.search.brand': 'Brand',
    'app.ui.dashboard.search.date_column': 'Date column',
    'app.ui.dashboard.search.date_from': 'Period',
    'app.ui.dashboard.search.date_to': '~',
    'app.ui.dashboard.search.find': 'Find',

    'app.ui.dashboard.status.title': 'Status',
    'app.ui.dashboard.status.impact_revenue': 'Infringement Impact Revenue',
    'app.ui.dashboard.status.impact_revenue.usd': 'USD:',
    'app.ui.dashboard.status.impact_revenue.rate': 'Rate:',
    'app.ui.dashboard.status.impact_revenue.jpy': 'JPY:',
    'app.ui.dashboard.status.annual_date': 'Annual Status - Date',
    'app.ui.dashboard.status.analyst': 'Member',

    'app.ui.dashboard.chart.overall_categorization_status.title': 'Overall Categorization Status',
    'app.ui.dashboard.chart.overall_categorization_status.legend.categorized': 'Categorized',
    'app.ui.dashboard.chart.overall_categorization_status.legend.infringment': 'Infringement',
    'app.ui.dashboard.chart.overall_categorization_status.legend.infringment_percent': 'Infringement%',
    'app.ui.dashboard.chart.overall_categorization_status.left_y_axis_name': 'Count',
    'app.ui.dashboard.chart.overall_categorization_status.right_y_axis_name': '%',

    'app.ui.dashboard.chart.overall_infringement_type.title': 'Overall Infringement Type',
    'app.ui.dashboard.map.overall_infringement_country.title': 'Overall Infringement By Region',
    'app.ui.dashboard.chart.overall_infringement_country.title': 'Overall Infringement By Region',
    'app.ui.dashboard.table.categorization_status.title': 'Categorization Status',
    'app.ui.dashboard.chart.locale_filtering.title': 'Region Filtering',
    'app.ui.dashboard.filter.locale_filtering.title': 'Region',

    'app.ui.dashboard.chart.ec_infringement_categorization.title': 'EC Infringement Categorization',
    'app.ui.dashboard.list.top_infringers.title': 'Top Infringers',
    'app.ui.dashboard.chart.takedown.title': 'Takedown',

    'app.ui.dashboard.chart.ws_takedown.title': 'Web Site Takedown',
    'app.ui.dashboard.chart.ws_category.title': 'Web Site Categorization',

    // ec-list
    'app.ui.eclist.title': 'Marketplace Database',
    'app.ui.eclist.to_trademark_list': 'Your Trademark List',
    'app.ui.eclist.table.action_history': 'Show edit history',

    'app.ui.eclist.column_modal.title': "Column display setting",

    'app.ui.eclist.table.id': "ID",
    'app.ui.eclist.table.ec_no': "No.",
    'app.ui.eclist.table.ec_site': "Marketplaces",
    'app.ui.eclist.table.ec_scan_month': "Scan Month",
    'app.ui.eclist.table.ec_scan_date': "Scan Date",
    'app.ui.eclist.table.ec_search_keyword': "Query",
    'app.ui.eclist.table.ec_product_no': "Listing #",
    'app.ui.eclist.table.ec_product_title': "Product Title",
    'app.ui.eclist.table.ec_product_url': "Listing URL",
    'app.ui.eclist.table.ec_product_brand': "Brand Field",
    'app.ui.eclist.table.ec_product_seller': "Seller Name",
    'app.ui.eclist.table.ec_product_seller_url': "Seller URL",
    'app.ui.eclist.table.ec_product_price': "Price",
    'app.ui.eclist.table.ec_product_currency': "Currency",
    'app.ui.eclist.table.ec_product_image_url': "Image URL",
    'app.ui.eclist.table.ec_product_onsell_count': "Inventory",
    'app.ui.eclist.table.ec_product_sold_count': "Sold Number",
    'app.ui.eclist.table.ec_product_region': "Ship From(Region)",
    'app.ui.eclist.table.ec_product_city': "Ship From(Area)",
    'app.ui.eclist.table.ec_categorize_date': "Report Date",
    'app.ui.eclist.table.ec_blacklist_object': "Black List",
    'app.ui.eclist.table.ec_whitelist_object': "White List",
    'app.ui.eclist.table.ec_is_need_to_check': "Fast Check",
    'app.ui.eclist.table.ec_title_hint_word': "Brandname in Listing Title",
    'app.ui.eclist.table.ec_brand_hint_word': "Brandname in Brand Field",
    'app.ui.eclist.table.ec_product_category_id_code': "Product Type #",
    'app.ui.eclist.table.ec_product_category_id_word': "Product Type",
    'app.ui.eclist.table.ec_category_rule_id': "Complaint Type",
    'app.ui.eclist.table.ec_category_id_code': "Input Field",
    'app.ui.eclist.table.ec_category_id_word': "Infringement Category",
    'app.ui.eclist.table.ec_category_id_common': "Infringement common category",
    'app.ui.eclist.table.ec_categorize_remarks': "IPF Comment",
    'app.ui.eclist.table.ec_response_action': "Enforcement Flag",
    'app.ui.eclist.table.ec_response_reason': "Client's Comment",
    'app.ui.eclist.table.ec_response_trademark': "Trademark/Copyright registration number",
    'app.ui.eclist.table.ec_response_date': "Response date",
    'app.ui.eclist.table.ec_process1_date': "Report Date(1st)",
    'app.ui.eclist.table.ec_process1_result': "Complaint Output(1st)",
    'app.ui.eclist.table.ec_process1_confirm_date': "Confirmation Date(1st)",
    'app.ui.eclist.table.ec_process2_date': "Report Date(2nd)",
    'app.ui.eclist.table.ec_process2_result': "Complaint Output(2nd)",
    'app.ui.eclist.table.ec_process2_confirm_date': "Confirmation Date(2nd)",
    'app.ui.eclist.table.ec_process3_date': "Report Date(3rd)",
    'app.ui.eclist.table.ec_process3_result': "Complaint Output(3rd)",
    'app.ui.eclist.table.ec_process3_confirm_date': "Confirmation Date(3rd)",
    'app.ui.eclist.table.declaration_status': "Declaration Status",
    'app.ui.eclist.table.ec_process_result': "Final Result",
    'app.ui.eclist.table.ec_process_confirm_date': "Confirmation Date(Final)",
    'app.ui.eclist.table.ec_process_remarks': "Remarks",
    'app.ui.eclist.table.report_id': "Report",

    // ec list history
    'app.ui.eclist_history.table.edited_by': "Edited by",
    'app.ui.eclist_history.table.edited_date': "Edited date",

    // profile
    'app.ui.profile.section.basicinfo.title': 'Basic Information',
    'app.ui.profile.section.basicinfo.name': 'Name (Required)',
    'app.ui.profile.section.basicinfo.mail': 'Mail',
    'app.ui.profile.section.basicinfo.tel': 'Tel',
    'app.ui.profile.section.basicinfo.name.error': 'Please input your name.',
    'app.ui.profile.section.basicinfo.tel.error': 'Please check your tel number.',
    'app.ui.profile.section.basicinfo.update': 'Update',

    'app.ui.profile.section.company.title': 'Company Information',
    'app.ui.profile.section.company.name': 'Name',
    'app.ui.profile.section.company.address': 'Address',
    'app.ui.profile.section.company.tel': 'Tel',
    'app.ui.profile.section.company.remarks': 'Remarks',

    'app.ui.profile.section.analyst.title': 'Analyst Information',
    'app.ui.profile.section.analyst.table.name': 'Name',
    'app.ui.profile.section.analyst.table.mail': 'Mail',
    'app.ui.profile.section.analyst.table.tel': 'Tel',

    'app.ui.profile.section.contract.title': 'Contract Information',
    'app.ui.profile.section.contract.table.name': 'Service name',
    'app.ui.profile.section.contract.table.duration_from': 'From',
    'app.ui.profile.section.contract.table.duration_to': 'To',
    'app.ui.profile.section.contract.table.fee': 'Fee',

    'app.ui.profile.action.reset_password': 'Reset password',
    'app.ui.profile.breadcrumb.password': 'Password',
    'app.ui.profile.breadcrumb.profile': 'Profile',
    'app.ui.profile.breadcrumb.home': 'Home',

    'app.ui.profile.message.success.update_success': 'Update successfully.',
    'app.ui.profile.message.error.update_failed': 'Update unsuccessfully.',
    'app.ui.profile.message.error.password_character': 'The password contains three character categories: digits, lowercase characters and special characters and must be at least 8 characters long.',
    'app.ui.profile.message.error.confirm_password_same': 'The confirm password must be the same as the password.',
    'app.ui.profile.message.success.password_reset_success': 'Your password has been reset successfully.',
    'app.ui.profile.message.error.password_reset_failed': 'Failed to reset password.',

    'app.ui.profile.password_reset.title': 'Reset your password',
    'app.ui.profile.password_reset.old_password': 'Current password',
    'app.ui.profile.password_reset.new_password': 'New password',
    'app.ui.profile.password_reset.new_password_confirm': 'Confirm new password',
    'app.ui.profile.password_reset.code': 'Verification code',
    'app.ui.profile.password_reset.reset_button': 'Reset',

    'app.ui.profile.password_reset.message.error.no_current_password': 'Please input the current password.',
    'app.ui.profile.password_reset.message.error.no_new_password': 'Please input the new password.',
    'app.ui.profile.password_reset.message.error.no_confirm_new_password': 'Please input the confirm password.',
    'app.ui.profile.password_reset.message.error.no_code': 'Please input the verification code.',

    // login
    'app.ui.login.label.mail': 'Mail',
    'app.ui.login.label.password': 'Password',
    'app.ui.login.label.password_confirm': 'Comfirm password',
    'app.ui.login.label.name': 'User name',
    'app.ui.login.label.verification_code': 'Verification Code',
    'app.ui.login.button.login': 'Log in',
    'app.ui.login.button.setup': 'Set up',
    'app.ui.login.button.to_setup': 'Set up',
    'app.ui.login.button.forget_password': 'Reset your password',
    'app.ui.login.button.to_login': 'Log in',

    'app.ui.login.label.new_password': 'New password',
    'app.ui.login.label.new_password_confirm': 'Confirm password',
    'app.ui.login.button.reset': 'Reset',

    'app.ui.login.message.error.no_mail': 'Please input the mail.',
    'app.ui.login.message.error.mail_format': 'Please check the mail.',
    'app.ui.login.message.error.no_password': 'Please input the password.',
    'app.ui.login.error.no_confirm_password': 'Please input the confirm password.',

    // T/M trademark
    'app.ui.trademark.register_title': 'T/M Register',
    'app.ui.trademark.container.trademark_info.title': 'T/M Information',
    'app.ui.trademark.label.trademark_word': 'Word mark',
    'app.ui.trademark.label.trademark_word_description': 'If the trademark is text only, please enter it directly here. However, in the case of design, please attach the image instead of entering it here.',
    'app.ui.trademark.label.trademark_image': 'Design mark',
    'app.ui.trademark.label.trademark_image_description': 'If you wish to register with a logo, please attach an image. (Within 10MB)',
    'app.ui.trademark.label.trademark_detail': 'Description(required)',
    'app.ui.trademark.label.trademark_detail_description': 'What kind of goods or services will you use the trademark for? ',
    'app.ui.trademark.label.trademark_owner': 'Applicant name (required)',
    'app.ui.trademark.label.trademark_owner_description': 'If applicant is a corporation, please enter the registered corporation name.',
    'app.ui.trademark.container.contact_info.title': 'Contact information',
    'app.ui.trademark.label.contact_name': 'Person in charge(required)',
    'app.ui.trademark.label.contact_mail': 'Mail(required)',
    'app.ui.trademark.label.contact_tel': 'Tel(required)',
    'app.ui.trademark.label.contact_fax': 'Fax',
    'app.ui.trademark.label.remarks': 'Remarks',
    'app.ui.trademark.label.remarks_description': '   ',

    'app.ui.trademark.message.error.no_word_or_image_mark': 'Enter the word mark or image mark.',
    'app.ui.trademark.message.error.tel_format': 'Please enter a valid phone number.',
    'app.ui.trademark.message.success.mail_success': 'Submitted trademark information to the IPF.',
    'app.ui.trademark.message.error.no_trademark_discraption': 'Please enter your trademark discraption.',
    'app.ui.trademark.message.error.no_owner': 'Please enter the applicant name.',
    'app.ui.trademark.message.error.no_contact_person': 'Please enter the person in charge.',
    'app.ui.trademark.message.error.no_contact_mail': 'Please enter the email of the person in charge.',
    'app.ui.trademark.message.error.contact_mail_format': 'Please enter a valid email.',
    'app.ui.trademark.message.error.no_contact_tel': 'Please enter the phone number of the person in charge.',

    'app.ui.trademark.button.send_mail': 'Send',
    'app.ui.trademark.button.to_registration': 'Trademark Registration',
    'app.ui.trademark.button.to_request_trademark': 'Request TM Application',
    'app.ui.trademark.button.to_trademark_list': 'Your Trademark List',

    'app.ui.trademark.table.id': 'ID',
    'app.ui.trademark.table.client': 'Client',
    'app.ui.trademark.table.trademark_no': 'Trademark number',
    'app.ui.trademark.table.trademark_region': 'Region',
    'app.ui.trademark.table.trademark_product_genre': 'Product genre',
    'app.ui.trademark.table.trademark_word': 'Text/Logo',
    'app.ui.trademark.table.trademark_registration_date': 'Registration date',
    'app.ui.trademark.table.trademark_expire_date': 'Expire date',
    'app.ui.trademark.table.remind_mail_flg': 'Reminder mail',
    'app.ui.trademark.table.trademark_right_holder': 'Right holder',
    'app.ui.trademark.table.description_ja': 'Designated products and services (Japanese)',
    'app.ui.trademark.table.description_en': 'Designated products and services (English)',
    'app.ui.trademark.table.description_cn': 'Designated products and services (Chinese)',
    'app.ui.trademark.table.trademark_registration_status': 'Status',
    'app.ui.trademark.table.trademark_remarks': 'Remarks',

    'app.trademark_registration_status.register': 'Register',
    'app.trademark_registration_status.applying': 'Applying',
    'app.trademark_registration_status.rejection': 'Rejection',

    'app.ui.trademark.add.title': 'T/M Registration - Add',
    'app.ui.trademark.add.container_search.title': 'Trademark database',
    'app.ui.trademark.add.container_search.button': 'Search',
    'app.ui.trademark.add.container_body.title': 'T/M Information',
    'app.ui.trademark.add.product_genre_prefix': "{number}.",
    'app.ui.trademark.add.remind_message': "Notify when it is 30, 60, or 90 days before the expiration date.",

    'app.ui.trademark.update.title': 'T/M Registration - Update',

    'app.ui.trademark.message.error.no_search_db': "Choose a trademark database.",
    'app.ui.trademark.message.info.image_uploading': "Trademark images are still being uploaded. please wait a little bit.",
    'app.ui.trademark.message.info.trademark_updated': "Trademark information has been updated.",
    'app.ui.trademark.message.error.no_region': "Please select the trademark region.",
    'app.ui.trademark.message.error.no_trademark_no': "Please input the trademark number.",
    'app.ui.trademark.message.error.no_product_genre': "Please select the product genre.",
    'app.ui.trademark.message.error.no_word_mark': "Please input the word mark.",

    'app.ui.trademark.message.info.trademark_added': "Adding trademark is completed.",
    'app.ui.trademark.message.error.no_client': "Please select the client.",

    // reports
    'app.ui.ec_reports.table.id': "ID",
    'app.ui.ec_reports.table.report_name': "Report name",
    'app.ui.ec_reports.table.upload_date': "Upload date",
    'app.ui.ec_reports.table.scrape_total': "Record count",
    'app.ui.ec_reports.table.send_date': "Report date",
    'app.ui.ec_reports.table.categorized_count': "Categorized count",
    'app.ui.ec_reports.table.not_categorize_date': "Not categorized count",
    'app.ui.ec_reports.table.response_date': "Response date",
    'app.ui.ec_reports.table.response_total': "Response count",
    'app.ui.ec_reports.action.response': "Response",

    'app.ui.ec_reports.message.info.had_notify_analyst': "Notified to the analyst.",
    'app.ui.ec_reports.message.error.nofity_failed': "An error has occurred.Please try it again.",
    'app.ui.ec_reports.message.error.unknown': "An unknown error has occurred.",

    // ec detail
    'app.ui.ec_detail.title': "Record detail",
    'app.ui.ec_detail.tab.web': "Web",
    'app.ui.ec_detail.tab.thumbnail': "Thumbnail",
    'app.ui.ec_detail.tab.screenshot': "Screenshot",
    'app.ui.ec_detail.tab.scrape': "Scrape information",
    'app.ui.ec_detail.tab.categorized_info': "Categorized Information",
    'app.ui.ec_detail.tab.client_response': "Client Response",
    'app.ui.ec_detail.tab.ipf_report_info': "IPF report information",
    'app.ui.ec_detail.button.back': "Back to list",
    'app.ui.ec_detail.step.1': "Report(1st)",
    'app.ui.ec_detail.step.2': "Report(2nd)",
    'app.ui.ec_detail.step.3': "Report(3rd)",
    'app.ui.ec_detail.step.4': "Final Result",
    'app.ui.ec_detail.message.info.update_success': "Update successful.",
    'app.ui.ec_detail.message.error.update_failed': "Failed to update.",

    // home
    'app.ui.home.online.cases': "Number of cases",
    'app.ui.home.offline.cases': "Number of cases",
    'app.ui.home.registration.cases': "Number of cases",
    'app.ui.home.test_purchase.cases': "Number of cases",

    'app.ui.home.section.tm_register_region': "Trademark Registration",
    'app.ui.home.section.online_bad_seller': "Online infringing seller",
    'app.ui.home.section.test_purchase_cases_number': "Test purchase",
    'app.ui.home.section.offline_protection': "Offline protection",
    'app.ui.home.section.ipf_news': "IP FORWARD NEWS",

    'app.ui.home.section.unit': "cases",

    // notification center
    'app.ui.notification_center.title': 'Notice',
    'app.ui.notification_center.table.column.id': "ID",
    'app.ui.notification_center.table.column.title': "Title",
    'app.ui.notification_center.table.column.content': "Content",
    'app.ui.notification_center.table.column.type': "Type",
    'app.ui.notification_center.table.column.from': "From",
    'app.ui.notification_center.table.column.send_date': "Send Date",
    'app.ui.notification_center.table.column.viewed': "Viewed",

    // ws
    'app.ui.wslist.table.search_engine': 'Search engine',
    'app.ui.wslist.table.page_domain': 'Domain',
    'app.ui.wslist.table.page_url': 'URL',
    'app.ui.wslist.table.domain_region': 'Country/Area',
    'app.ui.wslist.table.tld': 'TLD',
    'app.ui.wslist.table.domain_contact': 'Contact',
    'app.ui.wslist.table.redirect_url': 'Redirect',
    'app.ui.wslist.table.whois': 'Whois',
    'app.ui.wslist.table.domain_created_at': 'Domain Created',
    'app.ui.wslist.table.domain_expire_at': 'Domain Expire',
    'app.ui.wslist.table.domain_updated_at': 'Domain Updated',
    'app.ui.wslist.search.collapse': "Search conditions",

    "app.ui.ws_detail.title": "Record detail",
    'app.ui.ws_detail.section.scrape_info': "Basic Information",

    "app.ui.data_setting.other_setting.tag_title": "Impact Amount Setting",
    "app.ui.data_setting.other_setting.impact_func": "Impact Amount：A＊B＊C",
    "app.ui.data_setting.other_setting.price_factor": "Factor(A)：",
    "app.ui.data_setting.other_setting.displayed_price": "Displayed Price",
    "app.ui.data_setting.other_setting.flat_price": "Flat Price",
    "app.ui.data_setting.other_setting.count_factor": "Factor(B)：",
    "app.ui.data_setting.other_setting.inventory": "Inventory",
    "app.ui.data_setting.other_setting.sold_count": "Sold Count",
    "app.ui.data_setting.other_setting.flat_count": "Flat Count",
    "app.ui.data_setting.other_setting.percent_factor": "Factor(C)：",

};

const combine_en_lang = {
    ...common_en_lang,
    ...ec_crawler_task_en_lang
}

export default combine_en_lang
