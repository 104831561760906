// login intl messages
import { defineMessages } from 'react-intl'

const messages = defineMessages({
    'message.error.password_character': {
        id: "app.ui.profile.message.error.password_character",
        defaultMessage: "The password contains three character categories: digits, lowercase characters and special characters and must be at least 8 characters long.",
    },
    'message.error.confirm_password_same':{
        id: "app.ui.profile.message.error.confirm_password_same",
        defaultMessage: "The confirm password must be the same as the password.",
    },
    'message.error.no_mail':{
        id: "app.ui.login.message.error.no_mail",
        defaultMessage: "Please input the mail.",
    },
    'message.error.mail_format':{
        id: "app.ui.login.message.error.mail_format",
        defaultMessage: "Please check the mail.",
    },
    'message.error.no_new_password':{
        id: "app.ui.profile.password_reset.message.error.no_new_password",
        defaultMessage: "Please input the new password.",
    },
    'message.error.no_confirm_new_password':{
        id: "app.ui.profile.password_reset.message.error.no_confirm_new_password",
        defaultMessage: "Please input the comfirm password.",
    },
    'message.error.no_code':{
        id: "app.ui.profile.password_reset.message.error.no_code",
        defaultMessage: "Please input the verification code.",
    },
    'message.error.no_password':{
        id: "app.ui.login.message.error.no_password",
        defaultMessage: "Please input the password.",
    },
    'message.error.no_confirm_password':{
        id: "app.ui.login.message.error.no_confirm_password",
        defaultMessage: "Please input the confirm password.",
    },
    'message.error.no_user_name':{
        id: "app.ui.profile.section.basicinfo.name.error",
        defaultMessage: "Please input your name.",
    },

})

export default messages