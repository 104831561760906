import "./index.less";
import ECChartContainer from "../common/chart-container"
import ChartDataFilter from "./chart-data-filter";
import SellerList from './seller-list'

import { DollarCircleTwoTone, CalendarTwoTone, TeamOutlined } from "@ant-design/icons"
import React, { Component } from "react";

import mapboxgl from '!mapbox-gl';// eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';

import genOverallCategorizationStatus from './charts-options/overall-categorization-status.js'
import genOverallInfringementCountry from './charts-options/overall-infringement-country.js'
import { genCountryFilteringPie, genCountryFilteringBar } from './charts-options/country-filtering.js'
import genECInfringementCategorizationChart from './charts-options/ec-infringement-categorization'
import genTakedownChartConfig from './charts-options/takedown-chart.js'
import genOverallInfringementType from './charts-options/overall-infringement-type.js'

import { InfringementCommonCategories } from '../../config/infringement-common-category.js'

import { Row, Col, DatePicker, Table, Select, Button, Form, message, Tabs } from "antd";
import { reqFetchDashboardInfo } from "@/api/ec.js";
import { reqFetchClients } from '@/api/analyst-client'

import moment from 'moment'
import { connect } from "react-redux";
import { TakedownResult } from "../../config/ec-report-select-items";

import * as echarts from 'echarts/core';
import { BarChart, LineChart, PieChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, GridComponent, LegendComponent, VisualMapComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import store from "@/store";

import ECListCommon from "../ec/common/ec-list-common.js"
import { FormattedMessage, injectIntl } from "react-intl";
import messages from "./messages";
import commonMessage from "../../locales/messages"
import { getCountryConfigDict } from "../../config/country";
import { WSCategoryType, WSTakedownResult } from "../../master-data/ws-master-data";


// import { jsPDF } from "jspdf";
// import html2canvas from 'html2canvas'

// 注册必须的组件
echarts.use([TitleComponent, TooltipComponent, GridComponent, LegendComponent, VisualMapComponent, BarChart, CanvasRenderer, LineChart, PieChart]);
mapboxgl.accessToken = 'pk.eyJ1IjoiY29uZ2NoaWNhZ28iLCJhIjoiY2t4c3V2b2NvNTBoajJ1cHpyb2l0bHZ3MiJ9.2Jkc9_TADo_YzhHZxm46Lw';

class EFDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            tabActiveKey: "ec-dashboard"
        }

        this.startDate = moment(moment(moment().format("YYYY").concat("0101"))).format("YYYY-MM-DD");
        this.endDate = moment(moment(moment().format("YYYY").concat("1231"))).format("YYYY-MM-DD");
        this.analyst = ""
        this.dateType = 1; // 1:scanDate 2:categorizeDate
        this.mapContainer = React.createRef();
        this.map = React.createRef();

        this.formRef = React.createRef()

        if (this.props.role & 2) {
            reqFetchClients().then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.setState({
                        clients: data.data.client
                    });
                }
            }).catch((error) => {
            })
        }
    }

    fetchDashboardInfo = (clientId, fromDate, toDate, dateType) => {

        reqFetchDashboardInfo({
            clientId: clientId,
            fromDate: fromDate,
            toDate: toDate,
            dateType: dateType
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.setState({
                    ...data.data,
                    infringementTypeFilters: data.data.infringementFilters,
                    countryDateInfringementFilters: data.data.countryFilters,
                    countryInfringementFilters: data.data.countryFilters,
                    countryECInfringementFilters: data.data.countryFilters,
                    countryTakedownFilters: data.data.countryFilters,
                    countrySellerFilters: data.data.countryFilters,
                });
                this.setupMap(data.data.countryInfringementCountDict)

            }
        }).catch((error) => {

        })
    }

    setupMap = (data) => {
        if (this.map.current && data) {
            if (this.map.current.getLayer && this.map.current.getLayer('circle-layer')) {
                this.map.current.removeLayer('circle-layer')
            }
            if (this.map.current.getLayer && this.map.current.getLayer('label-layer')) {
                this.map.current.removeLayer('label-layer')
            }
            if (this.map.current.getSource && this.map.current.getSource('country-point')) {
                this.map.current.removeSource('country-point')
            }
            this.map.current.addSource('country-point', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    "features": Object.keys(data).filter(key => {
                        return data[key].infringement && data[key].coordinates
                    }).map(name => {
                        return {
                            'type': 'Feature',
                            'properties': {
                                count: data[name].infringement
                            },
                            'geometry': {
                                'type': 'Point',
                                'coordinates': data[name].coordinates
                            }
                        }
                    })
                }
            });

            this.map.current.addLayer({
                'id': 'circle-layer',
                'type': 'circle',
                'source': 'country-point',
                'paint': {
                    'circle-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'count'],
                        100,
                        '#f39b90',
                        200,
                        '#ff1e00'
                    ],
                    'circle-opacity': 0.75,
                    'circle-radius': [
                        'interpolate',
                        ['linear'],
                        ['get', 'count'],
                        100,
                        10,
                        200,
                        15
                    ]
                }
            });

            this.map.current.addLayer({
                'id': 'label-layer',
                'type': 'symbol',
                'source': 'country-point',
                'layout': {
                    'text-field': ['get', 'count'],
                    'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
                    'text-size': 12
                },
                'paint': {
                    'text-color': 'rgba(0,0,0,0.5)'
                }
            });

        }
    }

    onFilterCountryDateInfringement = (values) => {
        this.setState({
            countryDateInfringementFilters: values
        })
    }

    onFilterCountryInfringement = (values) => {
        this.setState({
            countryInfringementFilters: values
        })
    }

    onFilterCountryECInfringement = (values) => {
        this.setState({
            countryECInfringementFilters: values
        })
    }

    onFilterCountryTakedown = (values) => {
        this.setState({
            countryTakedownFilters: values
        })
    }

    onFilterCountrySeller = (values) => {
        this.setState({
            countrySellerFilters: values
        })
    }

    componentDidMount() {
        this.formRef.current.setFieldsValue({
            fromDate: moment(this.startDate),
            toDate: moment(this.endDate),
        })

        if (this.props.role & 2) {
            store.dispatch(setMenuActiveKey("analyst-online-dashboard"));
        } else {
            store.dispatch(setMenuActiveKey("client-online-dashboard"));
        }
        // store.dispatch(setContentContainerHeaderAction([
        //     {
        //         name: "pdf",
        //         path: "",
        //         onClick: this.genPdf
        //     }
        // ]));
        store.dispatch(setContentContainerHeaderAction([]));
        store.dispatch(setBreadcrumbs([
            {
                name: <FormattedMessage id="app.layout.menu.home" />,
                path: "/",
            },
            {
                name: <FormattedMessage id="app.ui.dashboard.breadcrumb.dashboard" />,
                path: "/online-dashboard",
            },
        ]));
        // initialize map only once
        if (!this.map.current) {
            this.map.current = new mapboxgl.Map({
                container: this.mapContainer.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [120.930229378541, 23.7779779950014],//Taiwan
                zoom: 2
            });
            this.map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
        }

        if (this.props.role & 1) {
            this.clientId = this.props.companyInfo ? this.props.companyInfo.client_id : undefined;
            if(this.props.analystInfo) {
                this.analyst = this.props.analystInfo.map(analyst => {
                    return analyst.user_name
                }).join("、");
            }
            setTimeout(() => {
                this.fetchDashboardInfo(this.clientId, this.startDate, this.endDate, this.dateType);
            }, 1 * 1000)
        }
    }

    componentWillUnmount() {
        if (this.map.current) {
            this.map.current.remove()
        }
    }

    getInfringementFileter = () => {
        if (this.state.infringementFilters) {
            let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])
            return this.state.infringementFilters.map(infringement => {
                let infringementInfo = InfringementCommonCategories.find(info => {
                    return info.id === parseInt(infringement)
                })
                return {
                    name: infringementInfo.name[nameIntlKey],
                    value: infringement
                }
            })
        } else {
            return []
        }
    }

    getContountryFilter = () => {
        if (this.state.countryFilters) {
            let localeDict = getCountryConfigDict()
            let nameIntlKey = this.props.intl.formatMessage(commonMessage["locale.name_locale_key"])
            return this.state.countryFilters.map(countryCode => {
                if (localeDict[countryCode]) {
                    return {
                        name: localeDict[countryCode].name[nameIntlKey],
                        value: countryCode
                    }
                } else {
                    return {
                        name: countryCode,
                        value: countryCode
                    }
                }
            })
        } else {
            return []
        }
    }

    getOitTableDatasource = (data) => {
        let categorizedDateDict = {}
        if (this.state.dateCountryInfringementTypeDict) {
            this.wrapDataWithFromTo(this.startDate, this.endDate, (monthStr) => {
                if (this.state.dateCountryInfringementTypeDict[monthStr]) {
                    Object.keys(this.state.dateCountryInfringementTypeDict[monthStr]).forEach(countryName => {
                        Object.keys(this.state.dateCountryInfringementTypeDict[monthStr][countryName]).forEach(categorizedName => {
                            if (categorizedDateDict[categorizedName]) {
                                if (categorizedDateDict[categorizedName][monthStr]) {
                                    categorizedDateDict[categorizedName][monthStr] = categorizedDateDict[categorizedName][monthStr] + this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                                } else {
                                    categorizedDateDict[categorizedName][monthStr] = this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                                }
                            } else {
                                categorizedDateDict[categorizedName] = {}
                                categorizedDateDict[categorizedName][monthStr] = this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                            }
                        })
                    })
                } else {
                }
                return monthStr;
            })
        }

        let { xAxisData, infringementPercent, categorizedCountList, infringementCountList } = this.getInfringementCountPercent();
        let infringementPercentInfo = { type: this.props.intl.formatMessage(messages["overall_categorization_status.legend.infringment_percent"]) }
        let categorizedCountInfo = { type: this.props.intl.formatMessage(messages["overall_categorization_status.legend.categorized"]) }
        let infringementCountInfo = { type: this.props.intl.formatMessage(messages["overall_categorization_status.legend.infringment"]) }

        if (xAxisData) {
            for (let i = 0; i < xAxisData.length; i++) {
                categorizedCountInfo[xAxisData[i]] = categorizedCountList[i]
                infringementCountInfo[xAxisData[i]] = infringementCountList[i]
                infringementPercentInfo[xAxisData[i]] = `${parseInt(infringementPercent[i] * 1000) / 10.0}%`
            }
        }
        let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])
        let datasource = Object.keys(categorizedDateDict).sort().map((key) => {
            let values = categorizedDateDict[key];
            let infringementInfo = InfringementCommonCategories.find(info => {
                return info.id === parseInt(key)
            })
            return { type: infringementInfo.name[nameIntlKey], ...values }
        })

        return [categorizedCountInfo, infringementCountInfo, infringementPercentInfo, ...datasource];
    }

    wrapDataWithFromTo = (fromDate, ToDate, fn) => {
        // this.startDate = moment("2019-01-10").format("YYYY/MM");
        // this.endDate = moment("2019-05-10").format("YYYY/MM");

        const fromYear = parseInt(fromDate.split("-")[0])
        const fromMonth = parseInt(fromDate.split("-")[1])

        let result = []

        const toYear = parseInt(ToDate.split("-")[0])
        const toMonth = parseInt(ToDate.split("-")[1])
        for (let year = fromYear; year <= toYear; year++) {
            let start = fromMonth;
            let end = toMonth;
            if (year === fromYear) {
                if (year === toYear) {
                    start = fromMonth;
                    end = toMonth;
                } else {
                    start = fromMonth;
                    end = 12;
                }
            } else {
                if (year === toYear) {
                    start = 1;
                    end = toMonth;

                } else {
                    start = 1;
                    end = 12;
                }
            }
            for (let month = start; month <= end; month++) {
                let strMonth = month < 10 ? `0${month}` : `${month}`
                result.push(fn(`${year}-${strMonth}`))
            }
        }
        return result;
    }

    getOitTableColumn = (data) => {
        let columns = [
            {
                title: "#",
                dataIndex: "type",
                key: "type",
                width: 150,
                ellipsis: true
            }
        ]
        let warpValues = this.wrapDataWithFromTo(this.startDate, this.endDate, (dateStr) => {
            return {
                title: dateStr,
                dataIndex: dateStr,
                key: dateStr,
                render: (text, record, index) => {
                    return text ? text : "0";
                }
            }
        });
        return columns.concat(warpValues);
    }

    setupCountryFilteringSeriesData = () => {
        if (this.state.dateCountryInfringementTypeDict) {
            let warpValues = this.wrapDataWithFromTo(this.startDate, this.endDate, (dateStr) => {
                return dateStr;
            });
            let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])
            let seriesData = this.state.infringementFilters.map(infringement => {
                let infringementInfo = InfringementCommonCategories.find(info => {
                    return info.id === parseInt(infringement)
                })
                let data = warpValues.map(monthName => {
                    let count = 0
                    if (this.state.dateCountryInfringementTypeDict[monthName]) {
                        Object.keys(this.state.dateCountryInfringementTypeDict[monthName]).forEach(countryName => {
                            if (this.state.countryDateInfringementFilters.some(item => countryName === item)) {
                                if (this.state.dateCountryInfringementTypeDict[monthName][countryName][infringement]) {
                                    count = count + this.state.dateCountryInfringementTypeDict[monthName][countryName][infringement]
                                }
                            }
                        })
                    }
                    return count;
                })
                return {
                    name: infringementInfo.name[nameIntlKey],
                    data: data
                }
            })
            genCountryFilteringBar({
                echarts: echarts, title: "", elementId: "countryFilterBarChart",
                legend: this.state.infringementFilters.sort((a, b) => {
                    return parseInt(a) > parseInt(b) ? 1 : -1;
                }).map(infringement => {
                    let infringementInfo = InfringementCommonCategories.find(info => {
                        return info.id === parseInt(infringement)
                    })
                    return infringementInfo.name[nameIntlKey];
                }),
                xAxisData: warpValues,
                seriesData: seriesData,
            })
        }
    }

    setupCountryFilteringPie = () => {
        let infringementCategoryTypeCountTemp = {}
        if (this.state.dateCountryInfringementTypeDict) {
            this.wrapDataWithFromTo(this.startDate, this.endDate, (monthStr) => {
                if (this.state.dateCountryInfringementTypeDict[monthStr]) {
                    Object.keys(this.state.dateCountryInfringementTypeDict[monthStr]).forEach(countryName => {
                        if (this.state.countryInfringementFilters.some(name => name === countryName)) {
                            Object.keys(this.state.dateCountryInfringementTypeDict[monthStr][countryName]).forEach(categorizedName => {
                                if (infringementCategoryTypeCountTemp[categorizedName]) {
                                    infringementCategoryTypeCountTemp[categorizedName] = infringementCategoryTypeCountTemp[categorizedName] + this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                                } else {
                                    infringementCategoryTypeCountTemp[categorizedName] = {}
                                    infringementCategoryTypeCountTemp[categorizedName] = this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                                }
                            })
                        }
                    })
                } else {
                }
                return monthStr;
            })
            let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])
            let seriesData = Object.keys(infringementCategoryTypeCountTemp).map((infringement) => {
                let infringementInfo = InfringementCommonCategories.find(info => {
                    return info.id === parseInt(infringement)
                })
                return {
                    name: infringementInfo.name[nameIntlKey],
                    value: infringementCategoryTypeCountTemp[infringement]
                }
            })
            genCountryFilteringPie({
                echarts: echarts, title: "", elementId: "categoryPieChart",
                seriesData: seriesData,
            })
        } else {
            return []
        }
    }

    isInfringementCategory = (categorizedName) =>{
        // 1:"非侵害", 8:"参考情報", 9:"要確認",10:"販売終了",
        return (categorizedName !== "1" && categorizedName !== "8" && categorizedName !== "9" && categorizedName !== "10")
    }

    getInfringementCountPercent = () => {
        if (this.state.dateCountryInfringementTypeDict) {
            let leftYAxisData = []
            let rightYAxisData = []
            let infringementPercent = []
            let categorizedCountList = []
            let infringementCountList = []
            let xAxisData = this.wrapDataWithFromTo(this.startDate, this.endDate, (monthStr) => {
                if (this.state.dateCountryInfringementTypeDict[monthStr]) {
                    let categorizedCount = 0
                    let infringementCount = 0
                    Object.keys(this.state.dateCountryInfringementTypeDict[monthStr]).forEach(countryName => {
                        Object.keys(this.state.dateCountryInfringementTypeDict[monthStr][countryName]).forEach(categorizedName => {
                            categorizedCount = categorizedCount + this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                            if (this.isInfringementCategory(categorizedName)) {
                                infringementCount = infringementCount + this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                            }
                        })
                    })
                    leftYAxisData.push(categorizedCount)
                    categorizedCountList.push(categorizedCount)
                    infringementCountList.push(infringementCount)
                    if (categorizedCount > 0) {
                        infringementPercent.push(parseInt(infringementCount * 1.0 / categorizedCount * 100) / 100.0)
                        rightYAxisData.push(infringementCount * 1.0 / categorizedCount)
                    } else {
                        infringementPercent.push(0)
                        rightYAxisData.push(0)
                    }
                } else {
                    leftYAxisData.push(0)
                    rightYAxisData.push(0)
                    infringementPercent.push(0)
                    categorizedCountList.push(0)
                    infringementCountList.push(0)
                }
                return monthStr;
            })

            return {
                leftYAxisData,
                rightYAxisData,
                xAxisData,
                infringementPercent,
                categorizedCountList,
                infringementCountList
            }
        } else {
            return {}
        }
    }

    setupOverallInfringementStatus = () => {
        if (this.state.dateCountryInfringementTypeDict) {
            // let leftYAxisData = []
            // let rightYAxisData = []
            // let infringementPercent = []
            // let categorizedCountList = []
            // let infringementCountList = []
            let { leftYAxisData, rightYAxisData, infringementPercent, categorizedCountList, infringementCountList } = this.getInfringementCountPercent()
            let xAxisData = this.wrapDataWithFromTo(this.startDate, this.endDate, (monthStr) => {
                if (this.state.dateCountryInfringementTypeDict[monthStr]) {
                    let categorizedCount = 0
                    let infringementCount = 0
                    Object.keys(this.state.dateCountryInfringementTypeDict[monthStr]).forEach(countryName => {
                        Object.keys(this.state.dateCountryInfringementTypeDict[monthStr][countryName]).forEach(categorizedName => {
                            categorizedCount = categorizedCount + this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                            if (this.isInfringementCategory(categorizedName)) {
                                infringementCount = infringementCount + this.state.dateCountryInfringementTypeDict[monthStr][countryName][categorizedName]
                            }
                        })
                    })
                    leftYAxisData.push(categorizedCount)
                    categorizedCountList.push(categorizedCount)
                    infringementCountList.push(infringementCount)
                    if (categorizedCount > 0) {
                        infringementPercent.push(parseInt(infringementCount * 1.0 / categorizedCount * 100) / 100.0)
                        rightYAxisData.push(infringementCount * 1.0 / categorizedCount)
                    } else {
                        infringementPercent.push(0)
                        rightYAxisData.push(0)
                    }
                } else {
                    leftYAxisData.push(0)
                    rightYAxisData.push(0)
                    infringementPercent.push(0)
                    categorizedCountList.push(0)
                    infringementCountList.push(0)
                }
                return monthStr;
            })

            genOverallCategorizationStatus({
                echarts: echarts,
                legend: [
                    this.props.intl.formatMessage(messages["overall_categorization_status.legend.categorized"]),
                    this.props.intl.formatMessage(messages["overall_categorization_status.legend.infringment"]),
                    this.props.intl.formatMessage(messages["overall_categorization_status.legend.infringment_percent"]),
                ],
                title: "",
                xAxisData: xAxisData,
                leftYAxisName: this.props.intl.formatMessage(messages["overall_categorization_status.left_y_axis_name"]),
                leftYAxisData: leftYAxisData,
                rightYAxisName: this.props.intl.formatMessage(messages["overall_categorization_status.right_y_axis_name"]),
                rightYAxisData: rightYAxisData,
                seriesLineName: this.props.intl.formatMessage(messages["overall_categorization_status.legend.infringment_percent"]),
                seriesLineData: infringementPercent,
                seriesBar1Name: this.props.intl.formatMessage(messages["overall_categorization_status.legend.categorized"]),
                seriesBar1Data: categorizedCountList,
                seriesBar2Name: this.props.intl.formatMessage(messages["overall_categorization_status.legend.infringment"]),
                seriesBar2Data: infringementCountList,
                elementId: 'ocsChart'
            })
        }
    }

    setupOverallInfringementType = () => {
        let temp = {}
        let xAxisData = []
        let series = []
        let legend = []
        if (this.state.dateCountryInfringementTypeDict) {
            xAxisData = this.wrapDataWithFromTo(this.startDate, this.endDate, (monthStr) => {
                this.state.infringementTypeFilters.forEach(infringement => {
                    if (!temp[infringement]) {
                        temp[infringement] = []
                    }
                    if (this.state.dateCountryInfringementTypeDict[monthStr]) {
                        let infringementCount = 0;
                        Object.keys(this.state.dateCountryInfringementTypeDict[monthStr]).forEach(countryName => {
                            if (this.state.dateCountryInfringementTypeDict[monthStr][countryName][infringement]) {
                                infringementCount = infringementCount + this.state.dateCountryInfringementTypeDict[monthStr][countryName][infringement]
                            }
                        })
                        temp[infringement].push(infringementCount)
                    } else {
                        temp[infringement].push(0)
                    }
                })
                return monthStr;
            })

            series = this.state.infringementTypeFilters.sort((a, b) => {
                return parseInt(a) > parseInt(b) ? 1 : -1;
            }).map(infringement => {
                let infringementInfo = InfringementCommonCategories.find(info => {
                    return info.id === parseInt(infringement)
                })
                let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])
                legend.push(infringementInfo.name[nameIntlKey])
                return {
                    type: 'line',
                    showSymbol: false,
                    smooth: true,
                    name: infringementInfo.name[nameIntlKey],
                    data: temp[infringement]
                }
            })
        }
        // echarts, xAxisData, elementId, series
        genOverallInfringementType({
            echarts,
            xAxisData,
            elementId: "oitChart",
            legend,
            series
        })
    }


    setupOverallInfringementByCountry = () => {
        // let { echarts, title, xAxisData, yAxisName, yAxisData,
        //     seriesBarName, seriesBarData, seriesPieName, seriesPieData, elementId } = config
        if (this.state.countrySiteInfringementTypeDict) {
            let siteInfringementCountDict = {}
            let countryInfringementCountList = []
            Object.keys(this.state.countrySiteInfringementTypeDict).forEach(countryName => {
                let countryInfringementCount = 0;
                Object.keys(this.state.countrySiteInfringementTypeDict[countryName]).forEach(siteName => {
                    let siteCount = 0
                    Object.keys(this.state.countrySiteInfringementTypeDict[countryName][siteName]).forEach(infringementName => {
                        if (infringementName !== "1") {
                            countryInfringementCount = countryInfringementCount + this.state.countrySiteInfringementTypeDict[countryName][siteName][infringementName]
                            siteCount = siteCount + this.state.countrySiteInfringementTypeDict[countryName][siteName][infringementName]
                        }
                    })
                    if (siteInfringementCountDict[siteName]) {
                        siteInfringementCountDict[siteName] = siteInfringementCountDict[siteName] + siteCount;
                    } else {
                        siteInfringementCountDict[siteName] = siteCount;
                    }
                })
                countryInfringementCountList.push({
                    name: countryName,
                    value: countryInfringementCount
                })
            })
            let siteInfringementCountList = Object.keys(siteInfringementCountDict).map(siteName => {
                return {
                    name: siteName,
                    value: siteInfringementCountDict[siteName]
                }
            }).sort((siteA, siteB) => {
                return siteA.value < siteB.value ? 1 : -1;
            }).filter(site => {
                return site.value > 0;
            })
            countryInfringementCountList = countryInfringementCountList.sort((a, b) => {
                return a.value > b.value ? 1 : -1;
            })
            genOverallInfringementCountry({
                echarts: echarts, title: "", elementId: "oicChart",
                xAxisData: siteInfringementCountList.map(site => site.value),
                yAxisName: "", yAxisData: siteInfringementCountList.map(site => site.name),
                seriesBarName: "", seriesBarData: siteInfringementCountList.map(site => site.value),
                seriesPieName: "", seriesPieData: countryInfringementCountList.map((country) => country)
            })
        }
    }

    setupECInfringementCategorizationChart = () => {
        // let { echarts, legend, title, xAxisData, seriesData, elementId } = config
        // seriesData {name,data}
        let infringementSiteDict = {}

        if (this.state.countrySiteInfringementTypeDict) {
            Object.keys(this.state.countrySiteInfringementTypeDict).forEach(countryName => {
                if (this.state.countryECInfringementFilters.some(name => name === countryName)) {
                    Object.keys(this.state.countrySiteInfringementTypeDict[countryName]).forEach(siteName => {

                        Object.keys(this.state.countrySiteInfringementTypeDict[countryName][siteName]).forEach(infringement => {
                            if (infringementSiteDict[siteName]) {
                                if (infringementSiteDict[siteName][infringement]) {
                                    infringementSiteDict[siteName][infringement] = infringementSiteDict[siteName][infringement] + this.state.countrySiteInfringementTypeDict[countryName][siteName][infringement];
                                } else {
                                    infringementSiteDict[siteName][infringement] = this.state.countrySiteInfringementTypeDict[countryName][siteName][infringement];
                                }
                            } else {
                                infringementSiteDict[siteName] = {}
                                infringementSiteDict[siteName][infringement] = this.state.countrySiteInfringementTypeDict[countryName][siteName][infringement];
                            }
                        })
                    })
                }
            })
            let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])
            let legend = this.state.infringementFilters.sort((a, b) => {
                return parseInt(a) > parseInt(b) ? 1 : -1;
            }).map(infringement => {
                let infringementInfo = InfringementCommonCategories.find(info => {
                    return info.id === parseInt(infringement)
                })
                return infringementInfo.name[nameIntlKey]
            })
            let seriesData = this.state.infringementFilters.sort((a, b) => {
                return parseInt(a) > parseInt(b) ? 1 : -1;
            }).map(infringement => {
                let data = Object.keys(infringementSiteDict).map(siteName => {
                    let infringementInfo = { ...infringementSiteDict[siteName] };
                    let infringementCount = infringementInfo[infringement] ? infringementInfo[infringement] : 0;
                    return infringementCount;
                })
                let infringementInfo = InfringementCommonCategories.find(info => {
                    return info.id === parseInt(infringement)
                })
                return {
                    name: infringementInfo.name[nameIntlKey],
                    data: data
                }
            })

            // a.data.reduce((a, b) => a + b) > b.data.reduce((a, b) => a + b) ? 1 : -1;
            genECInfringementCategorizationChart({
                echarts: echarts, title: "", elementId: "ecInfringemntCategoryizationChart",
                legend: legend,
                xAxisData: Object.keys(infringementSiteDict),
                seriesData: seriesData
            })
        }
    }

    setupTakedownChart = () => {
        if (this.state.countryResultDict) {
            let resultDict = {}
            Object.keys(this.state.countryResultDict).forEach(countryName => {
                // condictions
                if (this.state.countryTakedownFilters.some(name => name === countryName)) {
                    Object.keys(this.state.countryResultDict[countryName]).forEach(resultName => {
                        if (resultDict[resultName]) {
                            resultDict[resultName] = this.state.countryResultDict[countryName][resultName] + resultDict[resultName];
                        } else {
                            resultDict[resultName] = this.state.countryResultDict[countryName][resultName];
                        }
                    })
                }
            })
            let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])

            let seriesPieData = Object.keys(resultDict).filter((key) => {
                return TakedownResult.find(result => {
                    return result.value === parseInt(key)
                })
            }).map(resultName => {
                let result = TakedownResult.find(result => {
                    return result.value === parseInt(resultName)
                })
                return {
                    name: result.name[nameIntlKey],
                    value: resultDict[resultName]
                }
            })
            genTakedownChartConfig({
                echarts: echarts, title: "", elementId: "takedownPieChart",
                seriesPieData: seriesPieData
            })
        }
    }

    getTopInfringersInfo = () => {
        if (this.state.countrySellerDict) {
            let sellers = {}
            this.state.countrySellerFilters.forEach((countryName) => {
                if (this.state.countrySellerDict[countryName]) {
                    Object.keys(this.state.countrySellerDict[countryName]).forEach(sellerName => {
                        if (sellers[sellerName]) {
                            sellers[sellerName].total = sellers[sellerName].total + this.state.countrySellerDict[countryName][sellerName].total
                            Object.keys(this.state.countrySellerDict[countryName][sellerName].sites).forEach(siteName => {
                                let siteValue = this.state.countrySellerDict[countryName][sellerName].sites[siteName];
                                sellers[sellerName].sites[siteName] = sellers[sellerName].sites[siteName] ? sellers[sellerName].sites[siteName] + siteValue : siteValue;
                            })
                        } else {
                            sellers[sellerName] = {}
                            sellers[sellerName].total = this.state.countrySellerDict[countryName][sellerName].total
                            sellers[sellerName].sites = this.state.countrySellerDict[countryName][sellerName].sites
                        }
                    })
                }
            })

            return Object.keys(sellers).map(sellerName => {
                let infringementFilters = {}
                infringementFilters.key = "ec_category_common_type_id";
                infringementFilters.values = InfringementCommonCategories.filter(category => category.id !== 1 && category.id !== 10).map(category => category.id);

                let regionFilters = {}
                regionFilters.key = "ec_product_region";
                regionFilters.values = this.state.countrySellerFilters;

                let searchParams = {}
                searchParams.wordFields = ["ec_product_seller"];
                searchParams.keyword = sellerName;
                searchParams.searchType = 4; // 完全一致
                searchParams.dateFields = this.dateType === 1 ? ["ec_scan_date"] : ["ec_categorize_date"]
                searchParams.startDate = moment(this.startDate).format("YYYY-MM-DD");
                searchParams.endDate = moment(this.endDate).format("YYYY-MM-DD");
                return {
                    name: sellerName,
                    value: sellers[sellerName].total,
                    type: "a",
                    link: `/ec-list?clientId=${this.clientId}&${ECListCommon.ParamsKey.search}=${encodeURIComponent(JSON.stringify(searchParams))}&filters=${encodeURIComponent(JSON.stringify([infringementFilters, regionFilters]))}`,
                    key: sellerName,
                    children: Object.keys(sellers[sellerName].sites).map(siteName => {
                        let filters = {}
                        filters.key = "ec_site";
                        filters.values = [siteName];
                        return {
                            name: siteName,
                            key: siteName,
                            value: sellers[sellerName].sites[siteName],
                            type: "c",
                            link: `/ec-list?clientId=${this.clientId}&${ECListCommon.ParamsKey.search}=${encodeURIComponent(JSON.stringify(searchParams))}&filters=${encodeURIComponent(JSON.stringify([filters, infringementFilters, regionFilters]))}`,
                        }
                    }).sort((a, b) => {
                        return a.value > b.value ? -1 : 1
                    })
                }
            }).sort((a, b) => {
                return a.value > b.value ? -1 : 1
            })
        }
        return []
    }

    setupCharts = () => {
        // ec
        this.setupOverallInfringementStatus();
        this.setupOverallInfringementType();
        this.setupCountryFilteringSeriesData();
        this.setupOverallInfringementByCountry();
        this.setupCountryFilteringPie();
        this.setupECInfringementCategorizationChart()
        this.setupTakedownChart()
        // ws
        this.setupWsCountryFilteringPie()
        this.setupWsTakedownChart()
    }


    setupWsCountryFilteringPie = () => {
        if (this.state.ws && this.state.ws.wsCategoryByFilterRegion) {
            let InfringementCategoryTypeCountTemp = {}
            const regionsCateogries = this.state.ws.wsCategoryByFilterRegion;
            Object.keys(regionsCateogries).forEach(regionName => {
                if (this.state.ws.regionFilters.some(name => name === regionName)) {
                    Object.keys(regionsCateogries[regionName]).forEach((infringementName) => {
                        if (InfringementCategoryTypeCountTemp[infringementName]) {
                            InfringementCategoryTypeCountTemp[infringementName] = InfringementCategoryTypeCountTemp[infringementName] + regionsCateogries[regionName][infringementName]
                        } else {
                            InfringementCategoryTypeCountTemp[infringementName] = regionsCateogries[regionName][infringementName]
                        }
                    })

                }
            });
            let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])
            let seriesData = Object.keys(InfringementCategoryTypeCountTemp).map((infringement) => {
                let infringementInfo = WSCategoryType.find(info => {
                    return info.value === parseInt(infringement)
                })
                if (infringementInfo) {
                    return {
                        name: infringementInfo.name[nameIntlKey],
                        value: InfringementCategoryTypeCountTemp[infringement]
                    }
                } else {
                    return {
                        name: "Others",
                        value: InfringementCategoryTypeCountTemp[infringement]
                    }
                }

            })
            genCountryFilteringPie({
                echarts: echarts, title: "", elementId: "wsCategoryPieChart",
                seriesData: seriesData,
            })
        } else {
            return []
        }
    }

    setupWsTakedownChart = () => {
        if (this.state.ws && this.state.ws.wsTakedownByFilterRegion) {
            let resultDict = {}
            const dict = this.state.ws.wsTakedownByFilterRegion;
            Object.keys(dict).forEach(countryName => {
                // condictions
                if (this.state.ws.regionFilters.some(name => name === countryName)) {
                    Object.keys(dict[countryName]).forEach(resultName => {
                        if (resultDict[resultName]) {
                            resultDict[resultName] = dict[countryName][resultName] + resultDict[resultName];
                        } else {
                            resultDict[resultName] = dict[countryName][resultName];
                        }
                    })
                }
            })
            let nameIntlKey = this.props.intl.formatMessage(commonMessage["infirgment_common_cateogry.name_locale_key"])
            // console.log(resultDict)
            let seriesPieData = Object.keys(resultDict).map(resultName => {
                const result = WSTakedownResult.find(result => {
                    return result.value === parseInt(resultName)
                })
                if (result) {
                    return {
                        name: result.name[nameIntlKey],
                        value: resultDict[resultName]
                    }
                } else {
                    return {
                        name: "Others",
                        value: resultDict[resultName]
                    }
                }
            })
            genTakedownChartConfig({
                echarts: echarts, title: "", elementId: "wsTakedownPieChart",
                seriesPieData: seriesPieData
            })
        }
    }

    showClientSelector = () => {
        if (this.props.role & 2) {
            if (this.state.clients) {
                return (
                    <div className="search-item-container">
                        <span className="search-item-title"><FormattedMessage id="app.ui.dashboard.search.brand" /></span>
                        <Form.Item noStyle={true} name="clientId" rules={[
                            {
                                required: true,
                                message: "開始期間を入力ください。"
                            }
                        ]}>
                            <Select allowClear style={{ width: 200 }}>
                                {
                                    this.state.clients.map((client) => {
                                        return <Select.Option key={`${client.client_id}${client.client_name}`} value={client.client_id}>{client.client_name}</Select.Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </div>
                )
            }
            return (
                <div className="search-item-container">
                    <span className="search-item-title"><FormattedMessage id="app.ui.dashboard.search.brand" /></span>
                    <Select allowClear style={{ width: 200 }}>
                    </Select>
                </div>
            )
        }
    }

    onFinishFailed = (values) => {
        const { errorFields } = values;
        const { errors } = errorFields[0];
        message.error(errors[0])
    }

    onFinish = (values) => {
        const { fromDate, toDate, clientId, dateType } = values;
        this.clientId = clientId;
        this.startDate = fromDate.format("YYYY-MM-DD")
        this.endDate = toDate.format("YYYY-MM-DD")
        this.dateType = dateType
        let client = this.state.clients.find(cleint => cleint.client_id === clientId);
        if (client) {
            this.analyst = client.analystInfo.map(analyst => analyst.user_name).join("、")
        }
        this.fetchDashboardInfo(clientId, this.startDate, this.endDate, this.dateType)
    }

    onTabChange = (activeKey) => {
        // this.setupCharts()
        if (activeKey === "ws-dashboard") {
            // ws
            setTimeout(() => {
                this.setupWsCountryFilteringPie()
                this.setupWsTakedownChart()

            }, 4);

        } else if (activeKey === "ec-dashboard") {
            // ec
            setTimeout(() => {
                this.setupOverallInfringementStatus();
                this.setupOverallInfringementType();
                this.setupCountryFilteringSeriesData();
                this.setupOverallInfringementByCountry();
                this.setupCountryFilteringPie();
                this.setupECInfringementCategorizationChart()
                this.setupTakedownChart()

            }, 4);
        }

    }

    render() {
        this.setupCharts()
        document.title = this.props.intl.formatMessage(messages["dashboard.title"])
        return (
            <div className="dashboard-container" >
                <Form
                    initialValues={{ dateType: this.dateType }}
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFaild}
                    ref={this.formRef}
                >
                    <div className="search-container">
                        {this.showClientSelector()}
                        <div className="search-item-container">
                            <span className="search-item-title"><FormattedMessage id="app.ui.dashboard.search.date_column" /></span>
                            <Form.Item name="dateType" noStyle={true} rules={[
                                {
                                    required: true,
                                    message: "開始日付を入力ください。"
                                }
                            ]}>
                                <Select defaultValue={1} style={{ width: 150 }}>
                                    <Select.Option value={1}>
                                        <FormattedMessage id="app.ui.eclist.table.ec_scan_month" />
                                    </Select.Option>
                                    <Select.Option value={2}>
                                        <FormattedMessage id="app.ui.eclist.table.ec_categorize_date" />
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="search-item-container">
                            <span className="search-item-title"><FormattedMessage id="app.ui.dashboard.search.date_from" /></span>
                            <Form.Item name="fromDate" noStyle={true} rules={[
                                {
                                    required: true,
                                    message: "開始日付を入力ください。"
                                }
                            ]}>
                                <DatePicker></DatePicker>
                            </Form.Item>
                            <FormattedMessage id="app.ui.dashboard.search.date_to" />
                            <Form.Item name="toDate" noStyle={true} rules={[
                                {
                                    required: true,
                                    message: "終了日付を入力ください。"
                                }
                            ]}>
                                <DatePicker></DatePicker>
                            </Form.Item>
                        </div>
                        <Form.Item noStyle={true}>
                            <Button type="primary" htmlType="submit"><FormattedMessage id="app.ui.dashboard.search.find" /></Button>
                        </Form.Item>
                    </div>
                </Form >

                <Tabs
                    type="card"
                    onChange={this.onTabChange}
                    // defaultActiveKey={this.state.tabActiveKey}
                    items={
                        [
                            {
                                key: "ec-dashboard",
                                label: "E-commerce",
                                forceRender: true,
                                children: (<>
                                    <Row className="row">
                                        <Col span={24} >
                                            <ECChartContainer title={<FormattedMessage id="app.ui.dashboard.status.title" />} >
                                                <Row>
                                                    <Col span={8}>
                                                        <div className="basic-info-container">
                                                            <div className="icon" ><DollarCircleTwoTone twoToneColor="#eb2f96" style={{ fontSize: '40px' }} /></div>
                                                            <div className="content">
                                                                <p className="title"><FormattedMessage id="app.ui.dashboard.status.impact_revenue" /></p>
                                                                <p><span className="subtitle"><FormattedMessage id="app.ui.dashboard.status.impact_revenue.usd" /></span><span className="result">{this.state.impactRevenueUSD ? this.state.impactRevenueUSD : 0} </span></p>
                                                                <p><span className="subtitle"><FormattedMessage id="app.ui.dashboard.status.impact_revenue.rate" /></span><span className="result">{this.state.JPYRate ? parseFloat(this.state.JPYRate) : 0} </span></p>
                                                                <p><span className="subtitle"><FormattedMessage id="app.ui.dashboard.status.impact_revenue.jpy" /></span><span className="result">{this.state.impactRevenueJPY ? this.state.impactRevenueJPY : 0}</span></p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={8}>
                                                        <div className="basic-info-container">
                                                            <div className="icon"><CalendarTwoTone twoToneColor="#606266" style={{ fontSize: '40px', }} /></div>
                                                            <div className="content">
                                                                <p className="title"> <FormattedMessage id="app.ui.dashboard.status.annual_date" /></p>
                                                                <span className="result">{this.startDate}</span><span> ~ </span><span className="result">{this.endDate}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col span={8}>
                                                        <div className="basic-info-container">
                                                            <div className="icon"><TeamOutlined style={{ fontSize: '40px', color: '#08c' }} /></div>
                                                            <div className="content">
                                                                <p className="title"><FormattedMessage id="app.ui.dashboard.status.analyst" /></p>
                                                                <p className="result">{this.analyst}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </ECChartContainer>

                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={12}>
                                            <Row>
                                                <Col span={24}>
                                                    <ECChartContainer title={this.props.intl.formatMessage(messages["overall_categorization_status.title"])} >
                                                        <div className="chart-element" id="ocsChart"></div>
                                                    </ECChartContainer>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24} >
                                                    <ECChartContainer title={this.props.intl.formatMessage(messages["overall_infringement_type.title"])} >
                                                        <div className="chart-element" id="oitChart"></div>
                                                    </ECChartContainer>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={24}>
                                                    <ECChartContainer title={this.props.intl.formatMessage(messages["map.overall_infringement_country.title"])} >
                                                        <div>
                                                            <div ref={this.mapContainer} className="map-container" />
                                                        </div>
                                                    </ECChartContainer>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={24} >
                                                    <ECChartContainer title={this.props.intl.formatMessage(messages["chart.overall_infringement_country.title"])} >
                                                        <div className="chart-element" id="oicChart"></div>
                                                    </ECChartContainer>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={24}>
                                            <ECChartContainer title={this.props.intl.formatMessage(messages["table.categorization_status.title"])} >
                                                <Table columns={this.getOitTableColumn()}
                                                    dataSource={this.getOitTableDatasource()}
                                                    pagination={false}
                                                    size="small"
                                                    tableLayout="fixed"
                                                    scroll={{
                                                        x: "max-content"
                                                    }}
                                                />
                                            </ECChartContainer>
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={24}>
                                            <ECChartContainer title={this.props.intl.formatMessage(messages["chart.locale_filtering.title"])} >
                                                <Row>
                                                    <Col span={12}>
                                                        <div className="chart-element" id="countryFilterBarChart"></div>
                                                        <ChartDataFilter
                                                            title={this.props.intl.formatMessage(messages["filter.locale_filtering.title"])}
                                                            options={this.getContountryFilter()}
                                                            selectList={this.state.countryDateInfringementFilters}
                                                            colCount={4}
                                                            onFilterChange={this.onFilterCountryDateInfringement}
                                                        />
                                                    </Col>
                                                    <Col span={12}>
                                                        <div className="chart-element" id="categoryPieChart"></div>
                                                        <ChartDataFilter
                                                            title={this.props.intl.formatMessage(messages["filter.locale_filtering.title"])}
                                                            options={this.getContountryFilter()}
                                                            selectList={this.state.countryInfringementFilters}
                                                            colCount={4}
                                                            onFilterChange={this.onFilterCountryInfringement}
                                                        />
                                                    </Col>
                                                </Row>
                                            </ECChartContainer>
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={24}>
                                            <ECChartContainer title={this.props.intl.formatMessage(messages["ec_infringement_categorization.title"])} >
                                                <div className="chart-element" id="ecInfringemntCategoryizationChart"></div>
                                                <ChartDataFilter
                                                    title={this.props.intl.formatMessage(messages["filter.locale_filtering.title"])}
                                                    options={this.getContountryFilter()}
                                                    selectList={this.state.countryECInfringementFilters}
                                                    colCount={6}
                                                    onFilterChange={this.onFilterCountryECInfringement} >
                                                </ChartDataFilter>
                                            </ECChartContainer>
                                        </Col>
                                    </Row>
                                    <Row className="row">
                                        <Col span={12}>
                                            <ECChartContainer title={this.props.intl.formatMessage(messages["list.top_infringers.title"])} >
                                                <div className="chart-element-type2">
                                                    <SellerList data={this.getTopInfringersInfo()} />
                                                </div>
                                                <ChartDataFilter
                                                    title={this.props.intl.formatMessage(messages["filter.locale_filtering.title"])}
                                                    options={this.getContountryFilter()}
                                                    selectList={this.state.countrySellerFilters}
                                                    colCount={4}
                                                    onFilterChange={this.onFilterCountrySeller}
                                                />
                                            </ECChartContainer>
                                        </Col>
                                        <Col span={12}>
                                            <ECChartContainer title={this.props.intl.formatMessage(messages["chart.takedown.title"])} >
                                                <div className="chart-element-type2" id="takedownPieChart"></div>
                                                <ChartDataFilter
                                                    title={this.props.intl.formatMessage(messages["filter.locale_filtering.title"])}
                                                    options={this.getContountryFilter()}
                                                    selectList={this.state.countryTakedownFilters}
                                                    colCount={4}
                                                    onFilterChange={this.onFilterCountryTakedown}
                                                />
                                            </ECChartContainer>
                                        </Col>
                                    </Row>

                                </>)
                            },
                            {
                                key: "ws-dashboard",
                                label: "Web Site",
                                forceRender: true,
                                children: (<>
                                    <Row className="row">
                                        <Col span={12}>
                                            <ECChartContainer title={this.props.intl.formatMessage(messages["chart.ws_takedown.title"])} >
                                                <div className="chart-element-type2" id="wsTakedownPieChart" style={{ width: '100%', height: '240px' }}></div>
                                                {/* <ChartDataFilter
                                title={this.props.intl.formatMessage(messages["filter.locale_filtering.title"])}
                                options={this.getContountryFilter()}
                                selectList={this.state.countryTakedownFilters}
                                colCount={4}
                                onFilterChange={this.onFilterCountryTakedown}
                            /> */}
                                            </ECChartContainer>
                                        </Col>
                                        <Col span={12}>
                                            <ECChartContainer title={this.props.intl.formatMessage(messages["chart.ws_category.title"])} >
                                                <div className="chart-element-type2" id="wsCategoryPieChart"></div>
                                                {/* <ChartDataFilter
                                title={this.props.intl.formatMessage(messages["filter.locale_filtering.title"])}
                                options={this.getContountryFilter()}
                                selectList={this.state.countryTakedownFilters}
                                colCount={4}
                                onFilterChange={this.onFilterCountryTakedown}
                            /> */}
                                            </ECChartContainer>
                                        </Col>
                                    </Row>
                                </>)
                            }
                        ]
                    }></Tabs>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.user,
        ...state.mainMenu,
    };
};


export default connect(mapStateToProps)(injectIntl(EFDashboard))
