import "./index.less"
import React, { Component } from "react";
import { Button, Form, Input, Radio, message } from "antd";
import { injectIntl, FormattedMessage } from "react-intl";
import messages from "./messages";

import { reqGetImpactSettings, reqSetImpactSettings } from '../../../api/analyst-other-setting'

class AnalystClientOtherSetting extends Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: {},
            isloading: false,
        }
        this.formRef = React.createRef();

        if (this.props.clientId) {
            reqGetImpactSettings({
                clientId: this.props.clientId,
            }).then((response) => {
                const { data } = response;
                if (data.code === 0) {
                    this.formRef.current.setFieldsValue({
                        ...data.data.settings
                    })
                }
            }).catch((error) => {

            })
        }
    }

    onFinish = (values) => {
        this.setState({ isloading: true })
        reqSetImpactSettings({
            clientId: this.props.clientId,
            count: parseInt(values.count),
            countType: values.countType,
            price: parseFloat(values.price),
            priceType: values.priceType,
            radio: parseFloat(values.radio)
        }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                this.formRef.current.setFieldsValue({ ...data.data.settings })
                this.setState({ isloading: false })
                message.success(this.props.intl.formatMessage(messages["message_success"]))
            }
        }).catch((e) => {
            this.setState({ isloading: false })
            message.success(this.props.intl.formatMessage(messages["message_failed"]))
        })
    }

    onFinishFailed = (values) => {
        message.success(this.props.intl.formatMessage(messages["message_failed"]))
    }


    render() {
        return (
            <div className="master-container">
                <Form
                    onFinish={this.onFinish}
                    onFinishFailed={this.onFinishFailed}
                    ref={this.formRef}
                >
                    <div className="setting-title-wrapper"><FormattedMessage id="app.ui.data_setting.other_setting.impact_func" /></div>
                    <div className="item-wrapper">
                        <div className="item-label-container">
                            <p><FormattedMessage id="app.ui.data_setting.other_setting.price_factor" /></p>
                        </div>
                        <div>
                            <Form.Item noStyle={true} name="priceType">
                                <Radio.Group onChange={this.onPriceTypeChange}>
                                    <Radio value={1}><FormattedMessage id="app.ui.data_setting.other_setting.displayed_price" /></Radio>
                                    <Radio value={2}><FormattedMessage id="app.ui.data_setting.other_setting.flat_price" /></Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item noStyle={true} name="price">
                                <Input className="input-text" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="item-label-container">
                            <p><FormattedMessage id="app.ui.data_setting.other_setting.count_factor" /></p>
                        </div>
                        <div >
                            <Form.Item noStyle={true} name="countType">
                                <Radio.Group onChange={this.onCountTypeChange} >
                                    <Radio value={1}><FormattedMessage id="app.ui.data_setting.other_setting.inventory" /></Radio>
                                    <Radio value={2}><FormattedMessage id="app.ui.data_setting.other_setting.sold_count" /></Radio>
                                    <Radio value={3}><FormattedMessage id="app.ui.data_setting.other_setting.flat_count" /></Radio>
                                </Radio.Group>
                            </Form.Item >
                            <Form.Item noStyle={true} name="count">
                                <Input className="input-text" />
                            </Form.Item>
                        </div>
                    </div>
                    <div className="item-wrapper">
                        <div className="item-label-container">
                            <p><FormattedMessage id="app.ui.data_setting.other_setting.percent_factor" /></p>
                        </div>
                        <div>
                            <Form.Item noStyle={true} name="radio">
                                <Input className="input-text" />
                            </Form.Item>%
                        </div>
                    </div>

                    <div className="acition-container">
                        <Button htmlType="submit" type="primary" className="update-button" loading={this.state.isloading}>
                            <FormattedMessage id="app.common.action.update" />
                        </Button>
                    </div>
                </Form>
            </div>
        )
    }
}

export default injectIntl(AnalystClientOtherSetting)
