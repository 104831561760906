import "./index.less"
import EFCSearchBar from "../../../components/ef-c-searchbar";
import store from "@/store";
import React, { Component } from "react";
import { Table, Space, Button } from "antd";
import { setContentContainerHeaderAction, setBreadcrumbs } from "@/store/actions/content-header.js"
import { setMenuActiveKey } from "@/store/actions/menu.js"
import { reqSearchUsers, reqRemoveUser } from '../../../api/admin-user'
import { compareString, compareNumber, compareDate } from "@/utils/compare.js"
import moment from 'moment';

class UserList extends Component {
    constructor(props) {
        super(props);
        this.dateSearchItems = [
            {
                name: "最終ログイン日付",
                value: "user_last_used_date"
            },
            {
                name: "新規日付",
                value: "user_create_at"
            }];
        this.keywordSearchItems = [
            {
                name: "名前",
                value: "user_name"
            },
            {
                name: "メール",
                value: "user_mail"
            },
            {
                name: "備考",
                value: "user_remarks"
            },
        ];
        this.columns = [
            {
                title: 'ID',
                dataIndex: 'user_id',
                key: 'user_id',
                fixed: true,
                width: 80,
                sorter: (a, b) => compareNumber(a.user_id, b.user_id),
                showSorterTooltip: false,
            },
            {
                title: '名前',
                dataIndex: 'user_name',
                key: 'user_name',
                width: 150,
                sorter: (a, b) => compareString(a.user_name, b.user_name),
                showSorterTooltip: false,
            },
            {
                title: 'メール',
                dataIndex: 'user_mail',
                key: 'user_mail',
                width: 200,
                sorter: (a, b) => compareString(a.user_mail, b.user_mail),
                showSorterTooltip: false,
            },
            {
                title: '電話番号',
                dataIndex: 'user_tel',
                key: 'user_tel',
                width: 150,
                sorter: (a, b) => compareString(a.user_tel, b.user_tel),
                showSorterTooltip: false,
            },
            {
                title: '部署',
                dataIndex: 'user_department',
                key: 'user_department',
                width: 150,
                sorter: (a, b) => compareString(a.user_department, b.user_department),
                showSorterTooltip: false,
            },
            {
                title: '最終ログイン日付',
                dataIndex: 'user_last_used_date',
                key: 'user_last_used_date',
                render: (text, record) => {
                    if (text) {
                        return moment(text).format("YYYY-MM-DD")
                    } else {
                        return ""
                    }
                },
                width: 150,
                sorter: (a, b) => {
                    if (a.user_last_used_date) {
                        if (b.user_last_used_date) {
                            return compareDate(a.user_last_used_date, b.user_last_used_date)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.user_last_used_date) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: '新規日付',
                dataIndex: 'user_create_at',
                key: 'user_create_at',
                render: (text, record) => (
                    moment(text).format("YYYY-MM-DD")
                ),
                width: 150,
                sorter: (a, b) => {
                    if (a.user_create_at) {
                        if (b.user_create_at) {
                            return compareDate(a.user_create_at, b.user_create_at)
                        } else {
                            return 1
                        }
                    } else {
                        if (b.user_create_at) {
                            return -1
                        } else {
                            return 0
                        }
                    }
                },
                showSorterTooltip: false,
            },
            {
                title: '備考',
                dataIndex: 'user_remarks',
                key: 'user_remarks',
                width: 400,
                sorter: (a, b) => compareString(a.user_remarks, b.user_remarks),
                showSorterTooltip: false,
            },

            {
                title: '動作',
                key: 'action',
                render: (text, record) => (
                    <Space size="middle">
                        <Button type="link" href={"/manage/user/" + record.user_id}>詳細</Button>
                        <Button type="link" onClick={() => { this.handleDelete(record) }} client={record.user_id}>削除</Button>
                    </Space>
                ),
                fixed: "right",
                width: 200,

            },
        ];
        this.state = {
            dateSource: []
        }
        this.searchUsers()
    }

    searchUsers = (conditions = {}) => {
        reqSearchUsers({ role: 1, ...conditions }).then((response) => {
            const { data } = response;
            if (data.code === 0) {
                let dateSource = data.data.users.map((user) => {
                    let userInfo = { key: user.user_id, ...user }
                    return userInfo;
                })
                this.setState({ dateSource });
            }
        }).catch((error) => {
            
        })
    }

    componentDidMount = () => {
        store.dispatch(setMenuActiveKey("manage-user-list"));
        store.dispatch(setContentContainerHeaderAction([{
            name: "新規",
            path: "/manage/user/new",
        }]));
        store.dispatch(setBreadcrumbs([
            {
                name: "Home",
                path: "/",
            },
            {
                name: "ユーザー登録一覧",
                path: "/manage/user",
            }
        ]));
    }

    handleSearch = (conditions) => {
        this.searchUsers(conditions)
    };

    handleReset = () => {
    };

    handleDelete = (record) => {
        reqRemoveUser({
            userId: record.user_id
        }).then((response) => {
            this.searchUsers(this.searchConditions)
        }).catch((e) => {

        })
    };

    handleDetail = () => {

    };

    render() {
        document.title = "ユーザー登録一覧"
        return (
            <div className="user-list-container">
                <div>
                    <EFCSearchBar onSearch={this.handleSearch} onReset={this.handleReset} dateItems={this.dateSearchItems} keywordItems={this.keywordSearchItems} />
                </div>
                <div className="result-table">
                    <Table
                        columns={this.columns}
                        dataSource={this.state.dateSource}
                        size="small"
                        scroll={{
                            x: 'max-content',
                        }}
                        pagination={false}
                    />
                </div>
            </div>
        )
    }
}

export default UserList